import React, { useContext, useEffect, useState } from "react";
import { getValue } from "../../utils";
import { UserContext } from "../../context/UserContext";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import { WaitlistsContext } from "../../context/WaitlistsContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { SingleClassContext } from "../../context/SingleClassContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import { ReservationsContext } from "../../context/ReservationsContext";
import AsistentesTable from "../../components/asistentes/AsistentesTable";
import SingleClassData from "../../components/single_class/SingleClassData";
import ConfirmDelete from "../../components/common/ConfirmDelete";

const AdminSingleClass = ({ single_class_id }) => {
  const { clase, clearClase, postPayment, getSingleClass, updateGuestName } =
    useContext(SingleClassContext);
  const { waitlist, getSingleClassWaitlist } = useContext(WaitlistsContext);
  const { postGear, postAttend } = useContext(ReservationsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { eliminarReservacion } = useContext(ReservationsContext);
  const appconfig = useContext(AppConfigContext);
  const { user } = useContext(UserContext);

  const [view, setView] = useState("reservas");

  const allow_manual_book_full_class = getValue(
    appconfig,
    "allow_manual_book_full_class",
    "boolean"
  );

  const remainingSpots = clase?.capacity - clase?.class_reservations.length;

  useEffect(() => {
    fetchAsistentes();
    return clearClase;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [single_class_id]);

  const getButtonComponent = () => {
    if (remainingSpots < 1 && !allow_manual_book_full_class) {
      return (
        <span className="badge badge-pill bg-danger text-white">Sold Out</span>
      );
    }
  };

  const fetchAsistentes = () => {
    getSingleClass(single_class_id);
    getSingleClassWaitlist(single_class_id);
  };

  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente single_class_id={single_class_id} />
    );
  };

  const confirmCancel = (customer) => {
    modalComponent(
      "¿Cancelar reservacion?",
      <ConfirmDelete
        message={`¿Estás segura que deseas cancelar la reservacion de ${customer.name}?`}
        handleDelete={() =>
          eliminarReservacion(customer.class_reservation_id, fetchAsistentes)
        }
        handleCancel={clearModal}
      />
    );
  };

  const renderClase = () => {
    if (clase && clase !== null) {
      return <SingleClassData single_class={clase} />;
    }
    return <div className="spinner-border"></div>;
  };

  const customers =
    (view === "waitlist" ? waitlist : clase?.class_reservations) || [];

  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle
        title="Clase"
        onClick={addAsistente}
        hideButton={user.role === "coach"}
        buttonComponent={getButtonComponent()}
      />
      <div className="card p-3 shadow-sm mb-3">{renderClase()}</div>
      {getValue(appconfig, "waitlist_enabled", "booleam") && (
        <div className="btn-group w-100 my-1">
          <div
            onClick={() => setView("reservas")}
            className={`btn btn-${
              view === "reservas" ? "primary" : "outline-primary"
            }`}
          >
            Reservas
          </div>
          <div
            onClick={() => setView("waitlist")}
            className={`btn btn-${
              view === "waitlist" ? "primary" : "outline-primary"
            }`}
          >
            Lista de Espera
          </div>
        </div>
      )}
      <AsistentesTable
        handleCallback={fetchAsistentes}
        single_class_id={single_class_id}
        updateGuestName={updateGuestName}
        confirmCancel={confirmCancel}
        postPayment={postPayment}
        postAttend={postAttend}
        asistentes={customers}
        postGear={postGear}
        user={user}
      />
    </div>
  );
};

export default AdminSingleClass;
