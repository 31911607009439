import React from "react";

const Secure = () => {
  return (
    <div className="container-fluid my-3">
      <div className="card p-3 border-0 shadow">
        <div className="row align-items-center">
          <div className="col-12 col-md-4 text-center my-3">
            <div className="seal secure">
              <div className="seal-icon-cta">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="50px"
                  viewBox="0 0 150 100"
                  preserveAspectRatio="xMidYMid meet"
                >
                  {" "}
                  <g
                    transform="translate(0,105) scale(0.1,-0.1)"
                    fill="#292929"
                    stroke="none"
                  >
                    {" "}
                    <path d="M415 1165 c-14 -13 -25 -35 -25 -48 0 -13 -3 -45 -6 -71 l-7 -46 354 0 c483 0 452 23 459 -335 l5 -240 24 -3 c37 -6 70 6 86 31 14 22 49 368 58 569 4 100 26 94 -454 133 -228 19 -427 35 -442 35 -17 0 -38 -10 -52 -25z"></path>{" "}
                    <path d="M177 928 c-30 -23 -37 -68 -37 -222 l0 -153 45 1 45 1 0 48 0 47 405 0 405 0 0 -165 0 -165 -306 0 -306 0 6 -22 c3 -13 6 -31 6 -40 0 -17 22 -18 320 -18 307 0 321 1 340 20 19 19 20 33 20 330 0 305 0 309 -22 330 -21 19 -33 20 -463 20 -337 0 -445 -3 -458 -12z m863 -108 l0 -40 -405 0 -405 0 0 40 0 40 405 0 405 0 0 -40z"></path>{" "}
                    <path d="M717 540 c-20 -16 -27 -30 -27 -56 0 -60 57 -92 112 -63 14 8 23 8 31 0 19 -19 75 -13 97 11 41 45 12 122 -46 119 -74 -3 -91 -2 -114 4 -18 5 -33 0 -53 -15z"></path>{" "}
                    <path d="M129 483 c-37 -23 -59 -64 -59 -111 0 -32 -4 -42 -20 -47 -36 -11 -50 -56 -50 -159 0 -86 2 -98 24 -123 l24 -28 147 0 147 0 24 28 c22 25 24 37 24 122 0 103 -10 136 -46 156 -19 10 -24 20 -24 52 0 99 -109 161 -191 110z"></path>{" "}
                  </g>{" "}
                </svg>
              </div>
              <div className="seal-text">
                <p className="mb-0">Pago Seguro</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center my-3">
            <div className="seal guar">
              <div className="seal-icon-cta">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50pt"
                  height="67px"
                  viewBox="0 0 150 80"
                  preserveAspectRatio="xMidYMid meet"
                >
                  {" "}
                  <g
                    transform="translate(0,104) scale(0.1,-0.1)"
                    fill="#292929"
                    stroke="none"
                  >
                    {" "}
                    <path d="M500 1385 c-7 -9 -26 -25 -42 -36 -27 -20 -31 -20 -75 -5 -26 9 -50 16 -55 16 -4 0 -18 -21 -32 -47 -23 -46 -26 -48 -82 -58 -58 -10 -59 -11 -62 -45 -5 -69 -11 -79 -62 -105 -28 -14 -50 -27 -50 -29 0 -2 7 -23 15 -46 20 -58 19 -64 -15 -103 -17 -19 -30 -41 -30 -49 0 -7 14 -30 31 -51 l31 -38 -15 -55 c-17 -60 -16 -63 55 -95 28 -12 34 -28 40 -104 3 -29 5 -30 58 -33 55 -3 56 -3 83 -52 31 -55 36 -57 97 -35 l42 15 42 -35 c24 -19 47 -35 51 -35 4 0 27 16 51 35 l42 35 42 -15 c61 -22 66 -20 97 35 27 49 28 49 83 52 53 3 55 4 58 33 6 76 12 92 40 104 71 32 72 35 55 95 l-15 55 31 38 c17 21 31 44 31 51 0 8 -13 30 -30 49 -34 39 -35 45 -15 103 8 23 15 44 15 46 0 2 -22 15 -50 29 -51 26 -57 36 -62 105 -3 34 -4 35 -62 45 -56 10 -59 12 -82 58 -14 26 -28 47 -33 47 -5 0 -28 -7 -51 -15 -56 -20 -52 -20 -100 20 -47 40 -53 41 -70 20z m174 -204 c210 -103 256 -371 93 -539 -23 -24 -64 -56 -92 -70 -45 -24 -61 -27 -150 -27 -89 0 -105 3 -150 27 -161 86 -235 283 -163 439 84 183 285 257 462 170z"></path>{" "}
                    <path d="M424 1162 c-128 -45 -216 -185 -200 -316 14 -111 96 -217 193 -250 60 -20 157 -20 216 0 61 21 140 95 168 159 65 145 -3 326 -148 396 -63 30 -162 35 -229 11z m241 -192 l29 -30 -99 -100 -100 -100 -70 70 -70 70 32 32 33 32 38 -37 38 -37 64 65 c35 36 67 65 70 65 4 0 19 -14 35 -30z"></path>{" "}
                    <path d="M140 315 c-80 -168 -82 -175 -71 -194 7 -11 23 -13 69 -8 34 4 66 8 73 9 7 2 29 -24 49 -57 28 -46 43 -60 60 -60 21 0 34 20 99 158 l75 158 -31 31 c-31 30 -32 31 -76 19 -69 -20 -88 -13 -117 41 -40 76 -51 68 -130 -97z"></path>{" "}
                    <path d="M780 413 c-28 -54 -48 -62 -116 -43 -40 11 -45 11 -73 -11 -16 -13 -30 -27 -30 -31 -2 -14 132 -294 149 -311 24 -26 47 -12 75 42 35 69 41 72 114 59 54 -10 64 -10 78 5 16 15 12 27 -58 174 -87 183 -99 193 -139 116z"></path>{" "}
                  </g>{" "}
                </svg>
              </div>
              <div className="seal-text">
                <p className="mb-0">Garantía de Reembolso</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center my-3">
            <div className="seal copies">
              <div className="seal-icon-cta">
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="146pt"
                  height="50px"
                  viewBox="0 -40 150 120"
                  preserveAspectRatio="xMidYMid meet"
                >
                  {" "}
                  <g
                    transform="translate(0,84) scale(0.1,-0.1)"
                    fill="#292929"
                    stroke="none"
                  >
                    <path d="M320 995 c-162 -112 -300 -215 -307 -227 -33 -60 -4 -187 46 -203 17 -5 21 -15 22 -53 0 -26 4 -56 9 -67 6 -15 1 -28 -23 -52 -41 -44 -49 -120 -18 -181 11 -22 29 -43 38 -46 30 -8 790 -166 801 -166 17 0 516 429 520 447 2 8 -1 20 -7 26 -6 6 -11 34 -11 62 0 45 4 55 35 85 33 32 47 70 25 70 -14 0 -12 126 3 151 10 17 9 22 -6 33 -9 8 -35 16 -57 20 l-40 6 0 52 c0 32 6 58 15 68 15 17 11 50 -5 50 -5 0 -169 29 -367 65 -197 36 -363 65 -369 65 -6 0 -143 -92 -304 -205z m980 -94 l0 -59 -225 -191 -225 -191 0 61 c0 54 3 64 25 81 14 11 115 96 223 189 108 93 198 169 200 169 1 0 2 -26 2 -59z m90 -180 l0 -58 -230 -197 -230 -196 1 63 0 62 227 192 c125 106 228 193 230 193 1 0 2 -26 2 -59z m-912 -55 c173 -36 316 -66 318 -66 2 0 4 -31 4 -68 l0 -69 -298 63 c-163 34 -323 67 -354 74 l-58 11 0 69 0 68 38 -8 c20 -5 178 -38 350 -74z m32 -196 c184 -39 343 -69 353 -66 15 4 17 -4 17 -60 0 -35 -3 -64 -7 -64 -5 1 -163 33 -353 73 l-345 73 -3 57 c-2 31 -2 57 0 57 2 0 154 -32 338 -70z m803 -44 c-15 -13 -118 -101 -228 -194 l-200 -170 -3 60 c-3 62 10 87 48 88 8 0 103 75 210 166 l195 167 3 -47 c3 -40 0 -49 -25 -70z m-843 -151 c173 -36 325 -67 338 -70 20 -5 22 -11 22 -70 0 -45 -4 -65 -12 -65 -7 0 -167 32 -355 72 l-343 72 0 68 c0 61 2 69 18 64 9 -3 159 -35 332 -71z"></path>
                  </g>
                </svg>
              </div>
              <div className="seal-text">
                <p className="mb-0">1,000+ Copias Vendidas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Secure;
