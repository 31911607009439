import api from "./api";

const route = "/appconfig";

const AppConfigService = {
  getAppConfig: () => api.get(route),
  getAdminAppConfig: () => api.get(`${route}/admin`),
  updateAppConfig: (data) => api.put(route, data),
};

export default AppConfigService;
