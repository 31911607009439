import React, { useContext, useEffect } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import { FiltersContext } from "../../context/FiltersContext";
import { VideoTypeContext } from "../../context/VideoTypeContext";

const FilterForm = () => {
  const { video_types, getVideoTypes } = useContext(VideoTypeContext);

  const { coaches, getCoaches } = useContext(CoachesContext);

  const { instructor_id, video_type_id, setFilter } =
    useContext(FiltersContext);

  useEffect(() => {
    getVideoTypes();
    getCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCoaches = () => {
    if (coaches && coaches !== null) {
      return [
        <option key="todos" value="">
          Todo
        </option>,
        ...coaches.map((coach) => (
          <option key={coach.instructor_id} value={coach.instructor_id}>
            {coach.name} {coach.last_name}
          </option>
        )),
      ];
    }
  };

  const renderVideoTypes = () => {
    if (Array.isArray(video_types)) {
      return [
        <option key="todos" value="">
          Todos
        </option>,
        ...video_types.map((video_type) => (
          <option
            key={video_type.video_type_id}
            value={video_type.video_type_id}
          >
            {video_type.name}
          </option>
        )),
      ];
    }
  };
  return (
    <div className="row align-items-center">
      <div className="col-12 col-md-6 mb-2">
        <label className="mb-1">Clase</label>
        <select
          value={video_type_id !== null ? video_type_id : ""}
          className="form-control"
          onChange={(e) => setFilter("video_type_id", e.target.value)}
        >
          {renderVideoTypes()}
        </select>
      </div>
      <div className="col-12 col-md-6 mb-2">
        <label className="mb-1">Coach</label>
        <select
          value={instructor_id !== null ? instructor_id : ""}
          className="form-control"
          onChange={(e) => setFilter("instructor_id", e.target.value)}
        >
          {renderCoaches()}
        </select>
      </div>
    </div>
  );
};

export default FilterForm;
