
import {
  SET_TAG,
  CREATE_TAG,
  TAGS_RECEIVED,
  SET_PROPERTY_TAG,
} from "../types/tags";

const schema = {

}

const tagsReducer = (state, { type, payload }) => {
  switch (type) {
    case TAGS_RECEIVED:
      return { ...state, tags: payload };
    case SET_TAG:
      return { ...state, tag: payload };
    case CREATE_TAG:
      return { ...state, tag: schema };
    case SET_PROPERTY_TAG: {
      const { key, value } = payload;
      const tag = { ...state.tag };
      tag[key] = value;
      return { ...state, tag };
    }
    default:
      return { ...state};
  }
};

export default tagsReducer;
