import { getArgs } from "../utils";
import api from "./api";

const route = "/customer";

const CustomerService = {
  getCustomer: (customer_id) => api.get(`${route}/admin/${customer_id}`),
  getAllCustomers: (filters) =>
    api.get(`${route}/admin/all?${getArgs(filters)}`),
  getCustomerTagOptions: (filters) =>
    api.get(`${route}/admin/tags/all?${getArgs(filters)}`),
  getCustomersByQuery: (query) => api.get(`${route}/query?query=${query}`),
  getOnlineActiveCustomers: () => api.get(`${route}/admin/online/all`),
  getStudioActiveCustomers: () => api.get(`${route}/admin/studio/all`),
  downloadOnlineActiveCustomers: () =>
    api.get(`${route}/admin/online/all?download=true`, {
      responseType: "blob",
    }),
  downloadStudioActiveCustomers: () =>
    api.get(`${route}/admin/studio/all?download=true`, {
      responseType: "blob",
    }),
  postCustomer: (customer) => api.post(route, { ...customer }),
  postCustomerTags: (data) => api.post(`${route}/tags`, data),
  putCustomer: (customer) => api.put(route, { ...customer }),
  putCurrentCustomer: (customer) => api.put(route, { ...customer }),
  postOnlineAccess: (data) => api.post(`${route}/onlineAccess`, data),
  putOnlineAccess: (data) => api.put(`${route}/onlineAccess`, data),
  extenderAcceso: (
    customer_id,
    class_package_id,
    expiration_days,
    is_gift,
    total,
    payment_method_id
  ) =>
    api.post(`${route}/giveAccess`, {
      customer_id,
      class_package_id,
      expiration_days,
      is_gift,
      total,
      payment_method_id,
    }),
  giveClasses: (data) => api.put(`${route}/giveClasses`, data),
  revokeAccess: (purchase_id) =>
    api.put(`${route}/revokeAccess`, { purchase_id }),
  removeClasses: (customer_id, amount) =>
    api.put(`${route}/removeClasses`, { customer_id, amount }),
  recoverPassword: (email) => api.post(`${route}/recover`, { email }),
  deleteCustomer: (customer_id) => api.delete(`${route}/${customer_id}`),
  getPasswordResetLink: (email) =>
    api.post(`${route}/resetPasswordLink`, { email }),
};

export default CustomerService;
