import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import ClassTypeRow from "../../components/classTypes/ClassTypeRow";
import ClassTypeForm from "../../components/classTypes/ClassTypeForm";
import PanelTitle from "../../components/global/PanelTitle";
import { AppConfigContext } from "../../context/AppConfigContext";


const AdminClassTypes = () => {
  const [query, setQuery] = useState("");
  
  const { class_types, getClassTypes, deleteClassType } =
    useContext(ClassTypeContext);

  const { single_class_enabled } = useContext(AppConfigContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getClassTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createType = () => {
    modalComponent("Agregar Clase", <ClassTypeForm class_type_id="nuevo" />);
  };

  const editClassType = (class_type_id) => {
    modalComponent(
      "Editar Clase",
      <ClassTypeForm class_type_id={class_type_id} />
    );
  };

  const confirmDelete = (class_type) => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás seguro que deseas eliminar el tipo de clase "{class_type.name}
          "? Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteClassType(class_type.class_type_id)}
        >
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const renderTipos = () => {
    
    if (class_types && class_types !== null) {
      if (class_types.length === 0) {
        return <p>No hay tipos de clase disponibles.</p>;
      }

      let classTypesRender = class_types;
      if (query && query !== null) {
        const current = String(query).toLowerCase();
        classTypesRender = classTypesRender.filter(({ name }) => {
          name = String(name).toLowerCase();
          return name.includes(current) || name.startsWith(current);
        });
      }
      return classTypesRender.map((category) => (
        <ClassTypeRow
          key={category.class_type_id}
          category={category}
          editClassType={editClassType}
          confirmDelete={confirmDelete}
        />
      ));
    }
    return <tr><td><div className="spinner-border"></div></td></tr>;
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Tipos de Clases" onClick={createType} />
      <div className="card p-2 shadow">
        <input
          type="text"
          value={query}
          className="form-control mt-1"
          placeholder="Buscar por nombre..."
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="table-responsive mt-3">
          <table className="table border">
            <thead className="bg-light border bold small">
              <tr className="bold mx-0 border align-items-center">
                <td>Nombre</td>
                <td>Icono</td>
                <td>Color</td>
                {single_class_enabled && <td>Mapa</td>}
                <td className="text-center">Acciones</td>
              </tr>
            </thead>
            <tbody>{renderTipos()}</tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default AdminClassTypes;
