import React from "react";
import PurchaseListItem from "./PurchaseListItem";

const PurchasesList = ({ purchases }) => {
  return (
    <div>
      {!Array.isArray(purchases) && <div className="spinner-border" />}
      {purchases?.length === 0 && (
        <p className="small mb-0">No hay compras registradas con los filtros seleccionados.</p>
      )}
      {purchases?.map((purchase) => (
        <PurchaseListItem key={purchase.purchase_id} purchase={purchase} />
      ))}
    </div>
  );
};

export default PurchasesList;
