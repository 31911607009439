import {
  ADD_CLASS_PACKAGE_TYPE,
  CREATE_PAQUETE,
  PAQUETES_RECIBIDOS,
  REMOVE_CLASS_PACKAGE_TYPE,
  RESERVATIONS_RECIBIDAS,
  SET_PACKAGE_CLASS_TYPE_PROPERTY,
  SET_PAQUETE,
  SET_PROPIEDAD_PAQUETE,
} from "../types";
import { generateRandomKey } from "../utils";

const schema = {
  class_package_id: "nuevo",
  title: "",
  short_description: "",
  description: "",
  first_check: "",
  price: "",
  sale_price: null,
  is_subscription: false,
  include_online: true,
  is_special_event: false,
  expiration_days: null,
  customer_limit: null,
  available: true,
  class_package_types: [],
};


const class_package_type_schema = {
  class_type_id: "",
};

const PaquetesReducer = (state, { type, payload }) => {
  switch (type) {
    case PAQUETES_RECIBIDOS:
      return { ...state, paquetes: payload };
    case SET_PAQUETE:
      return { ...state, paquete: payload };
    case SET_PROPIEDAD_PAQUETE: {
      const { key, value } = payload;
      const paquete = { ...state.paquete };
      paquete[key] = value;
      return { ...state, paquete };
    }
    case SET_PACKAGE_CLASS_TYPE_PROPERTY: {
      const { key, value, object_key } = payload;
      const class_package = { ...state.paquete };
      const class_package_types = [...class_package.class_package_types];
      
      const category = class_package_types.find(
        (category) => String(object_key) === String(category.object_key)
      );
      
      if (category) {
        category[key] = value;
      }
      return { ...state, paquete: { ...class_package, class_package_types } };
    }
    case ADD_CLASS_PACKAGE_TYPE: {
      const class_package = { ...state.paquete };
      let class_package_types = [...class_package.class_package_types];
      if (!Array.isArray(class_package_types)) {
        class_package_types = [];
      }
      // if (hasEmptyCategory(class_package_types)) {
      //   return { ...state, class_package_types };
      // }
  
      const current_schema = { 
        ...class_package_type_schema,
        object_key: generateRandomKey(),  
      };

      current_schema.class_package_id = class_package.class_package_id;
      class_package_types.push(current_schema);
      
      return {
        ...state,
        paquete: { ...class_package, class_package_types },
      };
    }
    case REMOVE_CLASS_PACKAGE_TYPE: {
      const class_package = { ...state.paquete };
      const class_package_types = [...class_package.class_package_types];
      if (Array.isArray(class_package_types)) {
        const index = class_package_types.findIndex(
          (current) =>
            String(current.object_key) ===
            String(payload)
        );
        if (index !== -1) {
          class_package_types.splice(index, 1);
        }
      }
      return { ...state, paquete: { ...class_package, class_package_types } };
    }
    case CREATE_PAQUETE:
      return { ...state, paquete: schema };
    case RESERVATIONS_RECIBIDAS:
      return { ...state, asistentes: payload };
    default:
      return { ...state };
  }
};

export default PaquetesReducer;
