import React, { useContext } from "react";
import { TagsContext } from "../../context/TagsContext";
import { getValue } from "../../utils";

const TagForm = ({ handleCancel }) => {
  const { tag, getTags, saveTag, setPropertyTag } = useContext(TagsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveTag(tag, getTags);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Nombre</label>
      <input
        type="text"
        value={getValue(tag, "name")}
        className="form-control mb-3"
        onChange={(e) => setPropertyTag("name", e.target.value)}
      />
      <label>Color</label>
      <input
        type="color"
        value={getValue(tag, "color")}
        className="form-control mb-3"
        onChange={(e) => setPropertyTag("color", e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default TagForm;