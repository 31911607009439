import React, { useContext, useEffect } from "react";
import { ReservationsContext } from "../../context/ReservationsContext";
import CustomerData from "../../components/customers/CustomerData";
import { getFriendlyLocalDateTimeString } from "../../utils/dates";

const AdminSingleReservation = ({ class_reservation_id }) => {
  const { reservation, postAttend, getSingleReservation } =
    useContext(ReservationsContext);

  useEffect(() => {
    getSingleReservation(class_reservation_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_reservation_id]);

  const renderIcon = (single_class) => {
    if (single_class && single_class !== null) {
      const { class_type } = single_class;
      if (class_type && class_type !== null) {
        return <i className={class_type.icon}></i>;
      }
    }
  };

  const renderName = (single_class) => {
    const { class_type } = single_class;
    if (class_type && class_type !== null) {
      return single_class.class_type.name;
    }
  };

  const renderCustomer = () => {
    if (reservation && reservation !== null && reservation.customer) {
      const { customer } = reservation;
      return(
        <div className="card mb-3">
          <div className="row">
            <CustomerData customer={customer} hideEdit/>
          </div>
        </div>
      )
    }
  };

  const renderCoach = () => {
    const coaches = reservation?.single_class?.class_instructors;

    if(coaches?.length > 0) {
      if(coaches.length === 1) {
        return (
          <p>Coach: {coaches[0].instructor.name} {coaches[0].instructor.last_name}</p>
        )
      } else {
        let currentCoaches = '';

        coaches.forEach(coach => {
          const current = coach.instructor;
          const name = `${current.name} ${current.last_name}`;
          currentCoaches += `${name}, `;
        })

        return (
          <p>Coaches: {currentCoaches}</p>
        )
      }
    }
  }

  const renderReservation = () => {
    if (reservation && reservation !== null) {
      const { single_class } = reservation;
      
      if (single_class && single_class !== null) {
        return (
          <div>
            <h2>
              {getFriendlyLocalDateTimeString(single_class.class_date)}
            </h2>
            <h3>
              {renderIcon(single_class)} {renderName(single_class)}
            </h3>
            {renderCoach()}
            <p>Spot: {reservation.spot}</p>
          </div>
        );
      }
    }
  };

  const renderButton = () => {
    if (reservation && reservation !== null) {
      if (reservation.attend) {
        return (
          <button disabled={true} className="btn btn-outline-success">
            Asistencia Registrada
          </button>
        );
      }
      return (
        <button
          className="btn btn-primary"
          onClick={() =>
            postAttend(class_reservation_id, true, reservation.single_class_id)
          }
        >
          Registrar Asistencia
        </button>
      );
    }
  };

  return (
    <div className="container-fluid" style={{ maxWidth: '700px' }}>

      <div className="card text-center mb-3 p-3">
        {renderReservation()}
        {renderButton()}
      </div>

      {renderCustomer()}

    </div>
  );
};

export default AdminSingleReservation;
