import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const ManagerTabs = () => {
  const appconfig = useContext(AppConfigContext);
  return (
    <ul className="side-menu-list">
      {getValue(appconfig, "single_class_enabled", "boolean") && (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#clasesDropdown"
            role="button"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="clasesDropdown"
          >
            Clases
          </a>
          <ul className="collapse collapse-menu" id="clasesDropdown">
            <li className="nav-item text-item">
              <Link to="/myadmin/clases" className="nav-link">
                Horarios
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/asistentes" className="nav-link">
                Asistentes
              </Link>
            </li>
          </ul>
        </li>
      )}
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#clientesDropdown"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-controls="clientesDropdown"
        >
          Clientes
        </a>
        <ul id="clientesDropdown" className="collapse collapse-menu">
          <li className="nav-item text-item">
            <Link to="/myadmin/customers" className="nav-link">
              Clientes
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/orders" className="nav-link">
              Pagos
            </Link>
          </li>
          {getValue(appconfig, "videos_enabled", "boolean") && (
            <li className="nav-item text-item">
              <Link to="/myadmin/online" className="nav-link">
                Online
              </Link>
            </li>
          )}
          {getValue(appconfig, "single_class_enabled", "boolean") && (
            <li className="nav-item text-item">
              <Link to="/myadmin/presenciales" className="nav-link">
                Créditos
              </Link>
            </li>
          )}
        </ul>
      </li>
      {getValue(appconfig, "single_class_enabled", "boolean") ? (
        <>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#paquetesDropdown"
              role="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-controls="paquetesDropdown"
            >
              Paquetes
            </a>
            <ul className="collapse collapse-menu" id="paquetesDropdown">
              <li className="nav-item text-item">
                <Link to="/myadmin/paquetes" className="nav-link">
                  Regulares
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/especiales" className="nav-link">
                  Especiales
                </Link>
              </li>
            </ul>
          </li>
        </>
      ) : (
        <li className="nav-item text-item">
          <Link to="/myadmin/paquetes" className="nav-link">
            Paquetes
          </Link>
        </li>
      )}
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#atributosDropdown"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-controls="atributosDropdown"
        >
          Herramientas
        </a>
        <ul className="collapse collapse-menu" id="atributosDropdown">
          <li className="nav-item text-item">
            <Link to="/myadmin/class_types" className="nav-link">
              Tipos de Clase
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/coaches" className="nav-link">
              Coaches
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/ubicaciones" className="nav-link">
              Ubicaciones
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/payment_methods" className="nav-link">
              Métodos de Pago
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/tags" className="nav-link">
              Etiquetas
            </Link>
          </li>
          {getValue(appconfig, "videos_enabled", "boolean") && (
            <>
              <li className="nav-item text-item">
                <Link to="/myadmin/categorias" className="nav-link">
                  Categorías
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/video_types" className="nav-link">
                  Tipos de Video
                </Link>
              </li>
            </>
          )}
          {getValue(appconfig, "pages_enabled", "boolean") && (
            <li className="nav-item text-item">
              <Link to="/myadmin/pages" className="nav-link">
                Páginas
              </Link>
            </li>
          )}
          {getValue(appconfig, "testimonials_enabled", "boolean") && (
            <li className="nav-item text-item">
              <Link to="/myadmin/testimonios" className="nav-link">
                Testimonios
              </Link>
            </li>
          )}
        </ul>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/descuentos" className="nav-link">
          Descuentos
        </Link>
      </li>
      {getValue(appconfig, "videos_enabled", "boolean") && (
        <li className="nav-item text-item">
          <Link to="/myadmin/videos" className="nav-link">
            Videos
          </Link>
        </li>
      )}
      <li className="nav-item text-item">
        <Link to="/myadmin/users" className="nav-link">
          Usuarios
        </Link>
      </li>
      {getValue(appconfig, "ratings_enabled", "boolean") && (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#calificaciones"
            role="button"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="calificaciones"
          >
            Ratings
          </a>
          <ul className="collapse collapse-menu" id="calificaciones">
            {getValue(appconfig, "videos_enabled", "boolean") && (
              <li className="nav-item text-item">
                <Link to="/myadmin/videos/ratings" className="nav-link">
                  Videos
                </Link>
              </li>
            )}
            {getValue(appconfig, "single_class_enabled", "boolean") && (
              <li className="nav-item text-item">
                <Link to="/myadmin/single_classes/ratings" className="nav-link">
                  Clases
                </Link>
              </li>
            )}
          </ul>
        </li>
      )}
    </ul>
  );
};

export default ManagerTabs;
