import api from "./api";

const route = "/testimonials";

const TestimoniosService = {
  getTestimonioById: (testimonial_id) => api.get(`${route}/${testimonial_id}`),
  getAllTestimonios: () => api.get(`${route}/all`),
  postTestimonial: (data) => api.post(`${route}`, data),
  updateTestimonial: (testimonio_id, data) => api.put(`${route}/${testimonio_id}`, data),
  deleteTestimonial: (testimonial_id) => api.delete(`${route}/${testimonial_id}`),
};

export default TestimoniosService;
