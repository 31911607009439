import React, { useContext } from "react";
import { hideModal } from "../../utils";
import { VideoTypeContext } from "../../context/VideoTypeContext";

const VideoTypeForm = () => {
  const { video_type, postVideoType, setPropertyVideoType } =
    useContext(VideoTypeContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postVideoType(video_type);
  };

  const renderForm = () => {
    if (video_type && video_type !== null) {
      const { name, description } = video_type;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropertyVideoType("name", e.target.value)}
          />
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={description}
            onChange={(e) =>
              setPropertyVideoType("description", e.target.value)
            }
          />
          <div className="row">
            <div className="col-6">
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Guardar
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                onClick={hideModal}
                className="btn btn-link text-muted w-100 mt-3"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default VideoTypeForm;
