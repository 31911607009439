import React from "react";
import { formatMonto } from "../../utils";
import { getFriendlyLocalDateString } from "../../utils/dates";

const DiscountRow = ({ discount, hideSales, showCommissions }) => {
  const getSales = () => {
    let total = 0;
    if (discount.invoice_sales && discount.invoice_sales !== null) {
      total += parseInt(discount.invoice_sales);
    }
    if (discount.purchase_sales && discount.purchase_sales !== null) {
      total += parseInt(discount.purchase_sales);
    }
    return total;
  };

  const getTransactions = () => {
    let total = 0;
    if (discount.invoices && discount.invoices !== null) {
      total += parseInt(discount.invoices);
    }
    if (discount.purchases && discount.purchases !== null) {
      total += parseInt(discount.purchases);
    }
    return total;
  };

  const getCommissions = () => {
    const total = getSales();
    return total * 0.15;
  };

  return (
    <tr>
      <td>{discount.code}</td>
      <td>{getFriendlyLocalDateString(discount.createdAt)}</td>
      <td>{formatMonto(getTransactions())}</td>
      {!hideSales && <td>${formatMonto(getSales())}</td>}
      {showCommissions && <td>${formatMonto(getCommissions())}</td>}
    </tr>
  );
};

export default DiscountRow;
