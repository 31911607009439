import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { hideModal } from "../../utils";
import elements from "../../utils/elements";
import AdjuntosService from "../../services/AdjuntosService";
import { EDITOR_JS_TOOLS, htmlToBlocks, parseBlock } from "./EditorTools";
import { createReactEditorJS } from "react-editor-js";
import { AppConfigContext } from "../../context/AppConfigContext";

const EditorJS = createReactEditorJS();

const ElementForm = ({ dbref, name, props, move, file, setFile }) => {
  const [elementValues, setElementValues] = useState({});
  const [customProp, setCustomProp] = useState(null);
  const [hasCustom, setHasCustom] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [instance, setInstance] = useState(null);
  const [fileKey, setFileKey] = useState(null);
  const [blocks, setblocks] = useState([]);

  const { alert, success, modalComponent } = useContext(ModalContext);

  const { S3_ENDPOINT } = useContext(AppConfigContext);

  useEffect(() => {
    let elementName = name.split("_")[0];
    let elementProps = elements[elementName];
    let object = {};
    Object.keys(elementProps).forEach((key) => {
      if (String(elementProps[key]) === "custom") {
        setCustomProp(key);
        setHasCustom(true);
      }
      if (props[key]) {
        object[key] = props[key];
      } else {
        object[key] = "";
      }
      if (String(object[key]).includes("<")) {
        let blocks = htmlToBlocks(object[key]);
        setblocks(blocks);
      }
    });
    setElementValues({ ...object });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (e, key) => {
    setFile(e.target.files[0]);
    setFileKey(key);
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    modalComponent(
      "¡Precaución!",
      <div>
        <p>
          ¿Estás seguro que deseas eliminar el elemento {name}? Esta acción NO
          puede deshacerse.
        </p>
        <button className="btn btn-danger" onClick={deleteElement}>
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const deleteElement = () => {
    dbref.child(name).remove((error) => {
      if (error && error !== null) {
        return alert(error);
      }
      success(`Elemento ${name} eliminado con éxito.`);
    });
    hideModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let values = { ...elementValues };
    if (hasCustom) {
      const data = await instance.save();
      let content = "";
      data.blocks.forEach((block) => {
        content += parseBlock(block);
      });
      values[customProp] = content;
    }
    if (file !== null) {
      const formData = new FormData();
      formData.append("file", file);
      AdjuntosService.postAdjunto(formData).then((res) => {
        setFile(null);
        const { file } = res.data;
        values.src = `${S3_ENDPOINT}/${file.name}.${file.type}`;
        delete values[fileKey];
        updateValues(values);
      });
    } else {
      updateValues(values);
    }
  };

  const updateValues = (elementValues) => {
    dbref.child(name).update(elementValues, (error) => {
      if (error && error !== null) return alert(`Error al guardar ${name}`);
      success(`Cambios de ${name} guardados.`);
    });
  };

  const handleChangeArray = (e, key, index) => {
    const { value } = e.target;
    let current = { ...elementValues };
    let array = current[key];
    array[index] = value;
    setElementValues(current);
  };

  const duplicate = () => {
    let nuevo = name.split("_")[0];
    if (name.split("_")[1]) {
      nuevo += `_${parseInt(name.split("_")[1]) + 1}`;
    }
    dbref.once("value", (snap) => {
      const tree = snap.val();
      const orden = Object.keys(tree).length - 1;
      dbref
        .child(nuevo)
        .set({ ...props, orden })
        .then(() => {
          success(`Elemento ${nuevo} creado con éxito.`);
        });
    });
  };

  const addItem = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    let current = { ...elementValues };
    let array = current[key];
    array.push("");
    setElementValues(current);
  };

  const deleteArrayElement = (e, key, index) => {
    e.preventDefault();
    e.stopPropagation();
    let current = { ...elementValues };
    let array = current[key];
    array.splice(index, 1);
    setElementValues(current);
  };

  const renderProps = () => {
    if (Object.keys(elementValues).length > 0) {
      let elementName = name.split("_")[0];
      let elementProps = elements[elementName];
      return Object.keys(elementValues)
        .filter((key) => key !== "src")
        .map((key, index) => (
          <div key={index}>
            {elementProps[key] === "custom" ? (
              <div className="border p-2 mb-3">
                <EditorJS
                  data={{ blocks }}
                  instanceRef={(current) => setInstance(current)}
                  tools={EDITOR_JS_TOOLS}
                />
              </div>
            ) : elementProps[key] === "array" ? (
              <div key={index}>
                <p className="bold mb-0">Items</p>
                {Array.from(elementValues[key]).map((value, index) => (
                  <div className="row" key={`arr-${index}`}>
                    <div className="col-6 col-md-10">
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={value}
                        onChange={(e) => handleChangeArray(e, key, index)}
                      />
                    </div>
                    <div className="col-6 col-md-2">
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => deleteArrayElement(e, key, index)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  onClick={(e) => addItem(e, key)}
                  className="btn btn-outline-primary mb-3"
                >
                  + Agregar Item
                </button>
              </div>
            ) : Array.isArray(elementProps[key]) ? (
              <div key={index}>
                <label className="bold text-capitalize">{key}</label>
                <select
                  className="form-control mb-3"
                  value={elementValues[key]}
                  onChange={(e) =>
                    setElementValues({
                      ...elementValues,
                      [key]: e.target.value,
                    })
                  }
                >
                  {elementProps[key].map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ) : elementProps[key] === "file" ? (
              <div key={index}>
                <label className="bold text-capitalize">{key}</label>
                <input
                  type={elementProps[key]}
                  className="form-control mb-3"
                  placeholder={key}
                  onChange={(e) => handleFileChange(e, key)}
                />
              </div>
            ) : (
              <div key={index}>
                <label className="bold text-capitalize">{key}</label>
                <input
                  type={elementProps[key]}
                  className="form-control mb-3"
                  placeholder={key}
                  value={elementValues[key]}
                  onChange={(e) =>
                    setElementValues({
                      ...elementValues,
                      [key]: e.target.value,
                    })
                  }
                />
              </div>
            )}
          </div>
        ));
    }
  };

  return (
    <div className="container-fluid my-3 card  p-3 shadow-sm">
      <div className="row align-items-center border-bottom pb-3 mb-3">
        <div className="col-12 col-md-8">{name}</div>
        <div className="col-12 col-md-4 text-end">
          <button
            className="btn btn-light mx-2"
            onClick={() => setCollapse(!collapse)}
          >
            <i
              className={`fa fa-${collapse ? "plus-square" : "minus-square"}`}
            ></i>
          </button>
          <button className="btn btn-light mx-2" onClick={duplicate}>
            <i className="fa fa-copy"></i>
          </button>
          <button
            className="btn btn-light mx-2"
            onClick={() => move("down", name)}
          >
            <i className="fa fa-chevron-down"></i>
          </button>
          <button className="btn btn-light" onClick={() => move("up", name)}>
            <i className="fa fa-chevron-up"></i>
          </button>
        </div>
      </div>
      {!collapse && (
        <form onSubmit={handleSubmit}>
          {renderProps()}
          <div className="row">
            <div className="col-12 col-md-6">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
            </div>
            <div className="col-12 col-md-6 text-end">
              <button
                className="btn btn-outline-danger"
                onClick={confirmDelete}
              >
                <i className="fa fa-trash"></i> Eliminar
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ElementForm;
