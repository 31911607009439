import api from "./api";
import { getArgs } from "../utils";

const route = "/analytics";

const AnaliticasService = {
  getInscritos: (start_date, end_date) =>
    api.get(`${route}/customers?start_date=${start_date}&end_date=${end_date}`),
  getPaquetes: (start_date, end_date) =>
    api.get(`${route}/purchases?start_date=${start_date}&end_date=${end_date}`),
  getIngresos: (start_date, end_date) =>
    api.get(`${route}/income?start_date=${start_date}&end_date=${end_date}`),
  getInstructores: (fecha_inicio, fecha_fin) =>
    api.get(
      `${route}/instructors?start_date=${fecha_inicio}&end_date=${fecha_fin}`
    ),
  getMensuales: (start_date, end_date) =>
    api.get(`${route}/monthly?start_date=${start_date}&end_date=${end_date}`),
  getReservaciones: (filters) =>
    api.get(
      `${route}/reservations?${getArgs(filters)}`
    ),
  getReporte: (start_date, end_date) =>
    api.get(
      `${route}/instructors/report?start_date=${start_date}&end_date=${end_date}`,
      {
        responseType: "blob",
      }
    ),
  getFreeTrials: (start_date, end_date) =>
    api.get(
      `${route}/free_trials?start_date=${start_date}&end_date=${end_date}`
    ),
  getVideos: (start_date, end_date) =>
    api.get(`${route}/videos?start_date=${start_date}&end_date=${end_date}`),
  getLeaderboard: (filters) =>
    api.get(`${route}/leaderboard?${getArgs(filters)}`),
  getDescuentos: (filters) => api.get(`${route}/discounts?${getArgs(filters)}`),

};

export default AnaliticasService;
