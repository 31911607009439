import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { Link } from "@reach/router";

const CustomersHeader = ({ sort, page, direction }) => {
  const [query, setQuery] = useState("");
  const [field, setField] = useState("");

  const { getAllCustomers } = useContext(CustomerContext);

  useEffect(() => {
    getAllCustomers({ page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = () => {
      getAllCustomers({ query, page, field, sort, direction });
    };

    if (query.length > 0) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, field, sort, direction]);
  
  return (
    <div className="row mx-0 align-items-center border-bottom mb-3">
      <div className="col-12 col-md-4 mb-3 px-0">
        <div className="row">
          <div className="col-8">
            <h1 className="mb-0">Clientes</h1>
          </div>
          <div className="col-4 show-mobile">
            <Link
              to="/myadmin/customer/nuevo/edit"
              className="btn btn-primary w-100"
            >
              + Agregar
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-8 mb-3 px-0">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="row">
              <div className="col-8 pr-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div className="col-4 pl-0">
                <select
                  className="form-control"
                  value={field}
                  onChange={(e) => setField(e.target.value)}
                >
                  <option value="">Todo</option>
                  <option value="name">Nombre</option>
                  <option value="last_name">Apellidos</option>
                  <option value="email">Correo</option>
                  <option value="phone">Teléfono</option>
                  <option value="tags">Etiquetas</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 hide-mobile col-md-4 text-right">
            <Link
              to="/myadmin/customer/nuevo/edit"
              className="btn btn-primary w-100"
            >
              + Agregar
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersHeader;
