import { useContext, useEffect, useState } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import SecureInput from "./SecureInput";


const FilesLocationInput = () => {
  const [currentLocation, setCurrentLocation] = useState('server'); 

  const { 
    setAppConfigProperty,
    files_location,
    aws_bucket_name,
    aws_access_key,
    aws_secret_key,
    aws_bucket_region,
  } = useContext(AppConfigContext);

  const awsInputs = [
    {
      key: 'aws_bucket_name',
      label: 'Bucket Name',
      value: aws_bucket_name,
    },
    {
      key: 'aws_access_key',
      label: 'Access Key',
      value: aws_access_key,
    },
    {
      key: 'aws_secret_key',
      label: 'Secret Key',
      value: aws_secret_key,
    },
    {
      key: 'aws_bucket_region',
      label: 'Bucket Region',
      value: aws_bucket_region,
    },
  ]

  useEffect(() => {
    if(files_location === 'server') setCurrentLocation('server');
    if(files_location === 'aws') setCurrentLocation('aws');

  }, [files_location]);

  const handleChangeAwsData = (key, newValue) => {
    setAppConfigProperty(key, newValue);
  }

  const handleChangeLocation = (location) => {
    setAppConfigProperty('files_location', location);
  }

  const renderAwsForm = () => {
    if(files_location === 'aws') {
      return(
        <div className="col-12 px-0 ps-3">
          {
            awsInputs.map((input, index) => {
              return (
                <div key={input.key} className="row w-100 m-auto mb-2">
                  <SecureInput
                    smallTitle
                    type={'text'}
                    hideValue
                    label={input.label}
                    value={input.value}
                    handleChange={(e) => handleChangeAwsData(input.key, e.target.value)}
                  />
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  return(
    <div className="row w-100 mx-auto mb-3">

      <div className="col-12 px-0 mb-2">
        <SecureInput
          type={'select'}
          hideValue
          label={'Ubicación de Archivos'}
          value={currentLocation}
          handleChange={(event) => {
            handleChangeLocation(event.target.value);
          }}
          selectOptions={[
            {
              value: 'server',
              label: 'Almacenamiento Local'
            },
            {
              value: 'aws',
              label: 'Bucket S3'
            },
          ]}
        />
      </div>
     
      {renderAwsForm()}

    </div>
  )
}

export default FilesLocationInput;