import React, { useContext } from "react";
import { Router } from "@reach/router";
import AdminCustomers from "./admin/AdminCustomers";
import AdminClassPackages from "./admin/AdminClassPackages";
import AdminPaqueteForm from "./admin/AdminPaqueteForm";
import AdminSchedule from "./admin/AdminSchedule";
import AdminSesiones from "./admin/AdminSesiones";
import AdminSingleClass from "./admin/AdminSingleClass";
import AdminLocations from "./admin/AdminLocations";
import AdminCoaches from "./admin/AdminCoaches";
import AdminSingleUsuario from "./admin/AdminSingleUsuario";
import AdminCategories from "./admin/AdminCategories";
import AdminSingleCategory from "./admin/AdminSingleCategory";
import AdminHome from "./admin/AdminHome";
import AdminClassTypes from "./admin/AdminClassTypes";
import AdminDescuentos from "./admin/AdminDescuentos";
import AdminEspeciales from "./admin/AdminEspeciales";
import AdminAsistentesEspeciales from "./admin/AdminAsistentesEspeciales";
import AdminVideos from "./admin/AdminVideos";
import AdminVideoForm from "./admin/AdminVideoForm";
import AdminCustomerForm from "./admin/AdminCustomerForm";
import AdminUsuarios from "./admin/AdminUsuarios";
import AdminInvoices from "./admin/AdminInvoices";
import AdminPurchases from "./admin/AdminPurchases";
import AdminOnline from "./admin/AdminOnline";
import AdminPresenciales from "./admin/AdminPresenciales";
import AdminPages from "./admin/AdminPages";
import AdminPageForm from "./admin/AdminPageForm";
import { AppConfigContext } from "../context/AppConfigContext";
import AdminVideoTypes from "./admin/AdminVideoTypes";
import AdminSingleReservation from "./admin/AdminSingleReservation";
import AdminAjustes from "./admin/AdminAjustes";
import AdminTestimonios from "./admin/AdminTestimonios";
import AdminSingleTestimonio from "./admin/AdminSingleTestimonio";
import AdminPaymentMethods from "./admin/AdminPaymentMethods";
import AdminClassesRatings from "./admin/AdminClassesRatings";
import AdminVideoRatings from "./admin/AdminVideoRatings";
import AdminTags from "./admin/AdminTags";
import { getValue } from "../utils";

const Admin = () => {
  const appconfig = useContext(AppConfigContext);

  const renderSingleClassTabs = () => {
    if (getValue(appconfig, "single_class_enabled", "boolean")) {
      return (
        <>
          <AdminSchedule path="/clases/*" />
          <AdminSesiones path="/asistentes" />
          <AdminLocations path="/ubicaciones" />
          <AdminEspeciales path="/especiales" />
          <AdminPresenciales path="/presenciales" />
          {getValue(appconfig, "ratings_enabled", "boolean") && (
            <AdminClassesRatings path="/single_classes/ratings" />
          )}
          <AdminSingleClass path="/asistentes/:single_class_id" />
          <AdminAsistentesEspeciales path="/especiales/:class_package_id" />
        </>
      );
    }
  };

  const renderVideoTabs = () => {
    if (getValue(appconfig, "videos_enabled", "boolean")) {
      return (
        <>
          <AdminOnline path="/online" />
          <AdminVideos path="/videos" default />
          <AdminVideoTypes path="/video_types" />
          <AdminVideoForm path="/videos/:video_id" />
          {getValue(appconfig, "ratings_enabled", "boolean") && (
            <AdminVideoRatings path="/videos/ratings" />
          )}
        </>
      );
    }
  };

  return (
    <Router>
      {renderVideoTabs()}
      {renderSingleClassTabs()}
      <AdminTags path="/tags" />
      <AdminHome path="/home" />
      <AdminPages path="/pages" />
      <AdminUsuarios path="/users" />
      <AdminCoaches path="/coaches" />
      <AdminClassPackages path="/paquetes" />
      <AdminAjustes path="/ajustes/*" />
      <AdminCustomers path="/customers" />
      <AdminCategories path="/categorias" />
      <AdminDescuentos path="/descuentos" />
      <AdminClassTypes path="/class_types" />
      <AdminInvoices path="/subscriptions" />
      <AdminPageForm path="/pages/:page_id" />
      <AdminPurchases path="/orders" default />
      <AdminPaymentMethods path="/payment_methods" />
      <AdminSingleUsuario path="/customer/:customer_id" />
      <AdminPaqueteForm path="/paquetes/:class_package_id" />
      <AdminCustomerForm path="/customer/:customer_id/edit" />
      <AdminSingleCategory path="/categorias/:class_category_id" />
      <AdminSingleReservation path="/reservation/:class_reservation_id" />
      {getValue(appconfig, "testimonials_enabled", "boolean") && (
        <>
          <AdminTestimonios path="/testimonios" />
          <AdminSingleTestimonio path="/testimonios/:testimonial_id" />
        </>
      )}
    </Router>
  );
};

export default Admin;
