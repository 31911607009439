import React, { useState, useEffect, useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import WeekScheduleAdminDesktop from "./WeekScheduleAdminDesktop";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import MonthScheduleAdmin from "./MonthScheduleAdmin";
import WeekScheduleMobile from "./WeekScheduleMobile";
import useSchedule from "../../hooks/useSchedule";
import "./schedule.css";

const ScheduleAdmin = () => {
  const [currentClasses, setCurrentClasses] = useState(null);
  const { days, view, setView } = useContext(SingleClassContext);
  const {
    weekNumber,
    yearString,
    monthString,
    setNextMonth,
    setPrevMonth,
    fetchSchedule,
    handleNextWeek,
    handlePrevWeek,
  } = useSchedule();

  useEffect(() => {
    window.onresize = handleResize;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const handleResize = () => {
    if(window.innerWidth < 767) {
      setView("week");
    }
  }

  const renderScheduleView = () => {
    if (!Array.isArray(days)) return <div className="spinner-border" />;
    if (view === "week") {
      return (
        <div>
          <WeekScheduleAdminDesktop weekClasses={currentClasses} />
          <WeekScheduleMobile weekClasses={currentClasses} />
        </div>
      );
    }
    return (
      <MonthScheduleAdmin
        monthClasses={currentClasses}
        handleCallback={fetchSchedule}
      />
    );
  };

  return (
    <div className="container-fluid px-0" style={{ overflowX: "hidden" }}>
      <div className="container-fluid px-0">
        <div className="row align-items-center mt-mb-3 mb-md-4 my-2">
          <div className="col-12 col-md-4 mb-3">
            <ScheduleMonthSelect
              yearString={yearString}
              monthString={monthString}
              setNextMonth={setNextMonth}
              setPrevMonth={setPrevMonth}
            />
          </div>
          <div className="col-12 col-md-4 mb-3">
            {view === "week" && (
              <ScheduleWeekSelect
                weekNumber={weekNumber}
                handleNextWeek={handleNextWeek}
                handlePrevWeek={handlePrevWeek}
              />
            )}
          </div>
          <div className="hide-mobile col-12 col-md-4 mb-3 text-end">
            <div className="btn-group">
              <button
                onClick={() => setView("week")}
                className={`btn border btn-${
                  view === "week" ? "primary" : "light"
                }`}
              >
                Semana
              </button>
              <button
                onClick={() => setView("month")}
                className={`btn border btn-${
                  view === "month" ? "primary" : "light"
                }`}
              >
                Mes
              </button>
            </div>
          </div>
        </div>
      </div>
      {renderScheduleView()}
    </div>
  );
};

export default ScheduleAdmin;
