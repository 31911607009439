import React, { useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import SingleClassTable from "../../components/clases/SingleClassTable";

const AdminSesiones = () => {
  const { clases, getClases } = useContext(SingleClassContext);

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Asistentes" callback={getClases} />
      <div className="card  p-3">
        <SingleClassTable single_classes={clases} />
      </div>
    </div>
  );
};

export default AdminSesiones;
