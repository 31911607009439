import React, { useEffect } from "react";
import { Router, navigate } from "@reach/router";
import Login from "./views/Login";
import Panel from "./views/Panel";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import Loading from "./views/Loading";
import { AppConfigContext } from "./context/AppConfigContext";
import OffCanvas from "./components/global/OffCanvas";
import { setupTimezone } from "./utils/dates";
import { handleColors, handleMetaTags } from "./utils/appconfig";

const Main = () => {
  const { user, userLoggedIn, setupInterceptors } = useContext(UserContext);

  const config = useContext(AppConfigContext);

  useEffect(() => {
    setupInterceptors();
    userLoggedIn();
    if (config.timezone && config.timezone !== null) {
      setupTimezone(config.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.role === "coach") {
      navigate("/myadmin/clases/");
    }

    const { getAdminAppConfig } = config;
    if (["super_admin", "admin"].includes(user?.role)) {
      getAdminAppConfig();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    handleColors(config);
    handleMetaTags(config);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <div className="container-fluid px-0 vh-100">
      <Router>
        <Login path="/entrar" default />
        {user !== null ? (
          <Panel path="/myadmin/*" />
        ) : (
          <Loading path="/myadmin/*" />
        )}
      </Router>
      <Modal />
      <OffCanvas />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
