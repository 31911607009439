import React from "react";
import ReservacionRow from "./ReservacionRow";

const ReservacionesTable = ({ reservaciones, deleteCallback }) => {
  const renderReservaciones = () => {
    if (Array.isArray(reservaciones)) {
      if (reservaciones.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay reservaciones registradas.</td>
          </tr>
        );
      }
      return reservaciones.map((reservacion) => (
        
        <ReservacionRow
          key={reservacion.class_reservation_id}
          reservation={reservacion}
          deleteCallback={deleteCallback}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>Clase</td>
            <td>Coach</td>
            <td>Fecha</td>
            <td>Reservada en</td>
            <td>Status</td>
            <td className="text-center">Spot</td>
            <td className="">Teléfono</td>
            <td className="text-center">Acciones</td>
          </tr>
        </thead>
        <tbody>{renderReservaciones()}</tbody>
      </table>
    </div>
  );
};

export default ReservacionesTable;
