import React, { useContext, useEffect, useState } from "react";
import elements from "../../utils/elements";
import { ModalContext } from "../../context/ModalContext";

const NewElement = ({ dbref, orden, disable, currentElements }) => {
  const [name, setName] = useState("Attention");
  const [number, setNumber] = useState("");

  const { success } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const child = `${name}_${number}`;
    dbref
      .child(child)
      .set({ ...elements[name], orden })
      .then(() => {
        success("¡Elemento agregado con éxito!");
        disable();
      });
  };

  useEffect(() => {
    let sameElements = currentElements.filter((elem) =>
      elem.component.includes(name)
    ).length;
    setNumber(sameElements + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <div className="container px-0 my-3">
      <form className="card  p-3 shadow-sm" onSubmit={handleSubmit}>
        <label className="bold">Tipo</label>
        <select
          className="form-control mb-3"
          value={name}
          onChange={(e) => setName(e.target.value)}
        >
          {Object.keys(elements).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
        <button type="submit" className="btn btn-success">
          Guardar
        </button>
      </form>
    </div>
  );
};

export default NewElement;
