import React, { useContext, useState } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import CustomersTable from "../../components/customers/CustomersTable";

const customerExtraFields = [
  { label: "Totales", key: "total_classes" },
  { label: "Reservadas", key: "used_classes" },
  { label: "Disponibles", key: "available_classes" },
];
const AnaliticasInscritos = () => {
  const [viewCustomers, setViewCustomers] = useState(null);
  const {
    signups,
    getInscritos,
    available_classes,
  } = useContext(AnaliticasContext);

  const getCustomers = (view) => {
    let customersRender = [];
    let customerLabel = "";
    switch (view) {
      case "signups":
        customersRender = signups;
        customerLabel = "Usuarios Nuevos";
        break;
      case "available_classes":
        customersRender = available_classes;
        customerLabel = "Clientes con Clases Disponibles";
        break;
      case "available_purchasers":
        customersRender = signups?.filter(
          ({ available_classes }) => parseInt(available_classes) > 0
        );
        customerLabel = "Usuarios Nuevos con Clases";
        break;
      default:
        customersRender = signups?.filter(({ value }) => parseFloat(value) > 0);
        customerLabel = "Usuarios Nuevos Convertidos";
    }
    return { customersRender, customerLabel };
  };

  const renderCustomers = (view) => {
    if (viewCustomers !== null) {
      const { customerLabel, customersRender } = getCustomers(view);
      return (
        <div className="card p-3 no-scale">
          <div className="row align-items-center mb-2">
            <div className="col-12 col-md-6">
              <h3 className="mb-0">{customerLabel}</h3>
            </div>
            <div className="col-12 col-md-6 text-right">
              <button
                className="btn btn-link text-secondary"
                onClick={() => setViewCustomers(null)}
              >
                Ocultar
              </button>
            </div>
          </div>
          <CustomersTable
            customers={customersRender}
            extraFields={customerExtraFields}
            showCancelReason={viewCustomers === "cancelados"}
          />
        </div>
      );
    }
  };

  const renderAmount = (view) => {
    const { customersRender } = getCustomers(view);
    if(Array.isArray(customersRender)) return customersRender.length;
    return <div className="spinner-border" />
  };

  return (
    <div className="container-fluid px-3 mb-3">
      <PanelTitleDate title="Inscritos" callback={getInscritos} />
      <div className="row mt-4">
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale mb-4">
            <h3 className="h5 bold">Clientes con Clases</h3>
            <h3 className="mb-0">{renderAmount("available_classes")}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("available_classes")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale">
            <h3 className="h5 bold">Nuevos Registrados</h3>
            <h3 className="mb-0">{renderAmount("signups")}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("signups")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale mb-4">
            <h3 className="h5 bold">Nuevos Convertidos</h3>
            <h3 className="mb-0">{renderAmount("purchasers")}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("purchasers")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale">
            <h3 className="h5 bold">Nuevos con Clases</h3>
            <h3 className="mb-0">{renderAmount("available_purchasers")}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("available_purchasers")}
            >
              Expandir
            </button>
          </div>
        </div>
      </div>
      {renderCustomers(viewCustomers)}
    </div>
  );
};

export default AnaliticasInscritos;
