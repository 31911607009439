import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const VideoCard = ({ video }) => {
  const { S3_ENDPOINT, default_thumbnail } = useContext(AppConfigContext);

  const getSrc = () => {
    let src = default_thumbnail;
    if (video.file && video.file !== null) {
      src = `${video.file.name}.${video.file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  const renderTitle = () => {
    if (String(video.name).length > 40) {
      return String(video.name).substring(0, 40) + "...";
    }
    return video.name;
  };

  const renderDescription = () => {
    if (String(video.description).length > 60) {
      return video.description.substring(0, 60) + "...";
    }
    return video.description;
  };

  const renderInstructors = () => {
    if (Array.isArray(video.video_instructors)) {
      let instructorsRender = video.video_instructors.filter(
        ({ instructor }) => instructor !== null
      );
      return instructorsRender.map(({ instructor }) => (
        <span className="d-block" key={instructor.instructor_id}>
          {instructor.nick_name !== null && instructor.nick_name !== ""
            ? instructor.nick_name
            : instructor.name}
        </span>
      ));
    }
  };

  return (
    <div className="col-12 col-md-6 col-lg-3 position-relative">
      <Link
        to={`./${video.video_id}`}
        className="text-dark no-decoration"
        style={{ zIndex: 0 }}
      >
        <div className="card shadow-sm  px-0 py-0 card-video">
          <img
            className="video-thumbnail"
            src={getSrc()}
            alt="video thumbnail"
          />
          <div className="container-fluid p-3">
            <h3 className="card-video-title mb-0">{renderTitle()}</h3>
            <p className="mb-1">{renderDescription()}</p>
            {renderInstructors()}
          </div>
        </div>
      </Link>
      <div
        className="row mx-0"
        style={{
          position: "absolute",
          bottom: 50,
          zIndex: 1,
          right: "6px",
        }}
      >
        <div className="container-fluid">
          <Link
            className="btn btn-outline-primary me-2"
            to={`./${video.video_id}`}
          >
            <i className="fa fa-edit"></i> Editar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
