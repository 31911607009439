import React, { createContext, useReducer, useContext } from "react";
import ClassTypesReducer from "../reducers/ClassTypesReducer";
import AdjuntosService from "../services/AdjuntosService";
import ClassTypesService from "../services/ClassTypesService";
import {
  CLASS_TYPES_RECIBIDOS,
  CREATE_CLASS_TYPE,
  SET_CLASS_TYPE,
  SET_PROPIEDAD_CLASS_TYPE,
} from "../types";
import { getFormDataFile, hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  class_types: null,
  class_type: null,
};

export const ClassTypeContext = createContext(initialState);

export const ClassTypeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClassTypesReducer, initialState);

  const { success } = useContext(ModalContext);

  const getClassTypes = () => {
    ClassTypesService.getClassTypes().then((res) => {
      const { class_types } = res.data;
      dispatch({ type: CLASS_TYPES_RECIBIDOS, payload: class_types });
    });
  };

  const getClassType = (class_type_id) => {
    ClassTypesService.getClassTypes().then((res) => {
      const { class_types } = res.data;
      let class_type = class_types.find(
        (tipo) => parseInt(tipo.class_type_id) === parseInt(class_type_id)
      );
      dispatch({ type: SET_CLASS_TYPE, payload: class_type });
    });
  };

  const postClassType = (class_type) => {
    const handleSuccess = () => {
      success("Tipo de clase guardado.");
      getClassTypes();
      hideModal();
    };
    const promises = [];
    let data = { ...class_type };
    if (data.picture && data.picture !== null) {
      promises.push(
        new Promise((resolve, reject) => {
          const formData = getFormDataFile(data.picture, "file");
          AdjuntosService.postAdjunto(formData)
            .then((res) => {
              const { file_id } = res.data;
              data.thumbnail = file_id;
              delete data.picture;
              resolve();
            })
            .catch(reject);
        })
      );
    }
    Promise.all(promises).then(() => {
      let service;
      if (isNaN(data.class_type_id)) {
        service = ClassTypesService.postClassType(data);
      } else {
        service = ClassTypesService.putClassType(data);
      }
      service.then(handleSuccess);
    });
  };

  const createClassType = () => {
    dispatch({ type: CREATE_CLASS_TYPE });
  };

  const setPropiedadClassType = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CLASS_TYPE, payload: { key, value } });
  };

  const deleteClassType = (class_type_id) => {
    ClassTypesService.deleteClassType(class_type_id).then(() => {
      success("Tipo de clase eliminado.");
      getClassTypes();
      hideModal();
    });
  };

  return (
    <ClassTypeContext.Provider
      value={{
        ...state,
        getClassType,
        getClassTypes,
        postClassType,
        createClassType,
        deleteClassType,
        setPropiedadClassType,
      }}
    >
      {children}
    </ClassTypeContext.Provider>
  );
};
