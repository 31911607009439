import React from "react";
import {
  getLocalMomentObject,
  getFriendlyLocalDateTimeString,
} from "../../utils/dates";

const DescuentoRow = ({ descuento, handleEdit, handleDelete }) => {
  const renderStartDate = (date) => {
    if (date !== null && getLocalMomentObject(date).isValid()) {
      return getFriendlyLocalDateTimeString(date);
    }
  };

  const renderEndDate = (date) => {
    if (date !== null && getLocalMomentObject(date).isValid()) {
      return getFriendlyLocalDateTimeString(date);
    }
  };

  const { discount_id, title, code, amount, is_percent } = descuento;
  return (
    <tr>
      <td>{code}</td>
      <td>
        {!is_percent && "$"}
        {amount}
        {is_percent && "%"}
      </td>
      <td>{renderStartDate(descuento.start_date)}</td>
      <td>{renderEndDate(descuento.expiration_date)}</td>
      <td>
        <button
          className="btn btn-outline-dark btn-sm"
          onClick={() => handleEdit(discount_id)}
        >
          <i className="fa fa-edit"></i>{" "}
          <span className="hide-mobile">Editar</span>
        </button>
        <button
          className="btn btn-outline-danger btn-sm mx-3"
          onClick={() =>
            handleDelete({
              discount_id,
              title,
              code,
            })
          }
        >
          <i className="fa fa-trash"></i>{" "}
          <span className="hide-mobile">Eliminar</span>
        </button>
      </td>
    </tr>
  );
};

export default DescuentoRow;
