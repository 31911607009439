import { Link } from "@reach/router";
import React from "react";

const AdminTabs = () => {
  return (
    <ul className="side-menu-list">
     
      <li className="nav-item text-item">
        <Link to="/myadmin/ajustes" className="nav-link">
          Ajustes
        </Link>
      </li>
    </ul>
  );
};

export default AdminTabs;
