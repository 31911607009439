import { PAGES_RECEIVED, SET_CONTENT, SET_PAGE } from "../types/pages";

const PagesReducer = (state, { type, payload }) => {
  switch (type) {
    case PAGES_RECEIVED:
      return { ...state, pages: payload };
    case SET_PAGE:
      return { ...state, page: payload };
    case SET_CONTENT:
      return { ...state, content: payload };
    default:
      return { ...state };
  }
};
export default PagesReducer;
