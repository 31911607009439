import { useContext } from "react";
import PurchaseRefundForm from "./PurchaseRefundForm";
import { ModalContext } from "../../context/ModalContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import EditPurchaseForm from "./EditPurchaseForm";

const PurchaseActions = ({ purchase }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setPurchase } = useContext(PurchasesContext);

  const handleCancel = () => {
    clearModal();
    setPurchase(null);
  };

  const handleEdit = () => {
    setPurchase(purchase);
    modalComponent(
      "Editar Compra",
      <EditPurchaseForm handleCancel={handleCancel} />
    );
  };

  const isPayPal = () => {
    if (purchase.payment_method_id === 3) {
      let link = "";
      let text = "";

      if (purchase.subscription_id !== null) {
        link = `https://www.paypal.com/billing/subscriptions/${purchase.subscription_id}`;
        text = "Ver Suscripción";
      } else if (purchase.order_id !== null) {
        link = `https://www.paypal.com/activity/payment/${purchase.order_id}`;
        text = "Ver Pago";
      }

      return (
        <a
          href={link}
          className="btn btn-sm px-2 btn-outline-secondary dropdown-item "
          rel="noreferrer"
          target="_blank"
        >
          <i
            className="fab fa-paypal me-2"
            style={{ width: "20px", height: "20px" }}
          ></i>
          {text}
        </a>
      );
    }
  };

  const isStripe = () => {
    if (purchase.payment_method_id === 1) {
      let link = "";
      let text = "";

      if (purchase.subscription_id !== null) {
        link = `https://dashboard.stripe.com/subscriptions/${purchase.subscription_id}`;
        text = "Ver Suscripción";
      } else if (purchase.order_id !== null) {
        link = `https://dashboard.stripe.com/payments/${purchase.order_id}`;
        text = "Ver Pago";
      }

      return (
        <li>
          <a
            href={link}
            className="btn px-2 btn-sm btn-outline-secondary dropdown-item "
            rel="noreferrer"
            target="_blank"
          >
            <i
              className="fab fa-stripe-s me-2"
              style={{ width: "20px", height: "20px" }}
            ></i>
            {text}
          </a>
        </li>
      );
    }
  };

  const canEdit = () => {
    return purchase.status !== "revoked";
  };

  const renderEditBtn = () => {
    if (canEdit()) {
      return (
        <li>
          <button className="dropdown-item px-2 " onClick={handleEdit}>
            <i
              className="fas fa-edit text-primary me-2"
              style={{ width: "20px", height: "20px" }}
            />
            Editar
          </button>
        </li>
      );
    }
  };

  const handleRefund = () => {
    modalComponent(
      "Reembolsar Compra",
      <PurchaseRefundForm
        purchase={purchase}
        handleCancel={() => {
          clearModal();
        }}
      />
    );
  };

  const renderRefundBtn = () => {
    if (purchase.status === "completed" && purchase.subscription_id === null) {
      return (
        <li>
          <button className="dropdown-item px-2 " onClick={handleRefund}>
            <i
              className="fas fa-undo text-success me-2"
              style={{ width: "20px", height: "20px" }}
            />
            Reembolso
          </button>
        </li>
      );
    }
  };
  return (
    <div className="dropdown">
      <button
        className="btn btn-sm btn-outline-primary border border-1 dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        type="button"
      >
        Opciones
      </button>
      <ul className="dropdown-menu py-0">
        {renderEditBtn()}
        {isStripe()}
        {isPayPal()}
        {renderRefundBtn()}
      </ul>
    </div>
  );
};

export default PurchaseActions;
