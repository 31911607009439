import React, { useContext } from "react";
import { Link } from "@reach/router";
import { AppConfigContext } from "../../context/AppConfigContext";
import { getValue } from "../../utils";

const InstructorTabs = () => {
  const appconfig = useContext(AppConfigContext);
  return (
    <ul className="side-menu-list">
      {getValue(appconfig, "single_class_enabled", "boolean") && (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#clasesDropdown"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="clasesDropdown"
          >
            Clases
          </a>
          <ul id="clasesDropdown" className="collapse collapse-menu">
            <li className="nav-item text-item">
              <Link to="/myadmin/clases" className="nav-link">
                Horarios
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/asistentes" className="nav-link">
                Asistentes
              </Link>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
};

export default InstructorTabs;
