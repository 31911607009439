import React from "react";

const LocationRow = ({ location, handleEdit, handleDelete }) => {
  return (
    <tr>
      <td>{location.name}</td>
      <td>{location.description}</td>
      <td>{location.address}</td>
      <td>{location.city}</td>
      <td>{location.time_zone}</td>
      <td>
        <button
          className="btn btn-sm btn-outline-dark"
          onClick={() => handleEdit(location)}
        >
          <i className="fa fa-edit"></i>{" "}
          <span className="hide-mobile">Editar</span>
        </button>
        <button
          className="btn btn-sm btn-outline-danger mx-3"
          onClick={() => handleDelete(location)}
        >
          <i className="fa fa-trash"></i>{" "}
          <span className="hide-mobile">Eliminar</span>
        </button>
      </td>
    </tr>
  );
};

export default LocationRow;
