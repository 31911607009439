import React from "react";
import Attention from "./Attention";

const MainPitch = ({ attention, title, tagline, highlight }) => {
  return (
    <div className="container-fluid text-center my-4" data-aos="fade-up">
      <Attention highlight={highlight} content={attention} />
      <h1 className="display-5 bold main-pitch">{title}</h1>
      <p className="h3 mb-0">{tagline}</p>
    </div>
  );
};

export default MainPitch;
