import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import SingleClassForm from "./SingleClassForm";
import { SingleClassContext } from "../../context/SingleClassContext";

const SingleClassActions = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { clase, eliminarClase } = useContext(SingleClassContext);

  const handleEdit = () => {
    modalComponent(
      "Editar Clase",
      <SingleClassForm single_class_id={clase?.single_class_id} handleCancel={clearModal} />
    );
  };

  const confirmDeleteClass = (single_class) => {
    modalComponent(
      "Eliminar Clase",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que deseas eliminar la single_class{" "}
          {single_class.description}? Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => eliminarClase(single_class.class_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  return (
    <div className="mb-3">
      <button className="btn btn-sm btn-outline-dark" onClick={handleEdit}>
        <i className="fa fa-edit me-1"></i> Editar
      </button>
      <button
        className="btn btn-sm btn-outline-danger mx-3"
        onClick={confirmDeleteClass}
      >
        <i className="fa fa-trash me-1"></i> Eliminar
      </button>
    </div>
  );
};
export default SingleClassActions;
