import { navigate } from "@reach/router";
import React, { createContext, useReducer, useContext } from "react";
import CustomerReducer from "../reducers/CustomerReducer";
import CustomerService from "../services/CustomerService";
import AdjuntosService from "../services/AdjuntosService";

import {
  SET_CUSTOMER,
  CREATE_CUSTOMER,
  CUSTOMERS_RECIBIDOS,
  SET_PROPIEDAD_CUSTOMER,
  SHOW_SPINNER,
  HIDE_SPINNER,
  LINK_RECIBIDO,
  SET_CUSTOMER_TAGS,
} from "../types";

import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { AppConfigContext } from "./AppConfigContext";

const initialState = {
  customers: null,
  customer: null,
  link: null,
  allCustomerTags: []
};

export const CustomerContext = createContext(initialState);

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  const { success, alert } = useContext(ModalContext);
  const { files_location } = useContext(AppConfigContext);

  const getCustomersByQuery = (query) => {
    CustomerService.getCustomersByQuery(query).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getAllCustomers = (filters) => {
    dispatch({ type: CUSTOMERS_RECIBIDOS, payload: null });
    CustomerService.getAllCustomers(filters).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getCustomerTagOptions = (params) => {
    CustomerService.getCustomerTagOptions(params).then((res) => {
      const { tags } = res.data;      
      dispatch({ type: SET_CUSTOMER_TAGS, payload: tags });
    });
  }

  const getOnlineActiveCustomers = () => {
    CustomerService.getOnlineActiveCustomers().then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getStudioActiveCustomers = () => {
    CustomerService.getStudioActiveCustomers().then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getCustomer = (customer_id) => {
    CustomerService.getCustomer(customer_id).then((res) => {
      const { customer } = res.data;
      dispatch({ type: SET_CUSTOMER, payload: customer });
    });
  };

  const extenderAcceso = (
    customer_id,
    class_package_id,
    expiration_days,
    is_gift,
    total,
    payment_method_id
  ) => {
    CustomerService.extenderAcceso(
      customer_id,
      class_package_id,
      expiration_days,
      is_gift,
      total,
      payment_method_id
    ).then(() => {
      success("¡Acceso agregado!");
      getCustomer(customer_id);
      hideModal();
    });
  };

  const revokeAccess = (purchase_id, customer_id) => {
    CustomerService.revokeAccess(purchase_id).then((res) => {
      getCustomer(customer_id);
      success("¡Acceso eliminado!");
      hideModal();
    });
  };

  const saveCustomerOnlineAccess = (data) => {
    let service = CustomerService.putOnlineAccess;
    if (isNaN(data.online_access_id)) {
      service = CustomerService.postOnlineAccess;
    }

    service(data)
      .then((res) => {
        success("Acceso Online Modificado");
        hideModal();
        getCustomer(data.customer_id);
      }).catch(err => {
        alert(err);
      });
  }

  const saveCustomerTags = (data) => {
    console.log(data);
    
    CustomerService.postCustomerTags(data)
      .then(res => {
        console.log('res');
      })
      .catch(err => {
        alert(err);
      })
      .finally(() => {
        const customer_id = data.customer_id;
        getCustomer(customer_id);
        getCustomerTagOptions({customer_id});
      })
  }
  
  const clearCustomer = () => {
    dispatch({ type: SET_CUSTOMER, payload: null });
  };

  const setPropiedadCustomer = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CUSTOMER, payload: { key, value } });
  };

  const createCustomer = () => {
    dispatch({ type: CREATE_CUSTOMER });
  };

  const postCustomer = (customer, callback) => {
    dispatch({ type: SHOW_SPINNER });

    const handleSuccess = ({ data }) => {
      if(typeof callback === 'function') callback(data?.customer);
      success("Cliente guardado con éxito.");
      dispatch({ type: HIDE_SPINNER });
      if (data.customer) {
        navigate(`/myadmin/customer/${data.customer.customer_id}`);
      } else if (!isNaN(customer.customer_id)) {
        navigate(`/myadmin/customer/${customer.customer_id}`);
      }
    };

    const handleError = (error) => {
      dispatch({ type: HIDE_SPINNER });
      if (error.response) {
        if (error.response.status === 409) {
          return alert("Ya existe un cliente con este correo.");
        }
      }
      alert(error);
    };

    const handlePost = () => {
      if (isNaN(customer.customer_id)) {
        CustomerService.postCustomer(customer)
          .then(handleSuccess)
          .catch(handleError);
      } else {
        CustomerService.putCustomer(customer)
          .then(handleSuccess)
          .catch(handleError);
      }
    };

    if (customer.newFile) {
      postCustomerFile(customer.newFile)
        .then((res) => {
          customer.file_id = res.data.file_id;
          delete customer.newFile;

          handlePost();
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      handlePost();
    }
  };

  const postCustomerFile = (file) => {
    const formData = AdjuntosService.getFormData(file);

    if(files_location === 'aws') {
      return AdjuntosService.postAwsFile(formData);
    } else {
      return AdjuntosService.postAdjunto(formData);
    }
  }

  const deleteCustomer = (customer_id) => {
    CustomerService.deleteCustomer(customer_id).then((res) => {
      navigate("/myadmin/customers");
      success("Cliente eliminado con éxito.");
      hideModal();
    });
  };

  const removeCustomerClasses = (customer_id, amount) => {
    CustomerService.removeClasses(customer_id, amount).then(() => {
      success("Clases eliminadas con éxito.");
      getCustomer(customer_id);
      hideModal();
    });
  };

  const addCustomerClasses = (data) => {
    CustomerService.giveClasses(data).then(
      () => {
        success("Clases agregadas con éxito.");
        hideModal();
        getCustomer(data.customer_id);
      }
    );
  };

  const getPasswordResetLink = (email) => {
    CustomerService.getPasswordResetLink(email).then((res) => {
      const { link } = res.data;
      dispatch({ type: LINK_RECIBIDO, payload: link });
    });
  };

  const clearLink = () => {
    dispatch({ type: LINK_RECIBIDO, payload: null });
  };

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        clearLink,
        getCustomer,
        getAllCustomers,
        getCustomersByQuery,
        getCustomerTagOptions,
        extenderAcceso,
        clearCustomer,
        revokeAccess,
        createCustomer,
        postCustomer,
        deleteCustomer,
        addCustomerClasses,
        setPropiedadCustomer,
        saveCustomerTags,
        removeCustomerClasses,
        getPasswordResetLink,
        getOnlineActiveCustomers,
        getStudioActiveCustomers,
        saveCustomerOnlineAccess
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
