import {
  SET_POST,
  APPEND_POST,
  CREATE_POST,
  POSTS_RECEIVED,
  SET_PROPERTY_POST,
  REMOVE_POST,
  SET_MAX,
  LIKE_POST,
  UNLIKE_POST,
} from "../types/posts";
import { getLocalMomentObject, sortByDateField } from "../utils/dates";

const schema = {};

const postsReducer = (state, { type, payload }) => {
  switch (type) {
    case LIKE_POST: {
      const { customer_id, post_id } = payload;
      let posts = state.posts;
      if (Array.isArray(posts)) posts = [...posts];
      else posts = [];
      let index = posts.findIndex(
        (post) => parseInt(post.post_id) === parseInt(post_id)
      );
      if (index !== -1) {
        const currentPost = posts[index];
        if (currentPost) currentPost.post_likes.push({ customer_id });
      }
      return { ...state, posts };
    }
    case UNLIKE_POST: {
      const { customer_id, post_id } = payload;
      let posts = state.posts;
      if (Array.isArray(posts)) posts = [...posts];
      else posts = [];
      let index = posts.findIndex(
        (post) => parseInt(post.post_id) === parseInt(post_id)
      );
      if (index !== -1) {
        const currentPost = posts[index];
        if (currentPost) {
          const likeIndex = currentPost.post_likes.find(
            (like) => parseInt(like.customer_id) === parseInt(customer_id)
          );
          if (likeIndex !== -1) {
            currentPost.post_likes.splice(likeIndex, 1);
          }
        }
      }
      return { ...state, posts };
    }
    case SET_MAX: {
      return { ...state, max: payload };
    }
    case REMOVE_POST: {
      let posts = state.posts;
      if (Array.isArray(posts)) posts = [...posts];
      else posts = [];
      let index = posts.findIndex(
        (post) => parseInt(post.post_id) === parseInt(payload)
      );
      if (index !== -1) {
        posts.splice(index, 1);
      }
      return { ...state, posts };
    }
    case APPEND_POST: {
      let posts = state.posts;
      if (Array.isArray(posts)) posts = [...posts];
      else posts = [];
      const index = posts.findIndex(
        (post) => parseInt(post.post_id) === parseInt(payload.post_id)
      );
      const postExists = index !== -1;
      if (postExists) {
        return {
          ...state,
          posts,
        };
      }
      posts.push(payload);
      posts = sortByDateField(posts, "createdAt");
      return {
        ...state,
        posts,
      };
    }
    case POSTS_RECEIVED: {
      let posts = payload;
      posts = posts.sort((a, b) =>
        getLocalMomentObject(a.createdAt).isAfter(getLocalMomentObject(b.createdAt)) ? 1 : -1
      );
      return { ...state, posts };
    }
    case SET_POST:
      return { ...state, post: payload };
    case CREATE_POST:
      return { ...state, post: schema };
    case SET_PROPERTY_POST: {
      const { key, value } = payload;
      const post = { ...state.post };
      post[key] = value;
      return { ...state, post };
    }
    default:
      return { ...state };
  }
};

export default postsReducer;
