
import api from './api';

const route = '/notes';

const NotesService = {
  getNotesByCustomer: (customer_id) => api.get(`${route}/${customer_id}`),
  getSingleNote: (note_id) => api.get(`${route}/${note_id}`),
  postNote: (note) => api.post(route, { ...note}),
  putNote: (note) => api.put(route, { ...note}),
  deleteNote: (note_id) => api.delete(`${route}/${note_id}`),
};


export default NotesService;
