import React, { useContext, useEffect, useState } from "react";
import { getFriendlyLocalDateTimeString } from "../../utils/dates";
import { InvoicesContext } from "../../context/InvoicesContext";
import { ModalContext } from "../../context/ModalContext";

const InvoiceForm = ({
  handleCancel,
  handleCallback,
  purchases,
  paymentMethods,
}) => {
  const [currentFormData, setCurrentFormData] = useState({
    amount: 0.0,
    purchaseId: "",
    paymentMethodId: "",
    // status: ''
  });

  const { postInvoice } = useContext(InvoicesContext);
  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    setDefaultPurchase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchases]);

  const setCurrentPurchase = (currentPurchase) => {
    const amount = currentPurchase.amount;
    const purchaseId = currentPurchase.purchase_id;
    const paymentMethodId = currentPurchase.payment_method.payment_method_id;
    const status = currentPurchase.status;

    setCurrentFormData({
      purchaseId,
      
      paymentMethodId,
      status,
      amount,
    });
  };

  const setDefaultPurchase = () => {
    const defaultPurchase = purchases[0];
    setCurrentPurchase(defaultPurchase);
  };

  const getInvoiceData = () => {
    const currentPurchase = purchases.find((obj) => {
      return obj.purchase_id === currentFormData.purchaseId;
    });

    const newInvoice = {
      status: currentPurchase.status,
      amount: Number(currentPurchase.amount),
      payment_method_id: currentFormData.paymentMethodId,
      discount_id: currentPurchase.discount_id,
      purchase_id: currentPurchase.purchase_id,
      customer_id: currentPurchase.customer_id,
      class_package_id: currentPurchase.class_package_id,
    };

    return newInvoice;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const invoiceData = getInvoiceData();
    postInvoice(invoiceData, () => {
      handleCallback();
      clearModal();
    });
  };

  const handleChangePurchase = (event) => {
    const purchaseId = Number(event.target.value);

    const selectedPurchase = purchases.find((obj) => {
      return obj.purchase_id === purchaseId;
    });

    setCurrentPurchase(selectedPurchase);
  };

  const handleChangePrice = (event) => {
    const amount = event.target.value;

    setCurrentFormData({
      ...currentFormData,
      amount,
    });
  };

  const handleChangePaymentMethod = (event) => {
    const methodId = event.target.value;

    setCurrentFormData({
      ...currentFormData,
      paymentMethodId: methodId,
    });
  };

  const renderPurchases = () => {
    return purchases.map((purchase, index) => {
      const purchaseId = purchase.purchase_id;
      const purchasePackage = purchase.class_package.title;
      const date = getFriendlyLocalDateTimeString(purchase.createdAt);

      const purchaseData = `#${purchaseId} - ${purchasePackage} ${date}`;

      return (
        <option key={purchaseId} value={purchaseId}>
          {purchaseData}
        </option>
      );
    });
  };

  const renderPaymentMethods = () => {
    return paymentMethods?.map((method) => {
      const methodId = method.payment_method_id;
      return (
        <option key={methodId} value={methodId}>
          {method.name}
        </option>
      );
    });
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Compra</label>
        <select className="form-control mb-3" onChange={handleChangePurchase}>
          {renderPurchases()}
        </select>

        <label>Total</label>
        <input
          type="number"
          className="form-control mb-3"
          value={currentFormData.amount}
          onChange={handleChangePrice}
        />
        <label>Metodo de Pago</label>
        <select
          className="form-control mb-3"
          onChange={handleChangePaymentMethod}
          value={currentFormData.paymentMethodId}
        >
          {renderPaymentMethods()}
        </select>

        {/* <label>Estado</label>
         <select
          className="form-control mb-3"
          onChange={handleChangePurchase}
        >
          <option>En proceso</option>
          <option>Completado</option>
          <option>Cancelado</option>
        </select> */}

        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={handleCancel}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <input
              type="submit"
              className="btn btn-primary w-100"
              value="Registrar Cargo"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvoiceForm;
