import React, { useContext } from "react";
import PanelTitle from "../global/PanelTitle";
import { ModalContext } from "../../context/ModalContext";
import OnlineAccessForm from "./OnlineAccessForm";
import { CustomerContext } from "../../context/CustomerContext";
import { getFriendlyLocalDateTimeString, getLocalMomentObject } from "../../utils/dates";

const OnlineAccessTable = ({ accesses }) => {

  const { modalComponent } = useContext(ModalContext);
  const { customer } = useContext(CustomerContext);

  const editAcceso = (access) => {
    modalComponent(
      "Editar Accesso",
      <OnlineAccessForm online_access={access}/>
    )
  }

  const handleCreateAccess = () => {
    const currentDate = getLocalMomentObject().startOf('day');
    const expirationDate = currentDate.clone().add(30, 'days').endOf('day');

    modalComponent(
      "Editar Accesso",
      <OnlineAccessForm 
        online_access={{
          status: 'active',
          start_date: currentDate.format(),
          expiration_date: expirationDate.format(),
          customer_id: customer?.customer_id
        }}
      />
    )
  }

  const renderAccesos = () => {
    if(Array.isArray(accesses)) {
      return accesses.map(access => {
        const startDate = getFriendlyLocalDateTimeString(access.start_date);
        const expirationDate = getFriendlyLocalDateTimeString(access.expiration_date);
        
        const accessStatuses = {
          revoked: 'Revocado',
          paused: 'Pausado',
          active: 'Activo',
        }

        const purchase = access.purchase_id ? access.purchase_id : 'N/A';
        const invoice = access.invoice_id ? access.invoice_id : 'N/A';

        return(
          <tr key={access.online_access_id} className="">
            <td>{access.online_access_id}</td>
            <td>{purchase}</td>
            <td>{invoice}</td>
            <td>{accessStatuses[access.status]}</td>
            <td>{startDate}</td>
            <td>{expirationDate}</td>
            <td>
              <button
                onClick={() => editAcceso(access)}
                className="btn btn-sm btn-outline-dark"
              >
                <i className="fa fa-edit" />
              </button>
            </td>
          </tr>
        )
      });
    }
  };

  return (
    <div className="card no-scale my-3">
      <PanelTitle title="Accesos" onClick={handleCreateAccess}/>
      
      <div className="table-responsive">
        <table className="table border">
          <thead className="bg-light border bold small">
            <tr>
              <td>#Acceso</td>
              <td>#Compra</td>
              <td>#Cargo</td>
              <td>Estatus</td>
              <td>Fecha de Inicio</td>
              <td>Fecha de Expiración</td>
              <td>Acciones</td>
            </tr>
          </thead>
          <tbody>{renderAccesos()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default OnlineAccessTable;
