
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  SET_REFUND,
  CREATE_REFUND,
  REFUNDS_RECEIVED,
  SET_PROPERTY_REFUND,
} from "../types/refunds";

const schema = {
  reason: '',
  amount_refunded: 0,
  purchase_id: null,
  customer_id: null
}

const refundsReducer = (state, { type, payload }) => {
  switch (type) {
    case REFUNDS_RECEIVED:
      return { ...state, refunds: payload };
    case SET_REFUND:
      return { ...state, refund: payload };
    case CREATE_REFUND:
      return { ...state, refund: schema };
    case SHOW_SPINNER:
      return { ...state, loading: true };
    case HIDE_SPINNER:
      return { ...state, loading: false };
    case SET_PROPERTY_REFUND: {
      const { key, value } = payload;
      const refund = { ...state.refund };
      refund[key] = value;
      return { ...state, refund };
    }
    default:
      return { ...state};
  }
};

export default refundsReducer;
