import React from "react";

const PictureTestimonial = ({ name, content, image_source }) => {
  const renderContent = () => {
    if (image_source && image_source !== null && image_source !== "") {
      return (
        <div className="row px-3 align-items-center">
          <div className="col-12 col-md-4 my-3">
            <img
              src={image_source}
              alt="Testimonio"
              className="picture-testimonial-img"
            />
          </div>
          <div className="col-12 col-md-8 my-3">
            <p className="h5 mb-0">"{content}"</p>
            <p className="bold h4 mb-0">- {name}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid p-3 my-4" data-aos="fade-up">
      <div className="card border-0 shadow p-3">{renderContent()}</div>
    </div>
  );
};

export default PictureTestimonial;
