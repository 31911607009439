import React from "react";

const CoachCustomerOption = ({ customer, modifier }) => {
  return (
    <div key={customer.customer_id} className="row mb-2 align-items-center">
      <div className="col col-md-4">
        <span className="small">
          {customer.name} {customer.last_name}
        </span>
      </div>
      <div className="col col-md-4 small">
        <span className="small">{customer.email}</span>
      </div>
      <div className="col col-md-4 text-end">
        <button
          className="btn btn-sm btn-outline-dark"
          onClick={() => modifier(customer)}
        >
          + Conectar
        </button>
      </div>
    </div>
  );
};

export default CoachCustomerOption;
