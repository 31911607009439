import React from "react";
import { getValue } from "../../utils";
import { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { Link } from "@reach/router";
import { UserContext } from "../../context/UserContext";

const AnalyticsTabs = () => {
  const appconfig = useContext(AppConfigContext);
  const { user } = useContext(UserContext);
  
  return (
    <ul className="side-menu-list">
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#analiticasDropdown"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-controls="analiticasDropdown"
        >
          Analíticas
        </a>
        <ul className="collapse collapse-menu" id="analiticasDropdown">
          <li className="nav-item text-item">
            <Link to="/myadmin/analytics/inscritos" className="nav-link">
              Inscritos
            </Link>
          </li>
          {["super_admin", "admin"].includes(getValue(user, "role")) && (
            <>
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/ingresos" className="nav-link">
                  Ingresos
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/paquetes" className="nav-link">
                  Paquetes
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/descuentos" className="nav-link">
                  Descuentos
                </Link>
              </li>
            </>
          )}
          {getValue(appconfig, "leaderboard_enabled", "boolean") && (
            <li className="nav-item text-item">
              <Link to="/myadmin/analytics/leaderboard" className="nav-link">
                Leaderboard
              </Link>
            </li>
          )}
          {getValue(appconfig, "single_class_enabled", "boolean") && (
            <>
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/instructores" className="nav-link">
                  Instructores
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/reservaciones" className="nav-link">
                  Reservaciones
                </Link>
              </li>
            </>
          )}
          {getValue(appconfig, "videos_enabled", "boolean") && (
            <>
              {" "}
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/videos" className="nav-link">
                  Videos
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/analytics/free" className="nav-link">
                  Free Trials
                </Link>
              </li>
            </>
          )}
        </ul>
      </li>
    </ul>
  );
};

export default AnalyticsTabs;
