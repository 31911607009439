import React, { useContext, useState } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import AnaliticasService from "../../services/AnaliticasService";
import { getLocalMomentObject } from "../../utils/dates";

const AnaliticasInstructores = () => {
  const [inicio, setInicio] = useState(
    getLocalMomentObject().startOf("month").format("YYYY-MM-DD")
  );
  const [fin, setFin] = useState(getLocalMomentObject().endOf("month").format("YYYY-MM-DD"));
  const { instructores, getInstructores } = useContext(AnaliticasContext);

  const handleReport = () => {
    AnaliticasService.getReporte(inicio, fin).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Reporte_Instructores_${inicio}_${fin}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDateChange = (startDate, endDate) => {
    getInstructores(startDate, endDate);
    setInicio(startDate);
    setFin(endDate);
  };

  const renderInstructores = () => {
    if (Array.isArray(instructores)) {
      return instructores
        .filter((instructor) => instructor.name !== null)
        .map((instructores) => (
          <div className="row my-2" key={instructores.name}>
            <div className="col">{instructores.name}</div>
            <div className="col">{instructores.single_classes}</div>
            <div className="col">{instructores.booked}</div>
            <div className="col">{instructores.attended}</div>
          </div>
        ));
    }
  };

  return (
    <div className="container-fluid mt-3">
      <PanelTitleDate title="Instructores" callback={handleDateChange} />
      <div className="row">
        <div className="container-fluid">
          <div className="card p-3 me-3  shadow-sm">
            <div className="row align-items-center pb-3">
              <div className="col-12 col-md-6">
                <h4>Alumnos por Instructor</h4>
              </div>
              <div className="col-12 col-md-6">
                <button onClick={handleReport} className="btn btn-outline-dark">
                  <i className="far fa-file-excel"></i>
                </button>
              </div>
            </div>
            <div className="row bold bg-light border py-2 mb-2">
              <div className="col">Nombre</div>
              <div className="col">Clases</div>
              <div className="col">Reservados</div>
              <div className="col">Asistentes</div>
            </div>
            {renderInstructores()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasInstructores;
