import { 
  CREATE_PAYMENT_METHOD, 
  PAYMENT_METHODS_RECIBIDOS, 
  SET_PAYMENT_METHOD, 
  SET_PROPIEDAD_PAYMENT_METHOD 
} from "../types";

const schema = {
  name: "",
};

const PaymentMethodsReducer = (state, { type, payload }) => {

  switch (type) {
    case PAYMENT_METHODS_RECIBIDOS:
      return { ...state, payment_methods: payload };

    case SET_PAYMENT_METHOD:
      return { ...state, payment_method: payload };

    case SET_PROPIEDAD_PAYMENT_METHOD:
      const payment_method = { ...state.payment_method };
      const { key, value } = payload;
      payment_method[key] = value;

      return { ...state, payment_method };
    case CREATE_PAYMENT_METHOD:
      return { ...state, payment_method: schema };
    default:
      return { ...state };
  }
};
export default PaymentMethodsReducer;
