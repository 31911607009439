import {
  CLEAR_INVOICES,
  HIDE_SPINNER,
  INVOICES_RECIBIDOS,
  SHOW_SPINNER,
} from "../types";

const InvoicesReducer = (state, { type, payload }) => {
  switch (type) {
    case INVOICES_RECIBIDOS:
      return { ...state, invoices: payload };
    case CLEAR_INVOICES:
      return { ...state, invoices: null };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default InvoicesReducer;
