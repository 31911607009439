import React, { useContext, useEffect, useState } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { getLocalMomentObject } from "../../utils/dates";

const RatingsHeader = ({ title, fetchRatings }) => {
  const [coach, setCoach] = useState("all");
  const [comment, setComment] = useState("all");
  const [classType, setClassType] = useState("all");
  const [startDate, setStartDate] = useState(
    getLocalMomentObject().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    getLocalMomentObject().endOf("month").format("YYYY-MM-DD")
  );
  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const { coaches, getCoaches } = useContext(CoachesContext);

  useEffect(() => {
    getClassTypes();
    getCoaches();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(startDate);
    console.log(endDate);
    
    fetchRatings({
      start_date: startDate,
      end_date: endDate,
      class_type_id: classType === "all" ? null : classType,
      instructor_id: coach === "all" ? null : coach,
      comment: comment === "all" ? null : comment,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, comment, coach, classType]);

  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      return class_types.map((classType) => (
        <option value={classType.class_type_id}>{classType.name}</option>
      ));
    }
  };

  const renderCoaches = () => {
    if (Array.isArray(coaches)) {
      let coachesRender = [...coaches];
      let components = [];
      components.push(
        <option key="all" value="all">
          Todas las Coaches
        </option>
      );
      coachesRender.forEach((coach) => {
        components.push(
          <option key={coach.instructor_id} value={coach.instructor_id}>
            {coach.name}
          </option>
        );
      });
      return components;
    }
  };
  return (
    <div className="row mx-0 align-items-center mb-3 pb-2 border-bottom">
      <div className="col-12 col-md-4 px-0 mb-2">
        <h1 className="h2 bold mb-0">
          <i className="fa fa-star" /> {title}
        </h1>
      </div>
      <div className="col-12 col-md-8 px-0 text-end mb-2">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-4">
                <select
                  value={classType}
                  className="form-control"
                  onChange={(e) => setClassType(e.target.value)}
                >
                  <option key="all" value="all">
                    Todas las Clases
                  </option>
                  {renderClassTypes()}
                </select>
              </div>
              <div className="col-4">
                <select
                  value={comment}
                  className="form-control"
                  onChange={(e) => setComment(e.target.value)}
                >
                  <option key="all" value="all">
                    Todos
                  </option>
                  <option value="no-comment">Sin Comentario</option>
                  <option value="comment">Con Comentario</option>
                </select>
              </div>
              <div className="col-4">
                <select
                  value={coach}
                  className="form-control"
                  onChange={(e) => setCoach(e.target.value)}
                >
                  {renderCoaches()}{" "}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6">
                <input
                  type="date"
                  value={startDate}
                  className="form-control"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-6">
                <input
                  type="date"
                  value={endDate}
                  className="form-control"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingsHeader;
