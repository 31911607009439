import React from "react";
import { formatMonto } from "../../utils";
import CallToAction from "./CallToAction";

const Stacking = ({
  title,
  items,
  value,
  tagline,
  color,
  text_color,
  cta_title,
  cta_tagline,
  call_to_action,
}) => {
  const renderItems = () => {
    if (items && items !== null) {
      return items.map((item, index) => (
        <div key={index} className="row px-5 my-2">
          <div className="col-10 text-start">
            <i className={`fa_prepended fas fa-check pe-3 text-${color}`}></i>
            {String(item).split("=")[0]}
          </div>
          <div className="col-2">
            <span className={`bold text-${color}`}>
              ({"$"}
              {formatMonto(String(item).split("=")[1])})
            </span>
          </div>
        </div>
      ));
    }
  };

  const getTotalValue = () => {
    if (items && items !== null) {
      const values = items.map((item) => item.split("=")[1]);
      let total = 0.0;
      values.forEach((value) => {
        if (!isNaN(value)) {
          total += parseFloat(value);
        }
      });
      return formatMonto(total.toFixed(2));
    }
    return 0;
  };

  return (
    <div className={`container-fluid p-5 text-center bonus`} data-aos="fade-up">
      <div className={`bg-${color} p-3 text-white`}>
        <h4 className="h2 mb-0">
          <b>{title}</b>
        </h4>
      </div>
      <div className={`border border-2 border-solid border-${color} p-4`}>
        <div>{renderItems()}</div>
        <h3 className={`h1 mt-5 bold text-${color}`}>
          Valor Total de ${getTotalValue()}
        </h3>
        <h3 className="px-3">SÓLO HOY puedes llevarte todo esto por</h3>
        <div className={`display-3 bold text-${color}`}>
          {"$"}
          {formatMonto(value)}
        </div>
        <p>{tagline}</p>
        {call_to_action && (
          <CallToAction
            tagline={cta_tagline}
            title={cta_title}
            call_to_action={call_to_action}
            color={color}
            text_color={text_color}
          />
        )}
      </div>
    </div>
  );
};

export default Stacking;
