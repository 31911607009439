import React from "react";
import LocationRow from "./LocationRow";

const LocationsTable = ({ locations, handleEdit, handleDelete }) => {
  const renderLocations = () => {
    if (Array.isArray(locations)) {
      return locations.map((location) => (
        <LocationRow
          key={location.location_id}
          location={location}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bold bg-light">
            <td>Nombre</td>
            <td>Descripción</td>
            <td>Dirección</td>
            <td>Ciudad</td>
            <td>Zona Horaria</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderLocations()}</tbody>
      </table>
    </div>
  );
};
export default LocationsTable;
