import { getArgs } from "../utils";
import api from "./api";

const route = "/invoices";

const InvoicesService = {
  getInvoicesAdmin: (startDate, endDate, args) =>
    api.get(
      `${route}/admin?start_date=${startDate}&end_date=${endDate}&${getArgs(
        args
      )}`
    ),

  postInvoiceAdmin: (data) =>
    api.post(
      `${route}/admin`, data
    ),
};

export default InvoicesService;
