import React, { useContext } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import DiscountsTable from "../../components/analytics/DiscountsTable";
import PanelTitleDate from "../../components/global/PanelTitleDate";

const AnaliticasDescuentos = () => {
  const { discounts, getDescuentos } = useContext(AnaliticasContext);
  
  const fetchDescuentos = (start_date, end_date) => {
    getDescuentos({ start_date, end_date }); 
  }

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Analíticas Descuentos" callback={fetchDescuentos} />
      <div className="card shadow">
        <DiscountsTable discounts={discounts} />
      </div>
    </div>
  );
};

export default AnaliticasDescuentos;
