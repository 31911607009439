import {
  SET_FILE
} from "../types/adjuntos";

const AdjuntosReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_FILE:
      return { ...state, file: payload };
    default:
      return { ...state };
  }
};

export default AdjuntosReducer;
