import React from "react";

const SectionTitle = ({ number, title }) => {
  return (
    <div className="container-fluid  text-center my-3">
      <h2>
        {number && number !== "" && (
          <span className="bold">Section #{number}:</span>
        )}{" "}
        {title}
      </h2>
    </div>
  );
};

export default SectionTitle;
