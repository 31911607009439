import axios from "axios";

const BASE_URL =
  (process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : window.location.origin.replace("admin.", "")) + "/api";

const api = axios.create({
  baseURL: BASE_URL,
});

export default api;
