import {
  CREATE_VIDEO_TYPE,
  SET_PROPERTY_VIDEO_TYPE,
  SET_VIDEO_TYPE,
  VIDEO_TYPE_RECIBIDOS,
} from "../types/video_types";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const schema = {
  video_type_id: "nuevo",
  name: "",
  description: "",
};

const VideoTypeReducer = (state, { type, payload }) => {
  switch (type) {
    case VIDEO_TYPE_RECIBIDOS: {
      return { ...state, video_types: payload };
    }
    case SET_PROPERTY_VIDEO_TYPE: {
      const { key, value } = payload;
      const video_type = { ...state.video_type };
      video_type[key] = value;
      return { ...state, video_type };
    }
    case CREATE_VIDEO_TYPE:
      return { ...state, video_type: schema };
    case SET_VIDEO_TYPE:
      return { ...state, video_type: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};

export default VideoTypeReducer;
