import React from "react";
import WeekScheduleAdminDay from "./WeekScheduleAdminDay";
import {
  getFriendlyLocalTimeString,
  getLocalMomentObject,
} from "../../utils/dates";

const WeekScheduleAdminWeek = ({
  week,
  isHome,
  location,
  filtered,
  handleCallback,
}) => {
  const getDetails = (day, time) => {
    const classes = day.details.filter((single_class) => {
      const hour = getLocalMomentObject(single_class.class_date).format(
        "HH : mm"
      );
      return hour === time;
    });
    const sorted = classes.sort((a, b) =>
      getFriendlyLocalTimeString(a.class_date) >
      getFriendlyLocalTimeString(b.class_date)
        ? 1
        : -1
    );
    return sorted;
  };

  const renderTime = (index, offset) => {
    const initial = getLocalMomentObject().startOf("day");
    const minutes = index * 5;
    initial.add(minutes, "minutes");
    if (offset) initial.add(offset, "hours");
    return initial.format("HH : mm");
  };

  const renderRows = () => {
    return new Array(288)
      .fill(1)
      .map((one, index) => {
        const time = renderTime(index);
        const single_classes = week.find((day) => {
          return day.details.find(
            (single_class) =>
              getLocalMomentObject(single_class.class_date).format("HH : mm") === time
          );
        });
        if (single_classes) {
          return (
            <div className="row" key={index}>
              <div className="schedule-col time-container text-center bold border-bottom bg-dark text-white py-4">
                <div className="row align-items-center h-100">
                  <span>{renderTime(index)}</span>
                </div>
              </div>
              {week.map((currentDay) => (
                <WeekScheduleAdminDay
                  key={currentDay.date}
                  day={currentDay}
                  isHome={isHome}
                  location={location}
                  filtered={filtered}
                  handleCallback={handleCallback}
                  clases={getDetails(currentDay, time)}
                />
              ))}
            </div>
          );
        }
        return null;
      })
      .filter((object) => object !== null);
  };

  const renderDay = (currentDay) => {
    const day_string = getLocalMomentObject(currentDay.date).format("dd");
    switch (day_string) {
      case "Tu":
        return "MARTES";
      case "We":
        return "MIÉRCOLES";
      case "Th":
        return "JUEVES";
      case "Fr":
        return "VIERNES";
      case "Sa":
        return "SÁBADO";
      case "Su":
        return "DOMINGO";
      default:
        return "LUNES";
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="schedule-col text-center bold border-bottom bg-dark text-white py-4">
          <div className="row align-items-center h-100">
            <span>Horario</span>
          </div>
        </div>
        {week.map((currentDay) => (
          <div key={currentDay.date} className="schedule-col border">
            <div className="row mx-0 pt-2">
              <div className="container-fluid text-center">
                {getLocalMomentObject(currentDay.date).format("DD")}
              </div>
            </div>
            <div className="row mx-0 my-3 bg-dark text-white">
              <div className="container-fluid text-center small">
                {renderDay(currentDay)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {renderRows()}
    </div>
  );
};

export default WeekScheduleAdminWeek;
