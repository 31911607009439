import React from "react";
import PaqueteRow from "./PaqueteRow";
import { searchRows } from "../../utils";

const PaquetesTable = ({ query, class_packages, confirmDelete }) => {
  const renderPaquetes = () => {
    let paquetesRender = [...class_packages];
    if (query && query !== "") {
      paquetesRender = searchRows(query, class_packages);
    }
    if(paquetesRender.length === 0) {
      return <p className="px-2 mt-2">No hay paquetes disponibles.</p>
    }
    return paquetesRender.map((paquete) => (
      <PaqueteRow
        paquete={paquete}
        confirmDelete={confirmDelete}
        key={paquete.class_package_id}
      />
    ));
  };

  return <div>{renderPaquetes()}</div>;
};

export default PaquetesTable;
