import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import { DiscountsContext } from "../../context/DiscountsContext";
import DescuentoForm from "../../components/descuentos/DescuentoForm";
import DescuentosTable from "../../components/descuentos/DescuentosTable";

const AdminDescuentos = () => {
  const { descuentos, getDescuentosAdmin, deleteDescuento } =
    useContext(DiscountsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getDescuentosAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createDescuento = () => {
    modalComponent("Agregar Descuento", <DescuentoForm discount_id="nuevo" />);
  };

  const editarDescuento = (discount_id) => {
    modalComponent(
      "Editar Descuento",
      <DescuentoForm discount_id={discount_id} />
    );
  };

  const confirmDelete = (descuento) => {
    modalComponent(
      "Eliminar Descuento",
      <div>
        <p>
          ¿Estás segura que quieres eliminar el descuento {descuento.title} -{" "}
          {descuento.code}? Esta acción NO puede deshacerse.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn text-muted w-100">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn w-100 btn-danger"
              onClick={() => deleteDescuento(descuento.discount_id)}
            >
              <i className="fa fa-trash"></i> Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderDescuentos = () => {
    if (Array.isArray(descuentos)) {
      if (descuentos.length === 0) return <p>No hay descuentos disponibles.</p>;
      return (
        <DescuentosTable
          descuentos={descuentos}
          handleEdit={editarDescuento}
          handleDelete={confirmDelete}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3 align-items-center">
        <div className="col-6">
          <h1>Descuentos</h1>
        </div>
        <div className="col-6 text-end">
          <button className="btn btn-primary" onClick={createDescuento}>
            + Agregar
          </button>
        </div>
      </div>
      <div className="card p-3 shadow">{renderDescuentos()}</div>
    </div>
  );
};

export default AdminDescuentos;
