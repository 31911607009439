import React, { createContext, useContext, useReducer } from "react";
import PaymentMethodsReducer from "../reducers/PaymentMethodsReducer";
import PaymentMethodService from "../services/PaymentMethodService";
import { 
  CREATE_PAYMENT_METHOD,
  PAYMENT_METHODS_RECIBIDOS, 
  SET_PAYMENT_METHOD, 
  SET_PROPIEDAD_PAYMENT_METHOD
} from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  payment_methods: null,
  payment_method: null
};

export const PaymentMethodsContext = createContext(initialState);

export const PaymentMethodsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PaymentMethodsReducer, initialState);
  const { clearModal } = useContext(ModalContext);

  
  const getPaymentMethods = () => {
    PaymentMethodService.getPaymentMethods().then((res) => {
      const { payment_methods } = res.data;
      dispatch({ type: PAYMENT_METHODS_RECIBIDOS, payload: payment_methods });
    });
  };

  const setPaymentMethod = (payload) => {
    dispatch({ type: SET_PAYMENT_METHOD, payload });
  };

  const createPaymentMethod = () => {
    dispatch({ type: CREATE_PAYMENT_METHOD });
  };

  const setPropiedadPaymentMethod = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_PAYMENT_METHOD, payload: { key, value } });
  };

  const postPaymentMethod = (payment_method) => {
    if (isNaN(payment_method.payment_method_id)) {
      PaymentMethodService.postPaymentMethod(payment_method).then(() => {
        getPaymentMethods();
        clearModal();
      });
    } else {
      PaymentMethodService.putPaymentMethod(payment_method).then(() => {
        getPaymentMethods();
        clearModal();
      });
    }
  };

  const deletePaymentMethod = (payment_method_id) => {
    PaymentMethodService.deletePaymentMethod(payment_method_id).then(() => {
      getPaymentMethods();
      clearModal();
    });
  };


  return (
    <PaymentMethodsContext.Provider 
      value={{ 
        ...state, 
        getPaymentMethods,
        setPaymentMethod,
        createPaymentMethod,
        setPropiedadPaymentMethod,
        postPaymentMethod,
        deletePaymentMethod
      }}
    >
      {children}
    </PaymentMethodsContext.Provider>
  );
};
