import React, { useContext, useEffect } from "react";
import DateTimeInput from "../../components/common/DateTimeInput";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { LocationsContext } from "../../context/LocationsContext";

const SpecialEventOptions = ({ class_package, modifier }) => {
  const { class_date, location_id, class_type_id } = class_package;

  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  const { locations, getLocations } = useContext(LocationsContext);

  useEffect(() => {
    getClassTypes();
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (!class_type_id || class_type_id === null) &&
      Array.isArray(class_types)
    ) {
      if (typeof class_types[0] === "object") {
        modifier("class_type_id", class_types[0].class_type_id);
      }
    }
    if ((!location_id || location_id === null) && Array.isArray(locations)) {
      if (typeof locations[0] === "object") {
        modifier("location_id", locations[0].location_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_types, locations]);

  const renderLocations = () => {
    if (locations && locations !== null) {
      return locations.map(({ location_id, name }) => (
        <option key={location_id} value={location_id}>
          {name}
        </option>
      ));
    }
  };

  const renderClassTypes = () => {
    if (class_types && class_types !== null) {
      return class_types.map(({ class_type_id, name }) => (
        <option key={class_type_id} value={class_type_id}>
          {name}
        </option>
      ));
    }
  };

  return (
    <div>
      <DateTimeInput class_date={class_date} modifier={modifier} />
      <div className="row mb-3">
        <div className="col-6">
          <label>Ubicación</label>
          <select
            className="form-control"
            onChange={(e) => modifier("location_id", e.target.value)}
            value={location_id && location_id !== null ? location_id : ""}
          >
            {renderLocations()}
          </select>
        </div>
        <div className="col-6">
          <label>Tipo de Clase</label>
          <select
            className="form-control"
            value={class_type_id && class_type_id !== null ? class_type_id : ""}
            onChange={(e) => modifier("class_type_id", e.target.value)}
          >
            {renderClassTypes()}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SpecialEventOptions;
