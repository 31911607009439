import React from "react";

const CallToAction = ({
  title,
  tagline,
  color,
  text_color,
  call_to_action,
}) => {
  return (
    <div className="container-fluid my-3">
      <button
        onClick={call_to_action}
        className={`btn btn-danger w-100 d-block call-to-action no-decoration p-3 text-center shadow-sm pointer text-${text_color} bg-${color}`}
      >
        <h4 className="bold">
          <i className="fa fa_prepended fas fa-shopping-cart pe-2"></i>
          {title}
        </h4>
        <p className="mb-0">{tagline}</p>
      </button>
    </div>
  );
};

export default CallToAction;
