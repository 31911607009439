import React, { useContext } from "react";
import moment from "moment-timezone";
import SecureInput from "./SecureInput";
import { AppConfigContext } from "../../context/AppConfigContext";
import FilesLocationInput from "./FilesLocationInputs";

const AdvancedSettings = () => {
  const {
    timezone,
    stripe_secret,
    brevo_api_key,
    stripe_public_key,
    setAppConfigProperty,
    stripe_webhook_secret,
  } = useContext(AppConfigContext);

  const timezones = [
    { label: "Seleccionar...", value: "" },
    ...moment.tz
      .zonesForCountry("MX")
      .map((name) => ({ label: name, value: name })),
  ];

  return (
    <div className="col-12 px-0">
      <h2 className="h4 mb-2 pb-2 border-bottom">Precaución</h2>
      <p className="text-danger bold">
        Modificar cualquier configuración en esta pestaña podría causar que tu
        plataforma deje de funcionar.
      </p>

      <div className="row mx-auto mb-3">
        <SecureInput
          smallTitle
          hideValue
          type={"select"}
          value={timezone}
          label={"Zona Horaria"}
          selectOptions={timezones}
          handleChange={(event) => {
            setAppConfigProperty("timezone", event.target.value);
          }}
        />
      </div>

      <FilesLocationInput />

      <div className="col-12 mx-auto px-0 mb-3">
        <SecureInput
          smallTitle
          type={"text"}
          hideValue
          label={"Stripe Public Key"}
          value={stripe_public_key}
          handleChange={(event) => {
            setAppConfigProperty("stripe_public_key", event.target.value);
          }}
        />
      </div>

      <div className="row mx-auto mb-3">
        <SecureInput
          smallTitle
          type={"text"}
          hideValue
          label={"Stripe Secret"}
          value={stripe_secret}
          handleChange={(event) => {
            setAppConfigProperty("stripe_secret", event.target.value);
          }}
        />
      </div>

      <div className="row mx-auto mb-3">
        <SecureInput
          smallTitle
          type={"text"}
          hideValue
          label={"Stripe WebHook Secret"}
          value={stripe_webhook_secret}
          handleChange={(event) => {
            setAppConfigProperty("stripe_webhook_secret", event.target.value);
          }}
        />
      </div>

      <div className="col-12 mx-auto px-0 mb-3">
        <SecureInput
          smallTitle
          type={"text"}
          hideValue
          label={"Brevo API Key"}
          value={brevo_api_key}
          handleChange={(event) => {
            setAppConfigProperty("brevo_api_key", event.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AdvancedSettings;
