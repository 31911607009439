import React, { createContext, useContext, useReducer } from "react";
import VideoTypeReducer from "../reducers/VideoTypeReducer";
import VideoTypeService from "../services/VideoTypeService";
import {
  CREATE_VIDEO_TYPE,
  SET_PROPERTY_VIDEO_TYPE,
  SET_VIDEO_TYPE,
  VIDEO_TYPE_RECIBIDOS,
} from "../types/video_types";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { ModalContext } from "./ModalContext";
import { hideModal } from "../utils";

const initialState = {
  video_types: null,
  video_type: null,
};

export const VideoTypeContext = createContext(initialState);

export const VideoTypeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VideoTypeReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const getVideoTypes = () => {
    VideoTypeService.getVideoTypes().then((res) => {
      const { video_types } = res.data;
      dispatch({ type: VIDEO_TYPE_RECIBIDOS, payload: video_types });
    });
  };

  const setVideoType = (video_type) => {
    dispatch({ type: SET_VIDEO_TYPE, payload: video_type });
  };

  const createVideoType = () => {
    dispatch({ type: CREATE_VIDEO_TYPE });
  };

  const setPropertyVideoType = (key, value) => {
    dispatch({ type: SET_PROPERTY_VIDEO_TYPE, payload: { key, value } });
  };

  const postVideoType = (video_type) => {
    dispatch({ type: SHOW_SPINNER });
    let service = VideoTypeService.putVideoType;
    if (isNaN(video_type.video_type_id)) {
      service = VideoTypeService.postVideoType;
    }
    service(video_type)
      .then(() => {
        hideModal();
        getVideoTypes();
        dispatch({ type: HIDE_SPINNER });
        success("Tipo de Video guardado.");
        dispatch({ type: SET_VIDEO_TYPE, payload: null });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const deleteVideoType = (video_type_id) => {
    dispatch({ type: SHOW_SPINNER });
    VideoTypeService.deleteVideoType(video_type_id)
      .then(() => {
        hideModal();
        getVideoTypes();
        success("Tipo de Video guardado.");
        dispatch({ type: HIDE_SPINNER });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  return (
    <VideoTypeContext.Provider
      value={{
        ...state,
        setVideoType,
        getVideoTypes,
        postVideoType,
        createVideoType,
        deleteVideoType,
        setPropertyVideoType,
      }}
    >
      {children}
    </VideoTypeContext.Provider>
  );
};
