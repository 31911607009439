import React, { useState, useEffect } from "react";

const Pagination = ({ modifier }) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    modifier(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="container-fluid text-right px-0 mt-2">
      <button
        className="btn bg-white border mx-2"
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
      >
        <i className="fa fa-chevron-left"></i>
      </button>
      <span className="mx-2">{page}</span>
      <button
        className="btn bg-white border mx-2"
        onClick={() => setPage(page + 1)}
      >
        <i className="fa fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
