import React, { useContext } from "react";
import FreeTrialsTable from "../../components/purchases/FreeTrialsTable";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";

const AdminFreeTrials = () => {
  const { free_trials, getFreeTrials } = useContext(AnaliticasContext);

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Free Trials" callback={getFreeTrials} />
      <div className="card  p-3">
        <FreeTrialsTable purchases={free_trials} />
      </div>
    </div>
  );
};

export default AdminFreeTrials;
