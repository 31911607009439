import React, { useState } from "react";

const CancelForm = ({ purchase, customer_id, cancelPurchase }) => {
  const [reason, setReason] = useState("");
  const [option, setOption] = useState("");

  const renderOtro = () => {
    if (option === "Otro") {
      return (
        <div>
          <label>Escribe la razón</label>
          <input
            type="text"
            value={reason}
            className="form-control mb-3"
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <p>
        ¿Estás seguro que deseas cancelar la compra {purchase.title}? Esta
        acción NO puede deshacerse. El cliente disfrutará sus beneficios hasta
        la fecha que termine su vigencia.
      </p>
      <label>Razón</label>
      <select
        className="form-control mb-3"
        value={option}
        onChange={(e) => setOption(e.target.value)}
      >
        <option>Pausa por COVID.</option>
        <option>No tiene tiempo.</option>
        <option>No le gustó.</option>
        <option>Embarazo.</option>
        <option>Otro</option>
      </select>
      {renderOtro()}
      <button
        className="btn btn-danger"
        onClick={() =>
          cancelPurchase(
            purchase.purchase_id,
            option !== "otro" ? option : reason,
            customer_id
          )
        }
      >
        Cancelar Compra
      </button>
    </div>
  );
};

export default CancelForm;
