import React, { useContext, useEffect, useState } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import AvailableClassesTable from "../../components/availableClasses/AvailableClassesTable";
import { ModalContext } from "../../context/ModalContext";
import AvailableClassForm from "../../components/availableClasses/AvailableClassForm";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
import Pagination from "../global/Pagination";

const AvailableClassesPanel = ({ customer_id }) => {
  const [page, setPage] = useState(1);
  const [allSelected, setAllSelected] = useState(false);
  const [itemsSelected, setItemsSelected] = useState([]);

  const { available_classes, getAvailableClassesByCustomerId } = useContext(
    AvailableClassesContext
  );
  const { modalComponent, clearModal } = useContext(ModalContext);

  useEffect(() => {
    fetchAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id, page]);

  const fetchAvailableClasses = () =>
    getAvailableClassesByCustomerId(customer_id, { page });

  const editSelection = () => {
    modalComponent(
      "Editar Selección",
      <AvailableClassForm
        handleCancel={clearModal}
        handleCallback={fetchAvailableClasses}
        editMany
        items={itemsSelected}
      />
    )
  }

  const hideButton = itemsSelected.length <= 0;

  const renderEditSelectionBtn = () => {
    return (
      <div className="col col-md-6 px-0 text-end">
        <button className="btn btn-primary" onClick={editSelection}>
          <i className="fas fa-edit me-2"/>
          Editar Selección
        </button>
      </div>
    )
  }

  return (
    <div className="card p-3 pb-1 no-scale my-3">
      <PanelTitle
        title="Créditos de Clases"
        hideButton={hideButton}
        buttonComponent={renderEditSelectionBtn()}
      />
      <AvailableClassesTable
        available_classes={available_classes}
        handleCallback={fetchAvailableClasses}
        allSelected={allSelected}
        setAllSelected={setAllSelected}
        itemsSelected={itemsSelected}
        setItemsSelected={setItemsSelected}
      />
      <Pagination modifier={setPage}/>
    </div>
  );
};

export default AvailableClassesPanel;
