import { useContext } from "react";
import { AppConfigContext } from "../context/AppConfigContext";
import { getValue } from "../utils";


export default function useAppConfigValues() {
  const appconfig = useContext(AppConfigContext);

  const getAppConfigValue = (key, valueType) => {
    return getValue(appconfig, key, valueType);
  }

  const videosEnabled = () => getAppConfigValue('videos_enabled', 'boolean');
  const singleClassEnabled = () => getAppConfigValue('single_class_enabled', 'boolean');
  const allowRestrictedClassTypes = () => getAppConfigValue('allow_restricted_class_types', 'boolean');

  return {
    getAppConfigValue,
    videosEnabled,
    singleClassEnabled,
    allowRestrictedClassTypes
  };
}