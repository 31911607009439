import React, { useContext, useEffect } from "react";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import { getValue } from "../../utils";

const SelectPaymentMethod = ({ value, modifier }) => {
  useEffect(() => {
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  const renderPaymentMethods = () => {
    if (Array.isArray(payment_methods)) {
      return [
        <option key="empty" value="">
          Seleccionar...
        </option>,
        ...payment_methods.map((payment_method) => (
          <option
            key={payment_method.payment_method_id}
            value={payment_method.payment_method_id}
          >
            {payment_method.name}
          </option>
        )),
      ];
    }
  };
  return (
    <div>
      <label>Método de Pago</label>
      <select
        className="form-control mb-3"
        value={getValue({ value }, "value")}
        onChange={(e) => modifier(e.target.value)}
      >
        {renderPaymentMethods()}
      </select>
    </div>
  );
};

export default SelectPaymentMethod;
