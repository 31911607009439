import React from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import { renderName } from "../../utils/customer";
import { getFriendlyLocalDateTimeString } from "../../utils/dates";
import PurchaseActions from "./PurchaseActions";

const classNames = {
  completed: "success",
  success: "success",
  active: "success",
  pending: "warning text-dark",
  failed: "danger",
  refunded: "danger",
};

const PurchaseListItem = ({ purchase }) => {
  return (
    <div className="border-bottom border-top px-1 py-2">
      <div className="row mb-2">
        <div className="col-9">
          <p className="bold mb-0">
            {purchase?.invoice_id && <i className="fa fa-undo small me-1" />}
            {purchase?.admin_enabled && (
              <i className="fa fa fa-user-shield small me-1"></i>
            )}{" "}
            {purchase?.customer?.purchases?.length === 1 && (
              <i className="fa fa-star text-warning small me-1"></i>
            )}
            <span className="small me-1">[#{purchase?.purchase_id}]</span>
            {purchase?.class_package?.title}
          </p>
        </div>
        <div className="col-3">
          <span
            className={`badge w-100 badge-pull bg-${
              classNames[purchase?.status]
            } text-capitalize`}
          >
            ${formatMonto(purchase?.amount)}
          </span>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-6">
          <span style={{ fontSize: 12 }}>{purchase?.payment_method?.name}</span>
        </div>
        <div className="col-6 text-end">
          <span style={{ fontSize: 12 }}>
            {getFriendlyLocalDateTimeString(purchase.createdAt)}
          </span>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-6">
          <Link to={`/myadmin/customer/${purchase?.customer?.customer_id}`}>
            <i className="fa fa-eye me-2"></i>
            {renderName(purchase?.customer)}
          </Link>
        </div>
        <div className="col-6 text-end">
          <PurchaseActions purchase={purchase} />
        </div>
      </div>
    </div>
  );
};

export default PurchaseListItem;
