import React, { useEffect, useContext } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import BirthdateInput from "../common/BirthdateInput";
import ImageInput from "../common/ImageInput";
import { ModalContext } from "../../context/ModalContext";
import SelectCoachCustomer from "./SelectCoachCustomer";
import SelectDiscountCoach from "./SelectDiscountCoach";
import ReactSwitch from "react-switch";

const CoachForm = ({ instructor_id }) => {
  const { clearModal } = useContext(ModalContext);
  const { coach, getCoach, createCoach, postCoach, setPropiedadCoach, setCoach } =
    useContext(CoachesContext);

  useEffect(() => {
    fetchCoach(instructor_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCoach(instructor_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructor_id]);


  const fetchCoach = (instructor_id) => {
    if (isNaN(instructor_id)) {
      createCoach();
    } else {
      getCoach(instructor_id);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postCoach(coach);
  };

  const handleCancel = () => {
    setCoach(null);
    clearModal();
  }

  const handleCoachDiscount = (discount) => {
    setPropiedadCoach("discount_id", discount.value);
  };

  const renderShowHome = () => {
    return(
      <div className={`row mb-3`}>
        <div className="w-50 d-flex justify-content-between align-items-center">
          <label className="">Mostrar en Home</label>
          <ReactSwitch
            checked={coach.show_home}
            onChange={(checked) => {
              setPropiedadCoach('show_home', checked);
            }}
          />
        </div>

        <div className="w-50 ">
          <label className="">Orden</label>
          <input
            className="form-control"
            type="number"
            min={0}
            value={coach.order}
            onChange={(event) => {
              const value = event.target.value;
              setPropiedadCoach('order', value);
            }}
          />
        </div>
       
      </div>
    )
  }

  const renderForm = () => {
    if (coach && coach !== null) {
      const { file, name, last_name, nick_name, birthdate, short_bio } = coach;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropiedadCoach("name", e.target.value)}
          />
          <label>Apellidos</label>
          <input
            type="text"
            className="form-control mb-3"
            value={last_name}
            onChange={(e) => setPropiedadCoach("last_name", e.target.value)}
          />

          <ImageInput value={coach.file?.src} file={file} prop="picture" modifier={setPropiedadCoach} />

          <label>Fecha de Nacimiento</label>
          <BirthdateInput
            value={birthdate}
            modifier={(value) => setPropiedadCoach("birthdate", value)}
          />
          <label>Apodo</label>
          <input
            type="text"
            className="form-control mb-3"
            value={nick_name}
            onChange={(e) => setPropiedadCoach("nick_name", e.target.value)}
          />
          <label>Bio</label>
          <textarea
            rows="5"
            className="form-control mb-3"
            value={short_bio}
            onChange={(e) => setPropiedadCoach("short_bio", e.target.value)}
          />

          {renderShowHome()}
        
          <SelectDiscountCoach coach={coach} modifier={handleCoachDiscount} />

          {!isNaN(instructor_id) && (
            <SelectCoachCustomer coach={coach} modifier={setPropiedadCoach} />
          )}
          <div className="row">
            <div className="col-6 text-center d-flex align-items-center justify-content-center">
              <button 
                type="button"
                onClick={handleCancel}
                className="btn btn-link text-secondary pt-auto pb-0 mb-0"
              >
                Cancelar
              </button>
            </div>

            <div className="col-6">
              <button type="submit" className="btn btn-primary mt-2 w-100">
                Guardar
              </button>
            </div>
          </div>
         

        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default CoachForm;
