import React, { useContext } from "react";
import { Link } from "@reach/router";
import { ReservationsContext } from "../../context/ReservationsContext";
import { CustomerContext } from "../../context/CustomerContext";
import { getFriendlyLocalDateTimeString, getLocalMomentObject } from "../../utils/dates";

const ReservacionRow = ({ reservation, deleteCallback }) => {
  const { eliminarReservacion } = useContext(ReservationsContext);
  const { customer } = useContext(CustomerContext);

  const getPaymentMethod = (reservacion) => {
    if (reservacion.is_cash) return <i className="fas fa-money-bill"></i>;
  };

  const getStatusReservacion = (reservacion) => {
    if (reservacion.deletedAt !== null) {
      return <span className="badge badge-pill bg-danger">Cancelada</span>;
    }
    if (reservacion.is_cash) {
      if (!reservacion.is_paid) {
        return (
          <span className="badge badge-pill bg-danger">
            Pago en Efectivo Incompleto
          </span>
        );
      }
    }
    if (
      !reservacion.attend &&
      getLocalMomentObject().isAfter(getLocalMomentObject(reservacion.single_class.class_date))
    ) {
      return (
        <span className="badge badge-pill text-dark bg-warning">
          No Asistió
        </span>
      );
    }
    if (reservacion.attend) {
      return <span className="badge badge-pill bg-success">Asistió</span>;
    }
  };

  const renderDelete = () => {
    if(getLocalMomentObject().isBefore(getLocalMomentObject(reservation.single_class.class_date))) {
      return (
        <button 
          type="button"
          className="btn btn-sm btn-outline-danger "
          onClick={handleDeleteReservacion}
        >
            <i className="fa fa-trash"></i>
        </button>
      )
    }
  }

  const renderInstructors = () => {
    const currentInstructors = reservation.single_class.class_instructors;

    if (currentInstructors && currentInstructors !== null) {
      const classInstructors = currentInstructors.filter(({ instructor }) =>
        instructor ? instructor : false
      );
      const currentNames = classInstructors.map(
        ({ instructor }) => `${instructor.name} ${instructor.last_name}`
      );
      return currentNames.join(", ");
    }
  };

  const renderClassStars = () => {
    const stars = reservation.class_stars;

    if(stars > 0) {
      return (
        <div className="d-inline-block">
          {stars}
          <i className='fas fa-star text-warning'/>
        </div>
      )
    }
  }

  const renderClassType = () => {
    if (reservation.single_class.class_type !== null) {
      const classType = reservation.single_class.class_type.name;
      return (
          <Link
            className="me-2"
            to={`/myadmin/asistentes/${reservation.single_class.single_class_id}`}
          >
            <i className="fa fa-eye me-2"></i>
            {classType}
          </Link>
      );
    }
  };

  const handleDeleteReservacion = async () => {
    await eliminarReservacion(reservation.class_reservation_id);
    deleteCallback();
  };

  return (
    <tr className="">
      <td className=" ">
        {renderClassType()}
        {renderClassStars()}
      </td>
      <td className=" ">{renderInstructors()}</td>
      <td className=" ">
        {getLocalMomentObject(reservation.single_class.class_date)
          .format("DD MMM HH:mm")}
      </td>
      <td className=" ">
        {getFriendlyLocalDateTimeString(reservation.createdAt)}
      </td>
      <td className=" ">
        {getPaymentMethod(reservation)} {getStatusReservacion(reservation)}
      </td>
      <td className="  text-center">{reservation.spot}</td>
      <td className="  ">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://wa.me/52${customer?.phone}`}
          className={
            customer?.phone !== null ? "text-success" : "text-secondary"
          }
        >
          <i className="fab fa-whatsapp me-2"></i>
          {customer?.phone}
        </a>
      </td>

      <td className="  text-center">
        {renderDelete()}
      </td>
    </tr>
  );
};

export default ReservacionRow;
