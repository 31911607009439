import React, { useContext } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import CustomerCard from "./CustomerCard";
import SortableButton from "./SortableButton";
import { UserContext } from "../../context/UserContext";

const CustomersList = ({ setSort, selected, direction, setDirection }) => {
  const { customers } = useContext(CustomerContext);
  const { user } = useContext(UserContext);

  return (
    <div className="mb-4">
      <div className="row ms-0 mb-3 align-items-center">
        <div className="col-1">
          <i className="fas fa-sort"></i>
        </div>
        <div className="col-11">
          <div className="btn-group shadow-sm w-100">
            <SortableButton
              column="name"
              label="Nombre"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableButton
              column="email"
              label="Correo"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableButton
              column="phone"
              label="Teléfono"
              setSort={setSort}
              selected={selected}
              direction={direction}
              setDirection={setDirection}
            />
            {["super_admin", "admin"].includes(user.role) && (
              <SortableButton
                column="value"
                label="Valor"
                setSort={setSort}
                selected={selected}
                direction={direction}
                setDirection={setDirection}
              />
            )}
          </div>
        </div>
      </div>
      {!Array.isArray(customers) && <div className="spinner-border" />}
      {customers?.map((customer) => (
        <CustomerCard key={customer.customer_id} customer={customer} />
      ))}
    </div>
  );
};

export default CustomersList;
