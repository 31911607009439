import api from "./api";

const route = "/posts";

const AdminLocations = {
  getPosts: () => api.get(`${route}/admin/all`),
  getVideoPosts: () => api.get(`${route}/admin/videos`),
  getClassesPosts: () => api.get(`${route}/admin/classes`),
  getSinglePost: (post_id) => api.get(`${route}/${post_id}`),
  postPost: (post) => api.post(route, { ...post }),
  putPost: (post) => api.put(route, { ...post }),
  deletePost: (Post) => api.delete(`${route}/admin/${Post}`),
};

export default AdminLocations;