import React, { useEffect, useState } from "react";
import Select from "react-select";

const SearchSelect = ({
  error,
  value,
  items,
  itemText,
  itemValue,
  modifier,
  fetchItems,
  placeholderText,
}) => {

  const fetchData = (query) => {
    if (query !== "" && typeof fetchItems === "function") {
      fetchItems({ query });
    }
  }

  const [selectedItem, setSelectedItem] = useState(null);

  const getOptions = () => {
    if(!Array.isArray(items)) return [];
    return items.map((item) => ({
      value: item[itemValue],
      label: item[itemText],
    }));
  };

  const baseStyles = {
    control: (provided) => ({
      ...provided,
      border: "0.5px solid rgb(33, 37, 41)",
      backgroundColor: "#fff",
      borderRadius: "0.25rem",
      paddingLeft: "16px",
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
      backgroundColor: "#f7f7f7",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      backgroundColor: "#f7f7f7",
      color: "black",
    }),
    options: (provided) => ({
      ...provided,
      backgroundColor: "#f7f7f7",
      color: "black",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: "#f7f7f7",
    }),
  };

  const customStyles = {
    ...baseStyles,
    control: (provided) => ({
      ...baseStyles.control(provided),
      boxShadow: error ? "0 0 0 1.5px #dc3545" : "none",
    }),
  };

  useEffect(() => {
    if (value && options) {
      const initialOption = options.filter((option) => {
        return option.value === value;
      });
      setSelectedItem(initialOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (selectedOption) => {
    setSelectedItem(selectedOption);
    if (modifier) {
      modifier(selectedOption);
    }
  };

  const options = getOptions();


  return (
    <Select
      isSearchable
      options={options}
      value={selectedItem}
      styles={customStyles}
      onChange={handleChange}
      onInputChange={fetchData}
      placeholder={placeholderText}
    />
  );
};

export default SearchSelect;
