import React, { useContext } from "react";
import { renderName } from "../../utils/customer";
import { formatMonto, getValue } from "../../utils";
import { UserContext } from "../../context/UserContext";
import { navigate } from "@reach/router";
import { getFriendlyLocalDateString } from "../../utils/dates";

const CustomerCard = ({ customer }) => {
  const { email, phone, total } = customer;
  const { user } = useContext(UserContext);

  const birthdate = getValue(customer, "birthdate", "date");

  const handleClick = () => {
    if (user.role !== "coach") {
      return navigate(`/myadmin/customer/${customer.customer_id}`);
    }
  };

  return (
    <div onClick={handleClick} className="card w-100 p-3 text-dark mb-2 shadow">
      <div className="row mb-1">
        <div className="col-9">
          <h4 className="h6 bold mb-0">{renderName(customer)}</h4>
        </div>
        <div className="col-3 text-end">
          <span className="small">
            {!user.isManager && !user.instructor_id ? (
              <>
                {"$"}
                {formatMonto(total)}
              </>
            ) : (
              "N/D"
            )}
          </span>
          <i className="fa fa-chevron-right text-primary ms-3" />
        </div>
      </div>
      <p className="mb-1 small">
        <i className="fa fa-envelope text-primary me-2" />
        <span className="text-muted">{email}</span>
      </p>
      <div className="row">
        <div className="col-6">
          <p className="mb-1 small">
            <i className="fab fa-whatsapp text-primary me-2" />
            <span className="text-muted">{phone || "No registrado"}</span>
          </p>
        </div>
        <div className="col-6 text-end">
          <p className="mb-1 small">
            <i className="fa fa-birthday-cake text-primary me-2" />
            {getFriendlyLocalDateString(birthdate) || <span className="text-muted">No registrado</span>}
          </p>
        </div>
      </div>
      {customer?.tags?.map((tag) => (
        <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
          {tag.content}
        </span>
      ))}
    </div>
  );
};

export default CustomerCard;
