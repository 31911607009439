import React, { useContext, useEffect } from "react";
import { DiscountsContext } from "../../context/DiscountsContext";
import SearchSelect from "../common/SearchSelect";
import { getValue } from "../../utils";

const SelectDiscountCoach = ({ coach, modifier }) => {
  const { descuentos, getDescuentosAdmin } = useContext(DiscountsContext);

  useEffect(() => {
    getDescuentosAdmin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelect = () => {
    if(descuentos?.length > 0) {
      return (
        <SearchSelect
          items={Array.isArray(descuentos) ? descuentos : []}
          itemText={"code"}
          modifier={modifier}
          itemValue={"discount_id"}
          value={getValue(coach, "discount_id")}
          placeholderText={"Buscar descuento..."}
        />
      )
    }
  }
  return (
    <div className="mb-3">
      <label>Código de Descuento</label>
      {renderSelect()}
    </div>
  );
};

export default SelectDiscountCoach;
