import React, { useState } from "react";
import LeaderboardRow from "./LeaderboardRow";

const LeaderboardTable = ({ query, customers }) => {
  const [sort, setSort] = useState("reservations");

  const renderCustomers = () => {
    if (Array.isArray(customers)) {
      let customersRender = [...customers];
      customersRender = customersRender.sort((a, b) =>
        parseInt(a[sort]) > parseInt(b[sort]) ? -1 : 1
      );
      if (query && query !== "") {
        customersRender = customersRender.filter(
          ({ name, last_name, email }) =>
            String(name).includes(query) ||
            String(last_name).includes(query) ||
            String(email).includes(query)
        );
      }
      return customersRender.map((customer, index) => (
        <LeaderboardRow
          key={customer.customer_id}
          customer={customer}
          index={index}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-dark text-white bold p-2">
            <th>
              <i className="fas fa-award"></i>
            </th>
            <th>Nombre</th>
            <th
              onClick={() => setSort("reservations")}
              className={sort === "reservations" ? "bg-primary text-white" : ""}
            >
              Reservadas
              <i
                className={`fa ms-2 fa-chevron-${
                  sort === "reservations" ? "down" : "up"
                }`}
              ></i>
            </th>
            <th
              onClick={() => setSort("attended")}
              className={sort === "attended" ? "bg-primary text-white" : ""}
            >
              Asistencias
              <i
                className={`fa ms-2 fa-chevron-${
                  sort === "attended" ? "down" : "up"
                }`}
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default LeaderboardTable;
