import React from "react";

const SortableButton = ({
  direction,
  setDirection,
  setSort,
  label,
  column,
  selected,
}) => {
  return (
    <button
      onClick={() => {
        setSort(column);
        setDirection(direction === "ASC" ? "DESC" : "ASC");
      }}
      className={`btn btn-sm ${selected === column ? "btn-primary" : "btn-light"} border px-1`}
    >
      {label}
      <i
        className={`ms-1 small fa fa-chevron-${direction === "DESC" ? "down" : "up"}`}
      ></i>
    </button>
  );
};

export default SortableButton;
