import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import Pagination from "../../components/global/Pagination";
import { InvoicesContext } from "../../context/InvoicesContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import InvoicesTable from "../../components/invoices/InvoicesTable";

const AdminInvoices = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const { spinner, invoices, getInvoices } = useContext(InvoicesContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && !spinner) {
      fetchInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, page, status, query]);

  const fetchInvoices = () => {
    getInvoices(startDate, endDate, { page, status, query });
  };

  const setDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Cargos" callback={setDates} />
      <div className="container-fluid px-0">
        <input
          type="text"
          value={query}
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar por nombre o correo electrónico..."
        />
      </div>
      <div className="card  p-3 mb-3 shadow-sm">
        <div className="container-fluid px-0 mb-3">
          <button
            className={`btn btn-sm btn-${
              status === "" ? "primary" : "light"
            } border br-0`}
            onClick={() => setStatus("")}
          >
            Todo
          </button>
          <button
            className={`btn btn-sm btn-${
              status === "completed" ? "primary" : "light"
            } border br-0`}
            onClick={() => setStatus("completed")}
          >
            Completados
          </button>
          <button
            className={`btn btn-sm btn-${
              status === "failed" ? "primary" : "light"
            } border br-0`}
            onClick={() => setStatus("failed")}
          >
            Fallidos
          </button>
          <button
            className={`btn btn-sm btn-${
              status === "pending" ? "primary" : "light"
            } border br-0`}
            onClick={() => setStatus("pending")}
          >
            Pendientes
          </button>
        </div>
        <InvoicesTable user={user} invoices={invoices} />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminInvoices;
