import React from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import { SortableHandle } from "react-sortable-hoc";

const DragHandle = SortableHandle(() => (
  <span>
    <i className="fas fa-grip-lines"></i>
  </span>
));

const PaqueteRow = ({ paquete, draggable, confirmDelete }) => {
  const {
    class_package_id,
    is_special_event,
    title,
    available,
    price,
    sale_price,
  } = paquete;

  const onSale = sale_price && sale_price !== null;

  return (
    <div className="card p-3 pb-2 hover-light br-0">
      <div className="row small align-items-center">
        {draggable && (
          <div className="col">
            <DragHandle />
          </div>
        )}
        <div className="col-6 col-md-2 mb-2">
          <h4 className="h5 mb-0">
            {is_special_event && <i class="fas fa-star text-primary" />} {title}
          </h4>
        </div>
        <div className="col-6 col-md-2 text-md-start text-end mb-2">
          <span
            className={`badge badge-pill bg-${
              available ? "success" : "secondary"
            }`}
          >
            {available ? "Disponible" : "Oculto"}
          </span>
        </div>
        <div className="col-6 col-md-2 mb-2">
          <span className={`${onSale ? "text-decoration-line-through me-2" : ""}`}>
            {"$"}
            {formatMonto(price)}
          </span>
          {onSale && <span className="bold show-mobile text-primary">${formatMonto(sale_price)}</span>}
        </div>
        <div className="ccol-6 col-md-2 mb-1 hide-mobile">
          {sale_price === null ? "" : `$${formatMonto(sale_price)}`}
        </div>
        {!draggable && (
          <div className="col-6 col-md-2 text-md-center text-end mb-2">
            <Link
              className="btn btn-outline-primary btn-sm me-2"
              to={`/myadmin/paquetes/${class_package_id}`}
            >
              <i className="fa fa-edit"></i>{" "}
              <span className="hide-mobile">Editar</span>
            </Link>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => confirmDelete(paquete)}
            >
              <i className="fa fa-trash"></i>{" "}
              <span className="hide-mobile">Eliminar</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaqueteRow;
