import { SET_FORM, SET_FORM_PROPERTY, SET_NEW_TESTIMONIO, SET_PROPERTY, SET_TESTIMONIO, SET_TESTIMONIOS, UPDATE_FORM } from "../types/testimonios";

const schema = {
  user_name: '',
  user_id: null,
  user_image: null,
  description: null,
  category: null,
  rating: 0,
  before_file: null,
  after_file: null,
  status: '',
  order: null
};


const TestimoniosReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_NEW_TESTIMONIO:
      return {
        ...state,
        testimonio: schema
      }
    case SET_TESTIMONIOS:
      return {
        ...state,
        testimonios: payload
      }
    case SET_TESTIMONIO:
      return {
        ...state,
        testimonio: payload
      }
    case SET_PROPERTY:
      return { 
        ...state, 
        testimonio: {
          ...state.testimonio,
          [payload.key]: payload.value
        }
      }
    case SET_FORM:
      return {
        ...state,
        testimonio_form: payload
      }
    case UPDATE_FORM:
      return {
        ...state,
        testimonio_form: {
          ...state.testimonio_form,
          ...payload
        }
      }
    case SET_FORM_PROPERTY:
      return { 
        ...state, 
        testimonio_form: {
          ...state.testimonio_form,
          [payload.key]: payload.value
        }
      }
    default:
      return { ...state };
  }
};
export default TestimoniosReducer;
