import React from "react";

const ScheduleMonthSelect = ({ yearString, monthString, setNextMonth, setPrevMonth }) => {

  const renderPrev = () => {
    return (
      <button className="btn btn-light border" onClick={setPrevMonth}>
        <i className="fa fa-chevron-left"></i>
      </button>
    );
  };

  return (
    <div className="row align-items-center">
      <div className="col-3">{renderPrev()}</div>
      <div className="col-6 text-center">
        <h2 className="h3 mb-0">
          {monthString} <span className="small">{yearString}</span>
        </h2>
      </div>
      <div className="col-3 text-right">
        <button className="btn btn-light border" onClick={setNextMonth}>
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default ScheduleMonthSelect;
