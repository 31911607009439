import { HIDE_SPINNER, SHOW_SPINNER, USERS_RECIBIDOS } from "../types";

const UsersReducer = (state, { type, payload }) => {
  switch (type) {
    case USERS_RECIBIDOS:
      return { ...state, users: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default UsersReducer;
