
import React, { createContext, useReducer, useContext } from 'react';
import AvailableClassesService from '../services/AvailableClassesService';
import AvailableClassesReducer from '../reducers/AvailableClassesReducer';
import {
  AVAILABLECLASSS_RECEIVED,
  SET_AVAILABLECLASS,
  CREATE_AVAILABLECLASS,
  SET_PROPERTY_AVAILABLECLASS,
} from "../types/available_classes";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  available_classes: null,
  available_class: null,
};

export const AvailableClassesContext = createContext(initialState);

export const AvailableClassesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AvailableClassesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getAvailableClasses = () => {
    AvailableClassesService.getAvailableClasses()
      .then((response) => {
        const { available_classes } = response.data;
        dispatch({ type: AVAILABLECLASSS_RECEIVED, payload: available_classes });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getAvailableClassesByCustomerId = (customer_id, filters) => {
    AvailableClassesService.getAvailableClassesByCustomerId(customer_id, filters)
      .then((response) => {
        const { available_classes } = response.data;
        dispatch({ type: AVAILABLECLASSS_RECEIVED, payload: available_classes });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleAvailableClass = (availableClass_id) => {
    AvailableClassesService.getSingleAvailableClass(availableClass_id)
      .then((response) => {
        const { availableClass } = response.data;
        dispatch({ type: SET_AVAILABLECLASS, payload: availableClass });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setAvailableClass = (availableClass) => {
    dispatch({ type: SET_AVAILABLECLASS, payload: availableClass });
  };

  const createAvailableClass = () => {
    dispatch({ type: CREATE_AVAILABLECLASS });
  };

  const setPropertyAvailableClass = (key, value) => {
    dispatch({ type: SET_PROPERTY_AVAILABLECLASS, payload: { key, value } });
  };

  const saveAvailableClass = (availableClass, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = AvailableClassesService.putAvailableClass;
      if(isNaN(parseInt(availableClass.available_class_id))) {
        service = AvailableClassesService.postAvailableClass;
      }
      service(availableClass).then(() => {
        success("Crédito de Clase guardado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const saveManyAvailableClasses = (data, callback) => {
    AvailableClassesService.putAvailableClass(data)
      .then(res => {
        success("Créditos de Clases modificados.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch(err => {
        dispatch({ type: HIDE_SPINNER });
        alert(err);
      });
  }

  const deleteAvailableClass = (available_class_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    AvailableClassesService.deleteAvailableClass(available_class_id).then(() => {
      success("Crédito de Clase eliminado.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <AvailableClassesContext.Provider
    value={{
      ...state,
      setAvailableClass,
      getAvailableClasses,
      saveAvailableClass,
      deleteAvailableClass,
      createAvailableClass,
      getSingleAvailableClass,
      setPropertyAvailableClass,
      saveManyAvailableClasses,
      getAvailableClassesByCustomerId,

    }}
  >
    {children}
  </AvailableClassesContext.Provider>
);
};
