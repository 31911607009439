import React from "react";
import WeekScheduleAdminDay from "./WeekScheduleAdminDay";

const WeekScheduleMobile = ({ weekClasses, handleCallback }) => {
  const renderDaysMobile = () => {
    if (Array.isArray(weekClasses)) {
      if (weekClasses.length === 0) {
        return (
          <div className="row">
            <p className="px-0 mb-0 text-center">
              No hay clases programadas esta semana.
            </p>
          </div>
        );
      }
      return weekClasses.map((currentDay) => (
        <WeekScheduleAdminDay
          day={currentDay}
          key={currentDay.date}
          clases={currentDay.details}
          handleCallback={handleCallback}
          isMobile
        />
      ));
    }
  };
  return (
    <div
      id="schedule-mobile-wrapper"
      className="container-fluid ps-0 schedule-mobile-container"
    >
      <div className="schedule-mobile-wrapper">
        <div className="row">{renderDaysMobile()}</div>
      </div>
    </div>
  );
};

export default WeekScheduleMobile;
