import api from "./api";
import firebase from "firebase";
import "firebase/auth";

const FirebaseService = (firebaseConfig) => {
  firebaseConfig = JSON.parse(firebaseConfig);
  // Initialize Firebase
  if (firebase.apps.length < 1) {
    firebase.initializeApp(firebaseConfig);
  }

  const getToken = () => auth.currentUser.getIdToken(true);

  const auth = firebase.auth();

  let refreshTokenActive = true;

  return {
    signIn: (email, password) =>
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          return getToken().then((token) => {
            api.defaults.headers.common["Authorization"] = token;
            return user;
          });
        }),
    userLoggedIn: (success, error) =>
      auth.onAuthStateChanged((user) => {
        if (user) {
          getToken().then((token) => {
            api.defaults.headers.common["Authorization"] = token;
            if (success) success(user);
          });
        } else {
          error();
        }
      }),
    signOut: () => auth.signOut(),
    signUp: (correo, password) =>
      auth.createUserWithEmailAndPassword(correo, password),
    recoverPassword: (email) => auth.sendPasswordResetEmail(email),
    getToken: () => auth.currentUser.getIdToken(true),
    verifyEmail: () => auth.currentUser.sendEmailVerification(),
    updateEmail: (email) => auth.currentUser.updateEmail(email),
    setToken: (token) => (api.defaults.headers.common["Authorization"] = token),
    setupInterceptors: (callback) => {
      api.interceptors.response.use(
        function (response) {
          if(refreshTokenActive) {
            getToken().then((token) => {
              api.defaults.headers.common["Authorization"] = token;
            });

            refreshTokenActive = false;

            setTimeout(() => {
              refreshTokenActive = true
            }, 15000);
          }
          
          return response;
        },
        function (error) {
          if (error.response.data) {
            if (error.response.data.code) {
              if (error.response.data.code === "auth/id-token-expired") {
                callback(error.response.data);
              }
            }
          }

          return Promise.reject(error);
        }
      );
    },
  };
};

export default FirebaseService;
