import React from "react";
import {
  getFriendlyLocalDateTimeString,
  getLocalMomentObject,
} from "../../utils/dates";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import PurchaseActions from "./PurchaseActions";

const PurchaseRow = ({ user, paquete, hideColumns }) => {
  const renderPaymentMethod = () => {
    if (paquete.payment_method && paquete.payment_method !== null) {
      return paquete.payment_method.name;
    }
  };

  const renderExpiration = () => {
    let createdAt = getLocalMomentObject(paquete.createdAt);
    if (paquete.subscription_id !== null) {
      if (paquete.status === "active") {
        let day = createdAt.day();
        let nextMonth = getLocalMomentObject();
        let currDay = getLocalMomentObject().day();
        if (day < currDay) {
          nextMonth = getLocalMomentObject(nextMonth, "M").add(
            paquete.subscription_interval,
            paquete.subscription_period
          );
        }
        return nextMonth
          .startOf("month")
          .add(day, "days")
          .format("DD MMM YYYY");
      }
      return createdAt
        .add(paquete.subscription_interval, paquete.subscription_period)
        .format("DD MMM YYYY");
    }
    return createdAt.add(paquete.expiration_days, "days").format("DD MMM YYYY");
  };

  const renderType = () => {
    if (!Array.isArray(hideColumns) || !hideColumns.includes("type")) {
      return (
        <td>{paquete.invoice_id ? <i className="fa fa-undo"></i> : ""}</td>
      );
    }
  };

  const renderCustomer = () => {
    if (
      paquete.customer &&
      paquete.customer !== null &&
      (!Array.isArray(hideColumns) || !hideColumns.includes("customer"))
    ) {
      return (
        <td>
          <Link to={`/myadmin/customer/${paquete.customer.customer_id}`}>
            <i className="fa fa-eye"></i> {paquete.customer.name}{" "}
            {paquete.customer.last_name}
          </Link>
        </td>
      );
    }
  };

  const isFirstPurchase = () => {
    if (paquete.customer && paquete.customer !== null) {
      if (Array.isArray(paquete.customer.purchases)) {
        return paquete.customer.purchases.length === 1;
      }
    }
  };

  const renderDiscountCode = () => {
    if (paquete.discount && paquete.discount !== null) {
      return paquete.discount.code;
    }
  };

  const renderClassPackage = () => {
    if (paquete.class_package && paquete.class_package !== null) {
      return paquete.class_package.title;
    }
  };

  const renderCustomerTags = () => {
    if (Array.isArray(paquete?.customer?.tags)) {
      return paquete.customer.tags.map((tag) => {
        return (
          <span key={tag.tag_id} className="badge badge-pill bg-secondary me-1">
            {tag.content}
          </span>
        );
      });
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {paquete.invoice_id ? paquete.invoice_id : paquete.purchase_id}{" "}
        {paquete.admin_enabled && <i className="fa fa fa-user-shield"></i>}{" "}
        {isFirstPurchase() && <i className="fa fa-star text-warning"></i>}
      </td>
      {renderType()}
      {renderCustomer()}
      <td>{renderClassPackage()}</td>
      <td>{getFriendlyLocalDateTimeString(paquete.createdAt)}</td>
      <td>
        {paquete.is_gift && <i className="fas fa-gift me-1"></i>}
        {["admin", "super_admin"].includes(user.role) && (
          <>
            {"$"}
            {formatMonto(paquete.amount)} MXN
          </>
        )}
      </td>
      <td>{renderExpiration()}</td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge status={paquete.status} date={paquete.updatedAt} />
      </td>
      <td>{renderDiscountCode()}</td>
      <td className="px-0">{renderCustomerTags()}</td>
      <td>
        <PurchaseActions purchase={paquete} />
      </td>
    </tr>
  );
};

export default PurchaseRow;
