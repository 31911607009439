import { getArgs } from "../utils";
import api from "./api";

const route = "/available_classes";

const AvailableClassesService = {
  getAvailableClasses: () => api.get(route),
  getAvailableClassesByCustomerId: (customer_id, filters) =>
    api.get(`${route}/${customer_id}?${getArgs(filters)}`),
  getSingleAvailableClass: (availableClass_id) =>
    api.get(`${route}/${availableClass_id}`),
  postAvailableClass: (availableClass) =>
    api.post(route, { ...availableClass }),
  putAvailableClass: (availableClass) => api.put(route, { ...availableClass }),
  deleteAvailableClass: (available_class_id) =>
    api.delete(`${route}/${available_class_id}`),
};

export default AvailableClassesService;
