import React from "react";

const CoachCustomer = ({ user, modifier }) => {
  return (
    <div className="container-fluid px-0">
      <label className="bold">Usuario conectado</label>
      <div className="row align-items-center mb-3">
        <div className="col-6">
          <p className="mb-0">
            {user.customer.name} {user.customer.last_name}
          </p>
          <p className="small text-muted">{user.customer.email}</p>
        </div>
        <div className="col-6 text-right">
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => modifier(user)}
          >
            <i className="fa fa-times me-1"></i> Desconectar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoachCustomer;
