import React from "react";

const Attention = ({ highlight, content }) => {
  return (
    <div className="container-fluid my-3">
      <h4 className="text-center">
        <span className="bg-warning px-3 py-1">
          {highlight && <b>{highlight}: </b>}
          {content}
        </span>
      </h4>
    </div>
  );
};

export default Attention;
