import React, { useEffect, useState } from "react";
import Attention from "./Attention";
import Bonus from "./Bonus";
import BulletList from "./BulletList";
import CallToAction from "./CallToAction";
import MainPitch from "./MainPitch";
import Pitch from "./Pitch";
import SectionTitle from "./SectionTitle";
import Secure from "./Secure";
import Stacking from "./Stacking";
import VideoTestimonial from "./VideoTestimonial";
import CustomContent from "./CustomContent";
import Product from "./Product";
import PictureTestimonial from "./PictureTestimonial";
import ThankYou from "./ThankYou";
import ElementForm from "./ElementForm";
import ImageText from "./ImageText";
import NumberList from "./NumberList";
import DoubleButton from "./DoubleButton";

const ElementFormPreview = ({ object, move, dbref }) => {
  const [file, setFile] = useState(null);
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    if (file !== null) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPicture(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const renderContent = () => {
    let CustomComponent = "";
    if (object.component.includes("MainPitch")) {
      CustomComponent = MainPitch;
    } else if (object.component.includes("CallToAction")) {
      CustomComponent = CallToAction;
    } else if (object.component.includes("SectionTitle")) {
      CustomComponent = SectionTitle;
    } else if (object.component.includes("Pitch")) {
      CustomComponent = Pitch;
    } else if (object.component.includes("Stacking")) {
      CustomComponent = Stacking;
    } else if (object.component.includes("Attention")) {
      CustomComponent = Attention;
    } else if (object.component.includes("Bonus")) {
      CustomComponent = Bonus;
    } else if (object.component.includes("VideoTestimonial")) {
      CustomComponent = VideoTestimonial;
    } else if (object.component.includes("Secure")) {
      CustomComponent = Secure;
    } else if (object.component.includes("CustomContent")) {
      CustomComponent = CustomContent;
    } else if (object.component.includes("Product")) {
      CustomComponent = Product;
    } else if (object.component.includes("BulletList")) {
      CustomComponent = BulletList;
    } else if (object.component.includes("PictureTestimonial")) {
      CustomComponent = PictureTestimonial;
    } else if (object.component.includes("ThankYou")) {
      CustomComponent = ThankYou;
    } else if (object.component.includes("ImageText")) {
      CustomComponent = ImageText;
      if (picture !== null) {
        object.props.src = picture;
      }
    } else if (object.component.includes("NumberList")) {
      CustomComponent = NumberList;
    } else if (object.component.includes("DoubleButton")) {
      CustomComponent = DoubleButton;
    }
    if (CustomComponent !== "") {
      return <CustomComponent {...object.props} />;
    } else if (object.component === "Checkout") {
      document.title = object.props.page_title;
    }
  };

  return (
    <div className="row align-items-center">
      <div className="col-6 bg-light px-4">
        <ElementForm
          key={object.component}
          dbref={dbref}
          name={object.component}
          props={object.props}
          move={move}
          file={file}
          setFile={setFile}
        />
      </div>
      <div className="col-6">
        <div className="row">
          <div className="container">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default ElementFormPreview;
