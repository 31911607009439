import api from "./api";

const route = "/payment_methods";

const PaymentMethodsService = {
  getPaymentMethods: () => api.get(route),
  postPaymentMethod: (data) => api.post(route, { ...data }),
  putPaymentMethod: (data) => api.put(route, { ...data }),
  deletePaymentMethod: (payment_method_id) => api.delete(`${route}/${payment_method_id}`),
};

export default PaymentMethodsService;
