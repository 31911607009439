import React from "react";
import SingleClassRow from "./SingleClassRow";

const SingleClassTable = ({ single_classes }) => {
  const renderSingleClasses = () => {
    if (Array.isArray(single_classes)) {
      if (single_classes.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay clases registradas.</td>
          </tr>
        );
      }
      return single_classes.map((single_class) => (
        <SingleClassRow
          key={single_class.single_class_id}
          single_class={single_class}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>Fecha</td>
            <td>Coach</td>
            <td>Ubicación</td>
            <td>Reservas</td>
          </tr>
        </thead>
        <tbody>{renderSingleClasses()}</tbody>
      </table>
    </div>
  );
};

export default SingleClassTable;
