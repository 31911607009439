import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import VideoTypeForm from "../../components/videoTypes/VideoTypeForm";
import { VideoTypeContext } from "../../context/VideoTypeContext";
import VideoTypeRow from "../../components/videoTypes/VideoTypeRow";

const AdminVideoTypes = () => {
  const [query, setQuery] = useState("");

  const {
    video_types,
    setVideoType,
    getVideoTypes,
    createVideoType,
    deleteVideoType,
  } = useContext(VideoTypeContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getVideoTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createType = () => {
    createVideoType();
    modalComponent("Agregar Tipo", <VideoTypeForm />);
  };

  const editVideoType = (video_type) => {
    setVideoType(video_type);
    modalComponent("Editar Tipo", <VideoTypeForm />);
  };

  const confirmDelete = (video_type) => {
    modalComponent(
      "Eliminar Tipo",
      <div>
        <p>
          ¿Estás seguro que deseas eliminar el tipo de video "{video_type.name}
          "? Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteVideoType(video_type.video_type_id)}
        >
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </div>
    );
  };

  const renderTipos = () => {
    if (video_types && video_types !== null) {
      if (video_types.length === 0) {
        return (
          <tr>
            <td>
              <p className="mb-0">No hay tipos de clase disponibles.</p>
            </td>
          </tr>
        );
      }
      let classTypesRender = video_types;
      if (query && query !== null) {
        const current = String(query).toLowerCase();
        classTypesRender = classTypesRender.filter(({ name }) => {
          name = String(name).toLowerCase();
          return name.includes(current) || name.startsWith(current);
        });
      }
      return classTypesRender.map((video_type) => (
        <VideoTypeRow
          key={video_type.video_type_id}
          video_type={video_type}
          editVideoType={editVideoType}
          confirmDelete={confirmDelete}
        />
      ));
    }
    return (
      <tr>
        <td>
          <div className="spinner-border"></div>
        </td>
      </tr>
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Tipos de Video" onClick={createType} />
      <div className="card p-2 shadow">
        <input
          type="text"
          value={query}
          className="form-control mt-1"
          placeholder="Buscar por nombre..."
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="table-responsive mt-3">
          <table className="table border">
            <thead className="bg-light border bold small">
              <tr className="bold mx-0 border align-items-center">
                <td>Nombre</td>
                <td>Descripción</td>
                <td>Acciones</td>
              </tr>
            </thead>
            <tbody>{renderTipos()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminVideoTypes;
