import React, { useContext } from "react";
import MapaLugares from "../schedule/MapaLugares";
import SingleClassActions from "./SingleClassActions";
import { UserContext } from "../../context/UserContext";
// import { getLocalMomentObject } from "../../utils/dates";
import moment from "moment";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const SingleClassData = ({ single_class }) => {
  const appconfig = useContext(AppConfigContext);
  const { user } = useContext(UserContext);

  const allow_manual_book_full_class = getValue(
    appconfig,
    "allow_manual_book_full_class",
    "boolean"
  );

  const getSpots = () => {
    if (single_class.class_type) {
      return single_class.class_type?.spot_map
        .split(",")
        .map((number) => parseInt(number));
    }
  };

  const renderClassTypeName = () => {
    if (single_class.class_type && single_class.class_type !== null) {
      return single_class.class_type.name;
    }
  };

  const renderLocationName = () => {
    if (single_class.location && single_class.location !== null) {
      return single_class.location.name;
    }
  };

  const renderButtons = () => {
    if (["admin", "manager"].includes(user.role)) {
      return <SingleClassActions single_class={single_class} />;
    }
  };

  const renderSpotMap = () => {
    if (
      single_class.class_type !== null &&
      single_class.class_type?.spot_map !== null
    ) {
      return (
        <MapaLugares
          rows={getSpots()}
          taken_spots={single_class?.taken_spots}
        />
      );
    }
  };

  const remainingSpots =
    single_class?.capacity - single_class?.class_reservations?.length;

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="row mb-2 align-items-center">
          <div className="col-9">
            <h4 className="mb-0">Datos de Clase</h4>
          </div>
          <div className="col-3 text-end">
            {remainingSpots < 1 && allow_manual_book_full_class && (
              <span className="badge badge-pill bg-danger text-white">
                Sold Out
              </span>
            )}
          </div>
        </div>
        <p className="mb-2">
          <i className="fa fa-calendar me-2 text-primary" />
          {moment(getValue(single_class, "class_date", "date")).format(
            "ddd DD MMM, YY"
          )}{" "}
          <i className="fas fa-clock me-1 ms-1 text-primary" />
          {getValue(single_class, "class_date", "time")}
        </p>
        <p className="mb-2">{renderClassTypeName()}</p>
        <p className="mb-2">
          <i className="fa fa-map-pin me-2 text-primary" />
          {renderLocationName()}
        </p>
        {renderButtons()}
      </div>
      <div className="col col-md-6">{renderSpotMap()}</div>
    </div>
  );
};

export default SingleClassData;
