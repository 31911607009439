import React, { useContext, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import HeaderRow from "../../components/global/HeaderRow";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import { PackagesContext } from "../../context/PackageContext";
import PaquetesTable from "../../components/paquetes/PaquetesTable";
import SortableClassPackagesTable from "../../components/paquetes/SortableClassPackagesTable";
import ConfirmDelete from "../../components/common/ConfirmDelete";

const AdminClassPackages = () => {
  const [query, setQuery] = useState("");
  const [onlyHome, setOnlyHome] = useState(false);
  const [sortMode, setSortMode] = useState(false);
  const { paquetes, setPaquetes, getAllPaquetes, postPaquete, deletePaquete } =
    useContext(PackagesContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getAllPaquetes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterHomeClassPackages = () =>
    [...paquetes]
      .filter(({ available }) => available)
      .sort((a, b) => (a.order < b.order ? -1 : 1));

  const handleSave = () => {
    const paquetesRender = filterHomeClassPackages();
    paquetesRender.forEach((paquete) => postPaquete(paquete));
  };

  const renderPaquetes = () => {
    if (Array.isArray(paquetes)) {
      const homePaquetes = filterHomeClassPackages();
      if (sortMode) {
        return (
          <SortableClassPackagesTable
            class_packages={homePaquetes}
            modifier={setPaquetes}
          />
        );
      }
      return (
        <PaquetesTable
          query={query}
          confirmDelete={confirmDelete}
          class_packages={onlyHome ? homePaquetes : paquetes}
        />
      );
    }
    return <div className="spinner-border"></div>;
  };

  const confirmDelete = (paquete) => {
    modalComponent(
      "Precaución",
      <ConfirmDelete
        message={`¿Estás segura que deseas eliminar el paquete ${paquete.title}?`}
        handleDelete={() => deletePaquete(paquete.class_package_id)}
      />
    );
  };

  const renderButton = () => {
    if (sortMode) {
      return (
        <button
          className="btn w-100 text-muted"
          onClick={() => {
            setOnlyHome(false);
            setSortMode(false);
          }}
        >
          Cancelar
        </button>
      );
    }
    return (
      <button
        className="btn w-100 btn-outline-primary"
        onClick={() => setOnlyHome(!onlyHome)}
      >
        <i className="fa fa-eye me-2" />
        {onlyHome ? "Todos" : "Home"}
      </button>
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Paquetes"
        onClick={() => navigate("/myadmin/paquetes/nuevo")}
      />
      <div className="row align-items-center">
        <div className="col-12 col-lg-8 col-xl-10 mb-3">
          <input
            type="text"
            value={query}
            className="form-control"
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Buscar paquete por nombre..."
          />
        </div>
        <div className="col-6 col-lg-2 col-xl-1 mb-3">{renderButton()}</div>
        <div className="col-6 col-lg-2 col-xl-1 mb-3">
          {onlyHome && (
            <button
              className="btn px-2 w-100 btn-outline-primary"
              onClick={() => {
                if (sortMode) handleSave();
                setSortMode(!sortMode);
              }}
            >
              <i
                className={sortMode ? "fa fa-save me-1" : "fas fa-sort me-1"}
              />{" "}
              {sortMode ? "Guardar" : "Ordenar"}
            </button>
          )}
        </div>
      </div>
      <div className="hide-mobile">
        <HeaderRow
          headers={["Título", "Disponible", "Precio", "Oferta", "Acciones"]}
        />
      </div>
      {renderPaquetes()}
    </div>
  );
};

export default AdminClassPackages;
