import { Router } from "@reach/router";
import React, { useContext } from "react";
import AdminFreeTrials from "./admin/AdminFreeTrials";
import AnaliticasIngresos from "./analiticas/AnaliticasIngresos";
import AnaliticasInscritos from "./analiticas/AnaliticasInscritos";
import AnaliticasInstructores from "./analiticas/AnaliticasInstructores";
import AnaliticasPaquetes from "./analiticas/AnaliticasPaquetes";
import AnaliticasReservaciones from "./analiticas/AnaliticasReservaciones";
import AnaliticasVideos from "./analiticas/AnaliticasVideos";
import AnaliticasLeaderboards from "./analiticas/AnaliticasLeaderboards";
import AnaliticasDescuentos from "./analiticas/AnaliticasDescuentos";
import { getValue } from "../utils";
import { AppConfigContext } from "../context/AppConfigContext";

const Analitica = () => {
  const appconfig = useContext(AppConfigContext);
  return (
    <div>
      <Router>
        <AnaliticasInscritos path="/inscritos" default />
        <AnaliticasDescuentos path="descuentos" />
        <AnaliticasPaquetes path="/paquetes" />
        <AnaliticasIngresos path="/ingresos" />
        {getValue(appconfig, "leaderboard_enabled", "boolean") && (
          <AnaliticasLeaderboards path="/leaderboard" />
        )}
        <AnaliticasReservaciones path="/reservaciones" />
        <AnaliticasInstructores path="/instructores" />
        <AnaliticasVideos path="/videos" />
        <AdminFreeTrials path="/free" />
      </Router>
    </div>
  );
};

export default Analitica;
