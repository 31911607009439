import React from "react";

const PanelTitle = ({ title, onClick, hideButton, buttonComponent }) => {
  const renderButton = () => {
    if (!hideButton) {
      return buttonComponent && buttonComponent !== null ? (
        <>{buttonComponent}</>
      ) : (
        <button className="btn btn-primary" onClick={onClick}>
          + Agregar
        </button>
      );
    }
  };

  return (
    <div className="row mx-0 align-items-center mb-3 pb-3 border-bottom">
      <div className="col col-md-6 px-0">
        <h1 className="h2 bold mb-0">{title}</h1>
      </div>
      <div className="col col-md-6 px-0 text-end">{renderButton()}</div>
    </div>
  );
};

export default PanelTitle;
