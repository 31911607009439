
import {
  SET_AVAILABLECLASS,
  CREATE_AVAILABLECLASS,
  AVAILABLECLASSS_RECEIVED,
  SET_PROPERTY_AVAILABLECLASS,
} from "../types/available_classes";

const schema = {

}

const availableClassesReducer = (state, { type, payload }) => {
  switch (type) {
    case AVAILABLECLASSS_RECEIVED:
      return { ...state, available_classes: payload };
    case SET_AVAILABLECLASS:
      return { ...state, available_class: payload };
    case CREATE_AVAILABLECLASS:
      return { ...state, available_class: schema };
    case SET_PROPERTY_AVAILABLECLASS: {
      const { key, value } = payload;
      const available_class = { ...state.available_class };
      available_class[key] = value;
      return { ...state, available_class };
    }
    default:
      return { ...state};
  }
};

export default availableClassesReducer;
