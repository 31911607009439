import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const OffCanvas = () => {
  const { title, content, component, children } =
    useContext(ModalContext);


  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvas"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header" style={{height: '62px'}}>
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          {title}
        </h5>

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-0 position-relative" style={{flex: 1}}>
        {content}
        {component}
        {children}
      </div>
    </div>
  );
};

export default OffCanvas;
