import api from "./api";

const route = "/video_types";

const VideoTypesService = {
  getVideoTypes: () => api.get(route),
  postVideoType: (video_type) => api.post(route, { ...video_type }),
  putVideoType: (video_type) => api.put(route, { ...video_type }),
  deleteVideoType: (video_type_id) => api.delete(`${route}/${video_type_id}`),
};

export default VideoTypesService;
