import api from './api';

const route = '/refunds';

const RefundsService = {
  getRefunds: () => api.get(route),
  getSingleRefund: (refund_id) => api.get(`${route}/${refund_id}`),
  postRefund: (refund) => api.post(route, { ...refund}),
  postSubscriptionRefund: (refund) => api.post(`${route}/subscription`, { ...refund}),
  putRefund: (refund) => api.put(route, { ...refund}),
  deleteRefund: (Refund) => api.delete(`${route}/${Refund}`),
};

export default RefundsService;
