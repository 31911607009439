import React from "react";
import FreeTrialRow from "./FreeTrialRow";

const FreeTrialsTable = ({ purchases }) => {
  const renderCustomers = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay clientes registradas.</td>
          </tr>
        );
      }
      return purchases.map((purchase) => <FreeTrialRow purchase={purchase} />);
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td className="td-id">#ID</td>
            <td>Cliente</td>
            <td>Estado</td>
            <td>Generado</td>
            <td>Corte</td>
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default FreeTrialsTable;
