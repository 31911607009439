import { getArgs } from "../utils";
import api from "./api";

const route = "/purchases";

const PurchasesService = {
  getPurchases: (start_date, end_date, filters) =>
    api.get(
      `${route}/admin/all?start_date=${start_date}&end_date=${end_date}&${getArgs(
        filters
      )}`
    ),
  downloadPurchases: (start_date, end_date, filters) =>
    api.get(
      `${route}/admin/all?start_date=${start_date}&end_date=${end_date}&${getArgs(
        filters
      )}`,
      {
        responseType: "blob",
      }
    ),
  getPurchase: (purchase_id) => api.get(`${route}/${purchase_id}`),
  updatePurchase: (purchase) => api.put(`${route}`, { ...purchase }),
  cancelPurchase: (purchase_id, reason) =>
    api.post(`${route}/cancel/${purchase_id}/admin`, {
      reason,
      status: "cancelled",
    }),
  revokePurchase: (purchase_id) =>
    api.post(`${route}/cancel/${purchase_id}/admin`, {
      status: "revoked",
    }),
};

export default PurchasesService;
