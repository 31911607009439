import {
  INSCRITOS_RECIBIDOS,
  INGRESOS_RECBIDOS,
  PAQUETES_RECIBIDOS,
  RESERVATIONS_RECIBIDAS,
  COACHES_RECIBIDAS,
  FREE_TRIALS_RECIBIDAS,
  VIDEOS_RECIBIDOS,
  LEADERBORARD_RECEIVED,
  DISCOUNTS_RECEIVED,
} from "../types";

const AnaliticasReducer = (state, { type, payload }) => {
  switch (type) {
    case LEADERBORARD_RECEIVED:
      return { ...state, leaderboard: payload };
    case INSCRITOS_RECIBIDOS:
      return { ...state, ...payload };
    case PAQUETES_RECIBIDOS:
      return { ...state, paquetes: payload };
    case INGRESOS_RECBIDOS:
      return { ...state, ...payload };
    case RESERVATIONS_RECIBIDAS:
      return { ...state, ...payload };
    case COACHES_RECIBIDAS:
      return { ...state, instructores: payload };
    case FREE_TRIALS_RECIBIDAS:
      return { ...state, free_trials: payload };
    case VIDEOS_RECIBIDOS:
      return { ...state, ...payload };
    case DISCOUNTS_RECEIVED:
      return { ...state, discounts: payload };

    default:
      return { ...state };
  }
};

export default AnaliticasReducer;
