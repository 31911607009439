import React, { useContext, useEffect, useState } from "react";
import SelectPaymentMethod from "../payment_methods/SelectPaymentMethod";
import { SingleClassContext } from "../../context/SingleClassContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import MapaLugares from "../schedule/MapaLugares";
import SearchSelect from "../common/SearchSelect";
import { getValue } from "../../utils";
import Switch from "react-switch";

const AgregarAsistente = ({ single_class_id }) => {
  const [bookWithCredits, setBookWithCredits] = useState(true);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [warnings, setWarnings] = useState([]);
  const [isPaid, setIsPaid] = useState(true);
  const [place, setPlace] = useState(null);

  const { clase, postAsistenteClase } = useContext(SingleClassContext);
  const { customers, getAllCustomers } = useContext(CustomerContext);
  const { clearModal } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);

  const customerSpots = clase?.class_reservations?.filter(
    (reservation) => reservation.customer_id === customer
  ).length;

  const allow_class_guests = getValue(
    appconfig,
    "allow_class_guests",
    "boolean"
  );
  const max_guests_per_class = getValue(
    appconfig,
    "max_guests_per_class",
    "number"
  );
  const allow_manual_book_full_class = getValue(
    appconfig,
    "allow_manual_book_full_class",
    "boolean"
  );
  const allow_manual_book_over_max_guests = getValue(
    appconfig,
    "allow_manual_book_over_max_guests",
    "boolean"
  );

  useEffect(() => {
    handleWarnings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const handleWarnings = () => {
    const currentWarnings = [];
    const { capacity, class_reservations } = clase;
    const remainingSpots = capacity - class_reservations.length;
    if (remainingSpots <= 0) {
      currentWarnings.push("Esta clase esta llena");
      if (!allow_manual_book_full_class) {
        setDisabled(true);
      }
    }
    if (customerSpots > 0) {
      if (!allow_class_guests) {
        currentWarnings.push(
          "El cliente ya tiene una reserva en esta clase y no se permiten invitados"
        );
        if (!allow_manual_book_over_max_guests) {
          setDisabled(true);
        }
      } else if (customerSpots > max_guests_per_class && max_guests_per_class !== "") {
        currentWarnings.push(
          `Este cliente ya tiene ${customerSpots} reservaciones para esta clase, que que son el límite máximo de reservas por clase por cliente`
        );
        if (!allow_manual_book_over_max_guests) {
          setDisabled(true);
        }
      }
    }
    setWarnings(currentWarnings);
    if (currentWarnings.length === 0) {
      setDisabled(false);
    }
  };

  const handleSubmit = () => {
    postAsistenteClase({
      book_with_credits: bookWithCredits,
      payment_method_id: paymentMethodId,
      customer_id: customer,
      single_class_id,
      is_paid: isPaid,
      spot: place,
    });
  };

  const getSpotMap = (class_type) => {
    return class_type?.spot_map?.split(",").map((num) => parseInt(num));
  };

  const renderSpotMap = () => {
    if (clase && clase !== null) {
      if (clase.class_type && clase.class_type !== null) {
        const { spot_map } = clase.class_type;
        if (spot_map && spot_map !== null) {
          const taken_spots = clase.class_reservations.map(({ spot }) => spot);
          return (
            <MapaLugares
              place={place}
              setPlace={setPlace}
              taken_spots={taken_spots}
              rows={getSpotMap(clase.class_type)}
            />
          );
        }
      }
    }
  };

  const renderSelectedCustomer = () => {
    if (customer !== null) {
      return (
        <div className="container-fluid px-0">
          <p>
            {customer.name} {customer.last_name}
          </p>
          <p>{customer.email}</p>
          <div className="row my-3">
            <div className="col-9">
              <label className="d-block bold">Reservar con sus créditos</label>
              <label className="small d-block">
                Si el cliente no tiene créditos disponibles, no creará la
                reserva.
              </label>
            </div>
            <div className="col-3 text-end">
              <Switch
                checked={bookWithCredits}
                onChange={(checked) => setBookWithCredits(checked)}
              />
            </div>
          </div>
          {!bookWithCredits && (
            <>
              <div className="row my-3">
                <div className="col-9">
                  <label className="d-block bold">¿Pagada?</label>
                  <label className="small">
                    Dejar "apagado" si pagará al llegar al estudio.
                  </label>
                </div>
                <div className="col-3 text-end">
                  <Switch
                    checked={isPaid}
                    onChange={(checked) => setIsPaid(checked)}
                  />
                </div>
              </div>
              {isPaid && (
                <SelectPaymentMethod
                  value={paymentMethodId}
                  modifier={setPaymentMethodId}
                />
              )}
            </>
          )}
          {renderSpotMap()}
        </div>
      );
    }
  };

  const renderButtons = () => {
    if (customer === null) {
      return (
        <button onClick={clearModal} className="btn w-100 text-muted mt-3">
          Cancelar
        </button>
      );
    }
    return (
      <div className="row">
        <div className="col-6">
          <button onClick={clearModal} className="btn w-100 text-muted">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            disabled={disabled}
            onClick={handleSubmit}
            className="btn w-100 btn-primary"
          >
            Agregar a Clase
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid px-0">
      <p>Buscar por nombre o correo.</p>
      <SearchSelect
        value={customer}
        itemText="combined"
        itemValue="customer_id"
        fetchItems={getAllCustomers}
        modifier={({ value }) => setCustomer(value)}
        items={customers?.map((customer) => ({
          ...customer,
          combined: `${customer.name} - ${customer.email}`,
        }))}
      />
      {renderSelectedCustomer()}
      {warnings.length > 0 && (
        <p className="my-3 bold text-danger">{warnings.join(". ")}</p>
      )}
      {renderButtons()}
    </div>
  );
};

export default AgregarAsistente;
