import api from "./api";

const route = "/programs";

const ProgramsService = {
  getPrograms: () => api.get(route),
  getSingleProgram: (program_id) => api.get(`${route}/${program_id}`),
  postProgram: (program) => api.post(route, { ...program }),
  putProgram: (program) => api.put(route, { ...program }),
  deleteProgram: (Program) => api.delete(`${route}/${Program}`),
};

export default ProgramsService;
