import React from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import PaqueteRow from "./PaqueteRow";

const ClassPackageTile = SortableElement(({ index, paquete }) => (
  <PaqueteRow paquete={paquete} index={index} draggable />
));

const ClassPackagesList = SortableContainer(({ class_packages }) => {
  return (
    <div>
      {class_packages.map((paquete, index) => (
        <ClassPackageTile
          key={paquete.class_package_id}
          paquete={paquete}
          index={index}
        />
      ))}
    </div>
  );
});

const SortableClassPackagesTable = ({ class_packages, modifier }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const classPackagesRender = JSON.parse(JSON.stringify([...class_packages]));
    const classPackagesArray = JSON.parse(
      JSON.stringify(arrayMove(classPackagesRender, oldIndex, newIndex))
    ).map((coach, index) => ({ ...coach, order: index + 1 }));
    modifier(classPackagesArray);
  };

  return (
    <ClassPackagesList class_packages={class_packages} onSortEnd={onSortEnd} />
  );
};

export default SortableClassPackagesTable;
