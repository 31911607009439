import React, { useState } from "react";

const PageForm = ({ postPage }) => {
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    postPage({ title, slug, description });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Título</label>
      <input
        type="text"
        className="form-control mb-3"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <label>URL</label>
      <input
        type="text"
        className="form-control mb-3"
        value={slug}
        onChange={(e) => setSlug(e.target.value)}
      />
      <label>Descripcion</label>
      <input
        type="text"
        className="form-control mb-3"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <button type="submit" className="btn btn-primary">
        Guardar
      </button>
    </form>
  );
};

export default PageForm;
