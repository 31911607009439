import React, { useContext } from "react";
import Switch from "react-switch";
import { Link } from "@reach/router";
import SpecialEventOptions from "./SpecialEventOptions";
import SingleClassOptions from "./SingleClassOptions";
import SubscriptionOptions from "./SubscriptionOptions";
// import { EditorHTML } from "../common/EditorHTML";
import { getValue } from "../../utils";
import FileInput from "../common/FileInput";
import { AppConfigContext } from "../../context/AppConfigContext";

// const defaultBlocks = [];

const PaqueteForm = ({
  spinner,
  modifier,
  postPaquete,
  class_package,
  class_types,
  single_class_enabled,
}) => {
  const appconfig = useContext(AppConfigContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postPaquete(class_package);
  };

  // const setHTMLDescription = (stringHTML) => {
  //   modifier("description", stringHTML);
  // };

  const addDefaultPeriod = () => {
    modifier("subscription_period", "month");
    modifier("subscription_interval", "1");
  };

  // const hasHTMLDescription = () => {
  //   const htmlRegex = /<([a-z]+)\s*\/?[^>]*>/i;
  //   return htmlRegex.test(class_package.description);
  // };

  const renderDescription = () => {
    return (
      <div className="mb-3">
        <label>Descripción</label>
        <textarea
          rows="4"
          className="form-control"
          value={getValue(class_package, "description")}
          onChange={(e) => modifier("description", e.target.value)}
        />
      </div>
    );

    // let currentDescription = "";
    // if (hasHTMLDescription) {
    //   currentDescription = description;
    // } else {
    //   currentDescription = `<p>${description}</p>`;
    // }

    // return (
    //   <div>
    //     <h4 className="border-bottom pb-2">Descripción</h4>
    //     <EditorHTML
    //       className={"codex-editor__border"}
    //       content={currentDescription}
    //       setContent={setHTMLDescription}
    //       defaultBlocks={defaultBlocks}
    //     />
    //   </div>
    // );
  };

  const renderSingleClassOptions = () => {
    if (parseInt(single_class_enabled) === 1) {
      return (
        <SingleClassOptions
          class_package={class_package}
          class_types={class_types}
          modifier={modifier}
        />
      );
    }
  };

  const renderSubscriptionOptions = () => {
    if (getValue(class_package, "is_subscription", "boolean")) {
      return (
        <SubscriptionOptions
          class_package={class_package}
          modifier={modifier}
        />
      );
    }
  };

  const renderSpeciaEventOptions = () => {
    if (getValue(class_package, "is_special_event", "boolean")) {
      return (
        <SpecialEventOptions
          class_package={class_package}
          modifier={modifier}
        />
      );
    }
  };

  const renderAccessTime = () => {
    if (getValue(appconfig, "videos_enabled", "boolean")) {
      return (
        <div className="row align-items-center mb-2">
          <label>Acceso por</label>
          <div className="col-12 col-md-2 mb-3">
            <input
              type="number"
              className="form-control"
              value={getValue(class_package, "expiration_days")}
              onChange={(e) => modifier("expiration_days", e.target.value)}
            />
          </div>
          <div className="col-12 col-md-10 mb-3">días</div>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-6">
            <label>Disponible</label>
          </div>
          <div className="col-6">
            <Switch
              checked={getValue(class_package, "available", "boolean")}
              onChange={(checked) => modifier("available", checked)}
            />
          </div>
        </div>
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(class_package, "title")}
          onChange={(e) => modifier("title", e.target.value)}
        />
        <label>Descripción Corta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(class_package, "short_description")}
          onChange={(e) => modifier("short_description", e.target.value)}
        />
        <label>Precio</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(class_package, "price")}
          onChange={(e) => modifier("price", e.target.value)}
        />
        <label>Precio de Oferta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(class_package, "sale_price")}
          onChange={(e) => modifier("sale_price", e.target.value)}
        />
        {renderDescription()}
        {renderSingleClassOptions()}
        {renderSpeciaEventOptions()}
        {!class_package.is_special_event && (
          <div>
            <h4 className="border-bottom pb-2">Suscripción</h4>
            <div className="row mb-2">
              <div className="col-6">
                <label>¿Es cargo recurrente?</label>
              </div>
              <div className="col-6">
                <Switch
                  checked={getValue(
                    class_package,
                    "is_subscription",
                    "boolean"
                  )}
                  onChange={(checked) => {
                    modifier("is_subscription", checked);
                    if (checked) addDefaultPeriod();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {renderAccessTime()}
        {renderSubscriptionOptions()}
        <h4 className="border-bottom pb-2">Restricciones</h4>
        <label>Límite de Clientes</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(class_package, "customer_limit")}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) => modifier("customer_limit", e.target.value)}
        />
        <label>Límite de Compras por Cliente</label>
        <input
          type="number"
          className="form-control mb-3"
          value={getValue(class_package, "limit_per_customer")}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) => modifier("limit_per_customer", e.target.value)}
        />

        <div className="row">
          <h4 className="border-bottom pb-2">Imagenes</h4>

          <div className="col-6">
            <FileInput
              label={"Imagen para Checkout"}
              value={getValue(class_package, "image")}
              modifierKey={"image"}
              modifier={modifier}
              type={"image"}
            />
          </div>

          <div className="col-6">
            <FileInput
              label={"Imagen para Recibo"}
              value={getValue(class_package, "receipt_file")}
              modifierKey={"receipt_file"}
              modifier={modifier}
              type={"image"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Link to="/myadmin/paquetes" className="btn w-100 btn-link text-secondary">
              Cancelar
            </Link>
          </div>
          <div className="col-6 text-right">
            <button type="submit" className="btn w-100 btn-primary">
              {spinner ? <div className="spinner-border"></div> : "Guardar"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaqueteForm;
