import { SET_CONFIG, SET_PROPERTY } from "../types/appconfig";

const FILES_BASE = (process.env.NODE_ENV === "development"
? "http://localhost:4000"
: window.location.origin.replace("admin.", ""));

const AppConfigReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_CONFIG:
      const objectParams = [
        'firebaseConfig', 
      ];
      let config = {};

      if (Array.isArray(payload)) {
        let params = payload;

        params.forEach(({ key, value }) => {
          if (objectParams.includes(key)) {
            value = JSON.parse(value);
          }
          config[key] = value;
        });

        if (!config.S3_ENDPOINT) {
          let S3_ENDPOINT = `https://${config.bucket}.s3.${config.region}.amazonaws.com`;
          config.S3_ENDPOINT = S3_ENDPOINT;
        }

        if(config.files_location === 'server') {
          config.filesBaseSrc = `${FILES_BASE}`;
        } else {
          config.filesBaseSrc = config.S3_ENDPOINT;
        }

      }
      return { ...state, ...config };

    case SET_PROPERTY:
      return { 
        ...state, 
        [payload.key]: payload.value
      }
    default:
      return { ...state };
  }
};
export default AppConfigReducer;
