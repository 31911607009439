import React, { useContext } from "react";
import { ReservationsContext } from "../../context/ReservationsContext";
import SingleClassRatingCard from "../../components/clases/SingleClassRatingCard";
import RatingsHeader from "../../components/ratings/RatingsHeader";

const AdminClassesRatings = () => {
  const { ratings, getReservationsRatings } = useContext(ReservationsContext);

  const renderVideoRatings = () => {
    if (Array.isArray(ratings)) {
      if (ratings.length === 0) {
        return <p>Aún no hay comentarios.</p>;
      }
      return ratings.map((rating, index) => (
        <div key={index} className="col-12 col-md-6">
          <SingleClassRatingCard rating={rating} />
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid" style={{ overflowY: "scroll" }}>
      <RatingsHeader
        title="Clases"
        fetchRatings={getReservationsRatings}
      />
      <div className="row"> {renderVideoRatings()}</div>
    </div>
  );
};

export default AdminClassesRatings;
