import React, { useState } from "react";

const SelectTags = ({ selected, tags, addTag, removeTag }) => {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);

  const renderTags = () => {
    if (Array.isArray(tags) && query !== "" && open) {
      let tagsRender = [...tags];
      if (query !== "") {
        tagsRender = tagsRender.filter((tag) =>
          String(tag.name).toLowerCase().startsWith(String(query).toLowerCase())
        );
      }
      if (Array.isArray(selected)) {
        const current = selected.map(({ tag }) => tag.tag_id);
        tagsRender = tagsRender.filter((tag) => !current.includes(tag.tag_id));
      }
      return tagsRender
        .slice(0, 3)
        .map((tag) => (
          <li
            key={tag.tag_id}
            className="dropdown-item border"
            onClick={() => {
              addTag(tag);
              setOpen(false);
              setQuery("");
            }}
          >
            {tag.name}
          </li>
        ))
        .concat(
          <li
            key="nuevo"
            className="dropdown-item border pointer"
            onClick={() => {
              addTag({ tag_id: "nuevo", name: query });
              setQuery("");
            }}
          >
            + Crear tag "{query}"
          </li>
        );
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      if (selected.length > 0) {
        return (
          <div className="pt-2">
            {selected.map((tag) => (
              <span
                key={tag.tag_id}
                className="badge badge-pill bg-light border text-dark p-2 me-1"
              >
                {tag.name && tag.name !== null ? tag.name: tag.tag.name}
                <i
                  className="fa fa-times small ms-1 pointer"
                  onClick={() => removeTag(tag.tag_id)}
                ></i>
              </span>
            ))}
          </div>
        );
      }
    }
  };

  return (
    <div className="mb-3">
      <label>Etiquetas</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Buscar por nombre"
        onChange={(e) => {
          const currentValue = e.target.value;
          setQuery(currentValue);
          if (currentValue.length > 0) setOpen(true);
        }}
      />
      <div>{renderTags()}</div>
      {renderSelected()}
    </div>
  );
};

export default SelectTags;
