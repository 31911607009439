
import {
  SET_WAITLIST,
  CREATE_WAITLIST,
  WAITLISTS_RECEIVED,
  SET_PROPERTY_WAITLIST,
} from "../types/waitlists";

const schema = {

}

const waitlistsReducer = (state, { type, payload }) => {
  switch (type) {
    case WAITLISTS_RECEIVED:
      return { ...state, waitlists: payload };
    case SET_WAITLIST:
      return { ...state, waitlist: payload };
    case CREATE_WAITLIST:
      return { ...state, waitlist: schema };
    case SET_PROPERTY_WAITLIST: {
      const { key, value } = payload;
      const waitlist = { ...state.waitlist };
      waitlist[key] = value;
      return { ...state, waitlist };
    }
    default:
      return { ...state};
  }
};

export default waitlistsReducer;
