import React from "react";

const Pitch = ({ main_pitch, tagline, description, color }) => {
  return (
    <div className="container-fluid text-center my-4" data-aos="fade-in">
      <h2 className={`display-4 pitch-title bold text-${color}`}>
        {main_pitch}
      </h2>
      <h3 className="bold h1">{tagline}</h3>
      <p className="h5">{description}</p>
    </div>
  );
};

export default Pitch;
