import React, { useContext, useState } from "react";
import Pagination from "../../components/global/Pagination";
import { CustomerContext } from "../../context/CustomerContext";
import CustomersTable from "../../components/customers/CustomersTable";
import CustomersHeader from "../../components/customers/CustomersHeader";
import CustomersList from "../../components/customers/CustomersList";

const AdminCustomers = () => {
  const [sort, setSort] = useState("name");
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState("ASC");

  const { customers } = useContext(CustomerContext);

  return (
    <div className="container-fluid">
      <CustomersHeader page={page} sort={sort} direction={direction} />
      <div className="show-mobile">
        <CustomersList
          selected={sort}
          setSort={setSort}
          direction={direction}
          setDirection={setDirection}
        />
      </div>
      <div className="card p-3 hide-mobile">
        <CustomersTable
          selected={sort}
          setSort={setSort}
          direction={direction}
          customers={customers}
          setDirection={setDirection}
        />
      </div>
      <Pagination modifier={setPage} />
    </div>
  );
};

export default AdminCustomers;
