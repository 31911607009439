import React from "react";
import VideoImage from "../videos/VideoImage";

const PostVideo = ({ post, handleVideo }) => {
  const renderInstructor = () => {
    const { video } = post;
    if (video.instructor && video.instructor !== null) {
      return <p className="mb-0">Coach {post.video.instructor.name}</p>;
    }
  };

  const renderVideoTo = () => {
    if (post.video && post.video !== null) {
      return (
        <div
          onClick={() => {
            if (typeof handleVideo === "function") {
              handleVideo(post.video_id);
            }
          }}
          className="reply px-md-4 px-3 py-2 bg-white br-15 text-dark my-2"
        >
          <div className="row align-items-center">
            <div className="col-6 col-md-3 mb-2">
              <VideoImage video={post.video} />
            </div>
            <div className="col-12 col-md-9 mb-2">
              <p className="mb-0 bold">{post.video.name}</p>
              {renderInstructor()}
            </div>
          </div>
        </div>
      );
    }
  };
  return <div>{renderVideoTo()}</div>;
};
export default PostVideo;
