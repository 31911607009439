import React from "react";

const MapaLugares = ({
  selected,
  rows,
  place,
  setPlace,
  icon,
  taken_spots,
}) => {
  const total = rows?.reduce((a, b) => a + b);

  const getCurrentLugar = (rowIndex, index) => {
    let ascending = parseInt(rowIndex) !== parseInt(rows) - 1;
    return ascending
      ? total - getSum(rowIndex) + index - rows[rowIndex] + 1
      : total - getSum(rowIndex) - index;
  };

  const getSum = (rowIndex) => {
    let sum = 0;
    for (let i = 0; i < rowIndex; i++) {
      sum += rows[i];
    }
    return sum;
  };

  const isPlaceTaken = (taken_spots, rowIndex, index) => {
    if (Array.isArray(taken_spots)) {
      const place = String(getCurrentLugar(rowIndex, index));
      return Array.from(taken_spots).includes(place);
    }
    return false;
  };

  const isPlaceSelected = (rowIndex, index) => {
    return parseInt(selected) === getCurrentLugar(rowIndex, index);
  };

  const isPlaceBeingSelected = (rowIndex, index) => {
    return parseInt(place) === getCurrentLugar(rowIndex, index);
  };

  const getButtonClassName = (rowIndex, index) => {
    if (Array.isArray(taken_spots)) {
      if (
        isPlaceTaken(taken_spots, rowIndex, index) ||
        isPlaceSelected(rowIndex, index) ||
        isPlaceBeingSelected(rowIndex, index)
      ) {
        return "primary";
      }
      if (isPlaceTaken(taken_spots, rowIndex, index)) {
        return "secondary";
      }
    }
    return "light";
  };

  const renderSpot = (currentSpot) => {
    if(window.location.host.includes("bunnation")) {
      return currentSpot > 10 ? `c${currentSpot - 10}` : `f${currentSpot}`;
    }
    return currentSpot;
  }

  return (
    <div>
      <p className="bold">{icon ? "Selecciona tu Lugar" : "Lugares"}</p>
      {rows?.map((cols, rowIndex) => (
        <div key={rowIndex} className="row my-3">
          {new Array(cols).fill(1).map((one, index) => {
            const currentSpot = getCurrentLugar(rowIndex, index);
            const placeTaken = isPlaceTaken(taken_spots, rowIndex, index);
            return (
              <div key={index} className={`col mb-3 text-center spot-col`}>
                <button
                  className={`btn btn-sm btn-${getButtonClassName(
                    rowIndex,
                    index
                  )}`}
                  onClick={() => {
                    if (typeof setPlace === "function") {
                      setPlace(`${currentSpot}`);
                    }
                  }}
                  disabled={placeTaken || typeof setPlace !== "function"}
                >
                  {icon && (
                    <i
                      className={`${icon} ${
                        placeTaken
                          ? "text-dark"
                          : place === `${currentSpot}`
                          ? "text-primary"
                          : ""
                      }`}
                    />
                  )}
                  <p className="mb-0">
                    {renderSpot(currentSpot)}
                  </p>
                </button>
              </div>
            );
          })}
        </div>
      ))}
      <div className="container-fluid px-0 py-1 bg-dark text-center mb-3">
        <p className="text-primary mb-0 small bold">Coach</p>
      </div>
    </div>
  );
};

export default MapaLugares;
