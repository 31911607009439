import React, { useContext } from "react";
import { PostsContext } from "../../context/PostsContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import PostVideo from "../posts/PostVideo";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";

const VideoRatingCard = ({ video_rating, handleCallback }) => {
  const { user } = useContext(UserContext);
  const { deletePost } = useContext(PostsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleDelete = () => {
    modalComponent(
      "Eliminar Comentario",
      <div>
        <p>
          ¿Estas segura que deseas eliminar el comentario? Esta acción NO puede
          deshacerse.
        </p>
        <div className="row mt-4 mb-2">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => deletePost(video_rating.post_id, handleCallback)}
              className="btn w-100 btn-danger"
            >
              <i className="fa fa-trash me-1" />
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderRating = () => {
    let components = [];
    const { class_stars, coach_stars } = video_rating;
    if (class_stars && class_stars !== null) {
      components.push(
        <span key="class" className="me-3">
          {formatMonto(class_stars)}{" "}
          <i className="fa text-warning fa-star ms-1"></i>
        </span>
      );
    }
    if (coach_stars && coach_stars !== null) {
      components.push(
        <span key="coach">
          {formatMonto(coach_stars)}
          <i className="fa text-warning fa-star ms-1"></i>
        </span>
      );
    }
    if (components.length === 0) {
      components.push(
        <span key="none" className="small text-muted">
          Sin estrellas
        </span>
      );
    }
    return <div className="mb-2">{components}</div>;
  };

  const renderComment = () => {
    if (video_rating.comment === null) {
      if (!video_rating.content || video_rating.content === null) {
        return <p className="text-muted small">Sin comentario</p>;
      }
      return <p>{video_rating.content}</p>;
    }
    if (video_rating.content && video_rating.content !== null) {
      return <p>{video_rating.content}</p>;
    }
    return <p>{video_rating.comment}</p>;
  };

  const renderDelete = () => {
    if (
      video_rating.post_id !== null &&
      video_rating.status === "published" &&
      ["manager", "admin"].includes(user.role)
    ) {
      return (
        <button
          onClick={handleDelete}
          className="btn btn-sm btn-outline-danger"
        >
          <i className="fa fa-trash" />
        </button>
      );
    }
    if (video_rating.status === "deleted")
      return (
        <span className="badge badge-pill text-capitalize bg-danger">
          {video_rating.status}
        </span>
      );
  };

  const renderCustomerName = () => {
    if (user.role !== "coach") {
      return (
        <Link
          to={`/myadmin/customer/${video_rating.customer_id}`}
          className="mb-1 text-accent"
        >
          <i className="fa fa-user me-1" /> {video_rating.customer.name}{" "}
          {video_rating.customer.last_name}
        </Link>
      );
    }
    return (
      <span>
        <i className="fa fa-user me-1" /> {video_rating.customer.name}{" "}
        {video_rating.customer.last_name}
      </span>
    );
  };

  return (
    <div className="card no-scale shadow mb-3">
      {renderCustomerName()}
      <PostVideo post={video_rating} />
      {renderComment()}
      <div className="row align-items-center">
        <div className="col-6">{renderRating()}</div>
        <div className="col-6 text-end">{renderDelete()}</div>
      </div>
    </div>
  );
};

export default VideoRatingCard;
