import React, { createContext, useContext, useReducer } from "react";
import UsersReducer from "../reducers/UsersReducer";
import UserService from "../services/UserService";
import { HIDE_SPINNER, SHOW_SPINNER, USERS_RECIBIDOS } from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  users: null,
  user: null,
};

export const UsersContext = createContext(initialState);

export const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UsersReducer, initialState);

  const { success } = useContext(ModalContext);

  const getUsers = (page) => {
    UserService.getAllUsers(page).then((res) => {
      const { users } = res.data;
      dispatch({ type: USERS_RECIBIDOS, payload: users });
    });
  };

  const createUser = (email, role) => {
    dispatch({ type: SHOW_SPINNER });
    UserService.postUser(email, role)
      .then(() => {
        getUsers();
        hideModal();
        dispatch({ type: HIDE_SPINNER });
        success("Usuario creado con éxito.");
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const updateUser = (user_id, role) => {
    dispatch({ type: SHOW_SPINNER });
    UserService.putUser(user_id, role)
      .then(() => {
        getUsers();
        hideModal();
        dispatch({ type: HIDE_SPINNER });
        success("Usuario actualizado con éxito.");
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const deleteUser = (user_id) => {
    dispatch({ type: SHOW_SPINNER });
    UserService.deleteUser(user_id)
      .then(() => {
        getUsers();
        hideModal();
        dispatch({ type: HIDE_SPINNER });
        success("Usuario elminado con éxito.");
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const clearUsers = () => {
    dispatch({ type: USERS_RECIBIDOS, payload: null });
  };

  return (
    <UsersContext.Provider
      value={{
        ...state,
        getUsers,
        createUser,
        updateUser,
        deleteUser,
        clearUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
