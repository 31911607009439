import React from "react";
import HomeSectionsForm from "./HomeSectionsForm";
import HomeSectionResources from "./HomeSectionsResources";

const HomeSection = () => {
  return (
    <div className="container-fluid px-1 overflow-hidden">
      <HomeSectionsForm/>
      <HomeSectionResources/>
    </div>
  );
};

export default HomeSection;
