import React from "react";
import DescuentoRow from "./DescuentoRow";

const DescuentosTable = ({ descuentos, handleEdit, handleDelete }) => {
  const renderDescuentos = () => {
    if (Array.isArray(descuentos)) {
      return descuentos.map((descuento) => (
        <DescuentoRow
          key={descuento.discount_id}
          descuento={descuento}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ));
    }
  };
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light bold">
            <td>Código</td>
            <td>Cantidad</td>
            <td>Inicio</td>
            <td>Expiración</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderDescuentos()}</tbody>
      </table>
    </div>
  );
};

export default DescuentosTable;
