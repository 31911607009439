import { navigate } from "@reach/router";
import React, { useContext, useEffect } from "react";
import LoginForm from "../components/auth/LoginForm";
import { AppConfigContext } from "../context/AppConfigContext";
import { UserContext } from "../context/UserContext";

const Login = () => {
  const { user } = useContext(UserContext);

  const { S3_ENDPOINT, bg_login, business_name } = useContext(AppConfigContext);

  useEffect(() => {
    if (user !== null && !window.location.href.includes("myadmin")) {
      navigate("/myadmin");
    }
  }, [user]);

  const getStyle = () => {
    let style = {};
    if (bg_login && bg_login !== null) {
      style.backgroundImage = `url("${S3_ENDPOINT}/${bg_login}")`;
    }
    return style;
  };

  return (
    <div className="container-fluid bg-light">
      <div className="row">
        <div className="col-12 col-md-6 bg-black vh-100 px-0 hide-mobile bg-dark">
          <div className="bg-vertical" style={getStyle()}></div>
        </div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <h1 className="text-center my-4">Administrador</h1>
              <h2 className="text-center">{business_name}</h2>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
