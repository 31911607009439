import React, { useContext, useEffect, useState } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { PackagesContext } from "../../context/PackageContext";
import TrackingCodesInputs from "./TrackingCodesInputs";
import ReactSelect from "react-select";

const MarketingSettings = () => {
  const [availablePackages, setAvailablePackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState(null);

  const {
    signup_bonus,
    email_from_name,
    email_from_address,
    setAppConfigProperty,
  } = useContext(AppConfigContext);
  const { getPaquetes, paquetes } = useContext(PackagesContext);

  useEffect(() => {
    getPaquetes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePackage = (data) => {
    setAppConfigProperty("signup_bonus", data.value);
  };

  useEffect(() => {
    if (Array.isArray(paquetes)) {
      const data = paquetes?.map((obj) => ({
        value: obj.class_package_id,
        label: obj.title,
      }));

      const emptyPackage = { label: "Sin paquete de bienvenida", value: null };

      if (signup_bonus === null) {
        setCurrentPackage(emptyPackage);
      } else {
        const currentIndex = data?.findIndex(
          (obj) => obj.value === Number(signup_bonus)
        );

        if (currentIndex >= 0) setCurrentPackage(data[currentIndex]);
      }

      setAvailablePackages([emptyPackage, ...data]);
    }
  }, [paquetes, signup_bonus]);

  return (
    <div className="container-fluid px-2 overflow-hidden">
      <h5 className="mb-1">Paquete de Regalo de Bienvenida</h5>
      <p className="mb-1">
        Este paquete se agrega como una{" "}
        <span className="bold">compra gratuita</span> al cliente ($0){" "}
        <span className="bold">en automático</span> cuando crea su cuenta.
      </p>
      <ReactSelect
        className="mb-3"
        value={currentPackage}
        options={availablePackages}
        onChange={(newValue) => handleChangePackage(newValue)}
      />

      <h2 className="h4 mb-2 pb-2 border-bottom">Mailing</h2>
      <p className="mb-1">
        Si tienes configurado envío de correos en automático con{" "}
        <a rel="noreferrer" target="_blank" href="https://brevo.com/">
          Brevo
        </a>
        , elige el remitente que se mostrará a tus clientes.
      </p>
      <div className="row mb-3">
        <div className="col-6">
          <label className="bold">Email</label>
          <input
            className="form-control"
            value={email_from_address}
            onChange={(event) => {
              setAppConfigProperty("email_from_address", event.target.value);
            }}
          />
        </div>
        <div className="col-6">
          <label className="bold">Nombre</label>
          <input
            className="form-control"
            value={email_from_name}
            onChange={(event) => {
              setAppConfigProperty("email_from_name", event.target.value);
            }}
          />
        </div>
      </div>

      <h2 className="h4 mb-2 pb-2 border-bottom">Códigos de Rastreo</h2>
      <p>
        Piezas de código que deben instalarse cuando usas Meta Ads, Google Ads o
        TikTok Ads para atraer nuevos clientes.
      </p>
      <TrackingCodesInputs />
    </div>
  );
};

export default MarketingSettings;
