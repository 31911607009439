import React from "react";

const VideoTypeRow = ({ video_type, editVideoType, confirmDelete }) => {
  return (
    <tr className="small bg-white border-bottom align-middle hover-light">
      <td>{video_type.name}</td>
      <td>{video_type.description}</td>
      <td>
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => editVideoType(video_type)}
        >
          <i className="fa fa-edit me-1"></i> Editar
        </button>
        <button
          className="btn btn-outline-danger btn-sm mx-3"
          onClick={() => confirmDelete(video_type)}
        >
          <i className="fa fa-trash me-1"></i> Eliminar
        </button>
      </td>
    </tr>
  );
};

export default VideoTypeRow;
