import React from "react";

const UserTabs = ({ user, signOut }) => {
  return (
    <ul className="side-menu-list">
      <li className="nav-item dropdown">
        <button
          id="navbarDropdown"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {String(user.customer.name).substr(0, 20)}
          {String(user.customer.name).length > 20 ? "..." : ""}
          <i className="ms-2 fa fa-user-circle"></i>
        </button>
        <ul
          className="dropdown-menu user-menu"
          aria-labelledby="navbarDropdown"
        >
          <li>
            <button className="dropdown-item text-danger" onClick={signOut}>
              Salir <i className="ms-2 fa fa-sign-out-alt"></i>
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default UserTabs;
